import {
    routes
  } from './routes';
  import EvChargerService from './service';
  import EvChargerListController from './list/controller';
  import EvChargerDetailsController from './details/controller';
  
  export default angular.module('app.ev', [])
    .config(routes)
    .service('EvChargerService', EvChargerService)
    .controller('EvChargerListController', EvChargerListController)
    .controller('EvChargerDetailsController', EvChargerDetailsController)
    .name;
  