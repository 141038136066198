import { routes } from './routes';
import TariffcycleService from './service';
import TariffcycleListController from './list/controller';
import TariffcycleDetailsController from './details/controller';
import TariffcycleNewController from './new/controller';

export default angular.module('app.tariffcycle', [])
    .config(routes)
    .service('TariffcycleService', TariffcycleService)
    .controller('TariffcycleListController', TariffcycleListController)
    .controller('TariffcycleDetailsController', TariffcycleDetailsController)
    .controller('TariffcycleNewController', TariffcycleNewController)
    .name;
