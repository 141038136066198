export default class AdminDashboardController {
  constructor(
    $state,
    $interval,
    SiteService,
    Dashboard,
    AuthenticationService
  ) {
    this.$state = $state;
    this.$interval = $interval;
    this.Site = SiteService;
    this.Dashboard = Dashboard;
    this.AuthenticationService = AuthenticationService;
    this.loadData();
  }

  clear = () => {
    this.term = "";
  };

  setSite = (site) => {
    const sites = this.data.filter((r) => r.entity.id == site.entity.id);
    this.AuthenticationService.updateSite(site, sites);
  };

  loadData = () => {
    this.loaded = false;
    this.Site.admin().then((r) => {
      // Caso não tenha nenhuma info de potência instantânea, não mostrar (ver solução)
      r = r.filter((row) => {
        return row.potency;
      });
      r.forEach((row) => {
        row.metrics = [];
        if (row.potency) {
          if (row.potency.grid) {
            row.metrics.push({
              name: "Consumo",
              origin: "grid",
            });
          }
          if (row.potency.production) {
            row.metrics.push({
              name: "Produção",
              origin: "production",
            });
          }
          if (row.potency.return) {
            row.metrics.push({
              name: "Excedente",
              origin: "return",
            });
          }
        }
        row.metrics.forEach((r) => {
          this.getMetric(row, r);
        });
      });
      this.data = r;
      this.loaded = true;
    });
  };

  filterFn = (item) => {
    if (!this.term || this.term.trim().length == 0) {
      return true;
    }
    return (
      item.name.toLowerCase().includes(this.term.toLowerCase()) ||
      item.entity.name.toLowerCase().includes(this.term.toLowerCase())
    );
  };

  getMetric = (site, metric) => {
    metric.loaded = false;
    metric.error = false;
    let get = () => {
      this.Dashboard.instant(site.id, metric.origin)
        .then((response) => {
          metric.loaded = true;
          metric.value = Number(response.value) / 1000; // W to kW
          metric.time = moment.unix(response.time);
          let timeCheck = Math.abs(moment().diff(metric.time, "hour"));
          if (timeCheck > 0) {
            // Com erro
            metric.error = true;
          }
          metric.time = moment(metric.time).format("YYYY-MM-DD HH:mm");
        })
        .catch((e) => {
          metric.loaded = true;
          metric.error = true;
          metric.time = moment.unix(response.time);
        });
    };
    get();
    this.$interval(() => {
      get();
    }, 1000 * 60 * 5);
  };
}

AdminDashboardController.$inject = [
  "$state",
  "$interval",
  "SiteService",
  "DashboardService",
  "AuthenticationService",
];
