export function routes($stateProvider) {
    $stateProvider.state('app.tariffwater', {
        url: '/tariffwater',
        abstract: true,
        template: '<ui-view></ui-view>',
    }).state('app.tariffwater.list', {
        url: '?{page:int}&{term:string}&{order:string}&{sort:string}',
        template: require('./list/view.html'),
        controller: 'TariffWaterListController',
        controllerAs: 'vm',
        role: ['tariffList']
    }).state('app.tariffwater.details', {
        url: '/?{id}',
        template: require('./details/view.html'),
        controller: 'TariffWaterDetailsController',
        controllerAs: 'vm',
        role: ['tariffEdit']
    }).state('app.tariffwater.wizard', {
        url: '/',
        template: require('./details/view.html'),
        controller: 'TariffWaterDetailsController',
        controllerAs: 'vm',
        role: ['tariffAdd']
    });
}

routes.$inject = ['$stateProvider'];
