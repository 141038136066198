const moment = require("moment-timezone");
export default class DashboardWater2Controller {
  constructor(DashboardEnergy, $interval, $scope, $state, AlarmService, $http, AuthenticationService) {
    this.AuthenticationService = AuthenticationService;
    this.DashboardEnergy = DashboardEnergy;
    this.Alarm = AlarmService;
    this.$interval = $interval;
    this.$scope = $scope;
    this.$state = $state;
    this.$http = $http;

    this.user = AuthenticationService.getUser();

    $scope.$on('$inlineHelpUpdate', () => {
      this.user = AuthenticationService.getUser();
    });

    // Aggregate values
    this.aggregatesLoaded = false;
    this.totalConsumption = 0;
    this.totalGrid = 0;
    this.totalReturn = 0;
    this.totalProductionLiquid = 0;
    this.costConsumption = 0;
    this.costGrid = 0;
    this.costReturn = 0;
    this.costProductionLiquid = 0;

    this.selfConsumedSolarEnergyRatio = 0;
    this.selfSufficiencyRatio = 0;

    this.day = moment.utc($state.params.date);

    this._3hrsGraph = {
      day: moment(),
      data: [],
      label: "Últimas 3 horas",
      dataLimits: [],
    }

    this.getData();

    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
    });
  }

  isToday = () => moment.utc().isSame(this.day, 'day');

  moveTimeline = inc => {
    this.day = moment.utc(this.day).add(inc, 'day');
    this.getLast24hrs();
  }

  setDayLabel = () => {
    if (this.isToday()) {
      return "Hoje";
    } else {
      return moment.utc(this.day).format("DD/MM/YYYY")
    }
  }

  getLast24hrs = () => {
    this.last24hrsLoaded = false;
    this.aggregatesLoaded = false;
    this.DashboardEnergy.waterLast24hrs(this.day).then((r) => {
      this.createDayChart(r);
      this.last24hrsLoaded = true;
      this.aggregatesLoaded = true;
    });
  }

  getLast3hrs = (forceUpdate) => {
    this.last3hrsLoaded = false;
    if (this._3hrsGraph.dataLimits.length == 2 && !forceUpdate) {
      if (this._3hrsGraph.day.isBetween(this._3hrsGraph.dataLimits[0], this._3hrsGraph.dataLimits[1], 'minutes', '[]')) {
        this.create3hrsChart();
        this.last3hrsLoaded = true;
        return;
      }
    }
    this.DashboardEnergy.getSmartmeters('60acdc530b903917588ec45a').then((r) => {
      const smartmeterIds = r.map((s) => s.id);
      //create moment 00:00 of next day
      const endOfDay = this._3hrsGraph.day.clone().add(1, 'day').startOf('day');
      const startOfDay = this._3hrsGraph.day.clone().startOf('day');
      this.DashboardEnergy.consumption3h(startOfDay, endOfDay, smartmeterIds, false, false).then((r) => {
        this._3hrsGraph.data = r;
        const moments = r.map((v) => moment(v.label));
        this._3hrsGraph.dataLimits = [moment.min(moments), moment.max(moments)];
        this.create3hrsChart();
        this.last3hrsLoaded = true;
      });
    });
  }

  getData = () => {
    this.getLast24hrs();

    this.consumptionMonthLoaded = false;
    this.DashboardEnergy.dashboardConsumption('60acdc530b903917588ec45a', true).then((r) => {
      this.createConsumptionMonthChart(r);
      this.consumptionMonthLoaded = true;
    });

    this.consumptionYearLoaded = false;
    this.DashboardEnergy.dashboardConsumptionYear('60acdc530b903917588ec45a').then((r) => {
      this.createConsumptionYearChart(r);
      this.generateTable(r);
      this.consumptionYearLoaded = true;
    });

    this.getLast3hrs();
  };

  generateTable = data => {
    this.table = [];
    this.tableLoaded = false;
    data.forEach(r => {
      this.table.push({
        date: r.date,
        consumption: r.consumption,
        consumptionCost: r.cost || 0,
      });
    });
    this.tableLoaded = true;
  }

  createAvgData = (color, config, callback, ...values) => {
    const longestArrayLength = Math.max(...values.map(arr => arr.length));
    const summedValues = Array.from({ length: longestArrayLength }, (_, i) =>
        callback(values.map((v) => v[i] || 0))
    );
    const zeroIndex = summedValues.findIndex((currentValue, index, array) => currentValue === 0 && array[index + 1] !== 0); //index of the first 0 followed by != 0
    const totalElements = summedValues.length - (zeroIndex + 1);
    const accumulatedValue = summedValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const avg = (accumulatedValue / totalElements).toFixed(3);
    const avg_values = Array(summedValues.length).fill(avg);
    const [r, g, b] = color;
    const line = {
      data: avg_values,
      type: 'line',
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0)`,
      pointRadius: 0,
      pointHoverRadius: 0,
      borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
      pointHoverBorderColor: `rgba(${r}, ${g}, ${b}, 1)`,
      pointHoverBackgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)`,
      hidden: true,
      borderDash: [6,6],
    };
    return { data: avg_values, dataset: Object.assign(line, config) };
  }

  updateLabel3hrsChart = () => {
    let ret = ""
    if (this._3hrsGraph.day.isSame(moment(), 'day')) {
      ret += "Hoje";
    } else {
      ret += moment(this._3hrsGraph.day).format("DD/MM/YYYY")
    }

    let _3hrsAgo = moment(this._3hrsGraph.day).subtract(3, 'hours');
    if (!_3hrsAgo.isSame(moment(this._3hrsGraph.day), 'day'))
      _3hrsAgo = moment(this._3hrsGraph.day).startOf('day');

    ret += " | " + _3hrsAgo.format("HH:mm") + " - " + moment(this._3hrsGraph.day).format("HH:mm");
    this._3hrsGraph.label = ret;
  }

  move3hrsChartDay = inc => {
    const oldDay = this._3hrsGraph.day;
    let newDay = moment(this._3hrsGraph.day).add(inc, 'day').endOf('day');
    if (newDay.isAfter())
      newDay = moment();
    if (newDay.isSame(moment(), 'day')) this._3hrsGraph.day = moment();
    else this._3hrsGraph.day = newDay;
    this.getLast3hrs(true);
    this.updateLabel3hrsChart();
  }

  move3hrsChartHour = inc => {
    const oldDay = this._3hrsGraph.day;
    let newDay = moment(this._3hrsGraph.day).add(inc, 'hour');
    if (newDay.isAfter())
      newDay = moment();
    if (!newDay.isSame(oldDay, 'day'))  {
      if (!newDay.isSame(moment(), 'day'))
        this._3hrsGraph.day = newDay.endOf('day');
      else
        this._3hrsGraph.day = newDay;
    } else {
      this._3hrsGraph.day = newDay;
    }
    this.getLast3hrs(false);
    this.updateLabel3hrsChart();
  }

  filter3hrsData = (data) => {
    const date = moment(data.label);
    const _3hrsAgo = moment(this._3hrsGraph.day).subtract(3, 'hours');
    return date.isBetween(_3hrsAgo, this._3hrsGraph.day, 'minutes', '[]') && date.isSame(this._3hrsGraph.day, 'day')
  }

  create3hrsChart = () => {
    const origin = this._3hrsGraph.data.filter((v) => (this.filter3hrsData(v)));
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.label);
      data[0].push(row.values);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointRadius: 0,
        pointHitRadius: 5,
      });
    });

    this.last3hrs = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 20,
                callback: function (value, index, values) {
                  return moment.utc(value).format("HH:mm");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true,
              },
              scaleLabel: {
                display: false
              },

              display: true,
              ticks: {
                maxTicksLimit: 5,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value.toFixed(4);
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionMonthChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([51, 133, 255], {
      label: 'Média Consumo',
      hidden: false
    }, (v) => (v[0]), data[0]);

    this.consumptionMonth = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value.toFixed(2);
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionYearChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([51, 133, 255], {
      label: 'Média Consumo',
      hidden: false
    }, (v) => (v[0]), data[0]);

    this.consumptionYear = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value.toFixed(1);
                }
              }
            }
          ]
        }
      }
    };
  };

  // Output array with times from 00:00 to 23:00
  createFullDayLabels = () => {
    let l = [];
    let time = moment.utc('00:00', 'HH:mm');
    for (let i = 0; i < 24; i++) {
      l.push(time.format('HH:mm'));
      time.add(1, 'h');
    }
    return l;
  };

  // Output an object with data for chart and other relevant info for background colors
  createDayData = (origin, colors, tints) => {
    let d = [[]]; // For values and their costs
    let c = [[]];
    let colorIndex = 0;
    for (let i = 0; i < origin.length; i++) {
      let m = moment.utc(origin[i].date);
      d[0][m.hour()] = origin[i].consumption;
      c[0][m.hour()] = origin[i].costConsumption;
    }

    // If we don't have data for this hour, use old data
    if (moment().minute() < 15) {
      let m = moment.utc(origin[origin.length - 1].date);
      d[0][m.hour()] = origin[0].consumption;
      c[0][m.hour()] = origin[0].costConsumption;
      colorIndex = m.hour();
    } else {
      colorIndex = moment.utc(origin[origin.length - 1].date).hour() + 1;
    }

    let backgroundColors = [[]];
    for (let i = 0; i < 24; i++) {
      if (i < colorIndex) {
        backgroundColors[0][i] = colors[0];
      } else {
        backgroundColors[0][i] = tints[0];
      }
    }

    return {
      data: d,
      cost: c,
      backgroundColors: backgroundColors,
      colorIndex: colorIndex
    }
  };

  // Get aggregate data from the collected data
  getAggregates = (dayData) => {
    let dayConsumption = 0;
    let costConsumption = 0;

    if (dayData && dayData.data.length === 1 && dayData.colorIndex) {
      for (let i = 0; i < dayData.colorIndex; i++) {
        dayConsumption += dayData.data[0][i];
        costConsumption += dayData.cost[0][i];
      }

      this.totalConsumption = Number.parseFloat((dayConsumption).toFixed(3));
      this.costConsumption = Number.parseFloat((costConsumption).toFixed(2));
    }
  };

  createDayChart = (origin) => {
    let labels = this.createFullDayLabels();
    let datasets = [];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];
    let tints = ["#BDD7FFBF"];
    let transparent = "#00000000";

    let dayData = this.createDayData(origin, colors, tints);
    let data = dayData.data;

    //replace previous day values with 0 and flip array so they are at the start and ignored by 'createAvgData'
    const actualValues = Array.from(dayData.data[0]).fill(0, dayData.colorIndex).reverse();
    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([51, 133, 255], {
      label: 'Média Consumo',
      hidden: false
    }, (v) => (v[0]), actualValues);

    // Set self consumed solar energy data
    this.getAggregates(dayData);

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        // backgroundColor: tints[i],
        backgroundColor: dayData.backgroundColors[i],
        borderColor: dayData.backgroundColors[i],
        pointBorderColor: transparent,
        pointBackgroundColor: transparent,
        // pointHoverBackgroundColor: tints[i],
        pointHoverBorderColor: transparent,
        fill: true
      });
    });

    this.last24hrs = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20
          }
        },
        cornerRadius: 10,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              return dataset.label + ": " + dataset.data[tooltipItem.index];
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                display: true,
                // TODO - Fix to properly use the chart options instead of this
                callback: function(value, index, ticks) {
                  if (index % 3 === 0 || index === ticks.length - 1)
                    return value;
                  else return '';
                }
              },
            }
          ],
          yAxes: [
            {
              id: "m³",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value.toFixed(2);
                }
              }
            }
          ]
        }
      }
    };
  };

}

DashboardWater2Controller.$inject = ["DashboardService", "$interval", "$scope", "$state", "AlarmService", "$http", "AuthenticationService"];
