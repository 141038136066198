export default class AdaptiveDashboardService {
  constructor(Smartmeter, AuthenticationService, Measure, $q, Site, Adaptivedashboard) {
    this.Smartmeter = Smartmeter;
    this.Auth = AuthenticationService;
    this.Measure = Measure;
    this.$q = $q;
    this.Site = Site;
    this.Adaptivedashboard = Adaptivedashboard;

    this.user = this.Auth.getUser();

    this.override = {
      "60acdc5b0b903917588ec45b": { //Temperature
        color: "#fda50f",
        fractionSize: 1,
        capacity: 60,
      },
      "60acdc530b903917588ec45a": { //Water
        color: "#0f53ba",
        fractionSize: 1,
      },
      "60acdc1c0b903917588ec458": { //Electricity
        unit: "kWh",
        //append: "",
        color: "#aa3033",
        convertValue: (value) => value / 1000,
      }
    }
  }

  getOverride = (measureId, key, defaultValue) => {
    if (this.override[measureId] && this.override[measureId][key])
      return this.override[measureId][key];
    else
      return defaultValue;
  }

  /**
   * @deprecated use list instead
   */
  getDashList = () => { //FIXME temporary
    let defer = this.$q.defer();
    this.Adaptivedashboard.find({filter:{ where: {siteIds: this.user.selected.siteId, active: true }}}).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    filter = Object.assign({}, {
      where: {
        siteIds: this.user.selected.siteId,
        active: true,
      },
      include: [],
      order: 'active DESC'
    }, filter);

    this.Adaptivedashboard.find({filter}).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));

    return defer.promise;
  }

  get = (id) => {
    let defer = this.$q.defer();
    this.Adaptivedashboard.findById({ id, filter: { include: ['sites'] } }).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  }

  listSmartmeters = (filter) => {
    let defer = this.$q.defer();

    filter = Object.assign({}, {
      where: {
        siteId: {inq: this.user.siteIds},
        active: true,
      },
      include: [],
      order: 'active DESC'
    }, filter);

    this.Smartmeter.find({
      filter
    }).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  }

  units = (filter) => {
    let defer = this.$q.defer();
    this.Measure.find(filter).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  }

  sites = (filter) => {
    let defer = this.$q.defer();

    filter = Object.assign({}, {
      where: {
        id: {inq: this.user.siteIds},
      },
      include: [],
    }, filter);

    this.Site.find({
      filter
    }).$promise
      .then(r => defer.resolve(r))
      .catch(e => defer.reject(e));
    return defer.promise;
  }

  selectedSite = () => {
    return this.user.selected.siteId;
  }

  save = (data) => {
    let defer = this.$q.defer();
    this.Adaptivedashboard.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getMeter = (smartmeterID) => {
    let defer = this.$q.defer();
    this.Smartmeter.findById({id: smartmeterID}).$promise
      .then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  }

  instant = (feedId) => {
    let defer = this.$q.defer();
    this.Site.openEnergyData({ no: feedId }).$promise
      .then(r => defer.resolve(r))
      .catch(err => defer.reject(err));
    return defer.promise;
  }

  getColor = (measureId) => this.getOverride(measureId, "color", "#000000");

  getChartData = (period, measureId, smartmeterIds, from, to) => {
    let defer = this.$q.defer();
    this.Adaptivedashboard.chartData({period, measureId, from, to, smartmeterIds}).$promise
      .then(r => defer.resolve(r))
      .catch(e => defer.reject(e));
    return defer.promise;
  }

}

AdaptiveDashboardService.$inject = ['Smartmeter', 'AuthenticationService', 'Measure', '$q', 'Site', 'Adaptivedashboard'];
