import { Divider, FormControlLabel, FormGroup, Stack, TextField, } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ApiData } from "../../utils";
import { CustomTextField, CustomSelect, CustomDatePicker, CustomSwitch, ImagePicker, } from "../../utils/CustomComponents";
import Features from "./Features";
const MeterForm = ({ state: { getErr, hasErr, isValid, setField, values: meter }, forceShowErrors, instalacao, }) => {
    const apiData = useContext(ApiData);
    const setFeatures = (funcionalidades) => {
        setField("Funcionalidades", funcionalidades);
    };
    const setResponsability = (value) => {
        setField("Responsability", value);
        if (value != 1)
            setField("InstallerId", undefined);
    };
    const hasError = (field) => hasErr(field, forceShowErrors);
    const getError = (field) => getErr(field, forceShowErrors);
    const resetImg = () => setField("Imagem", undefined);
    useEffect(() => {
        if (!instalacao.InstallerId)
            return;
        if (meter.Responsability != undefined)
            return;
        setField("Responsability", 1);
        setField("InstallerId", instalacao.InstallerId);
    }, [instalacao.InstallerId]);
    return (<div>
      <div style={{ display: "flex" }}>
        <Stack spacing={1} sx={{ width: "100%", marginRight: "10px" }}>
          <div style={{ display: "flex" }}>
            <CustomTextField obj={meter} field="Nome" label="Nome" handleChange={setField} required hasError={hasError} getHelperText={getError} sx={{ width: "100%", marginRight: "10px" }}/>
            <FormGroup sx={{ minWidth: 120 }}>
              <FormControlLabel control={<CustomSwitch obj={meter} field="Ativo" handleChange={setField}></CustomSwitch>} label="Ativo?"/>
            </FormGroup>
          </div>
          <CustomTextField obj={meter} field="Observacoes" label="Observações" handleChange={setField} multiline hasError={hasError} getHelperText={getError}/>
          <div style={{ display: "flex" }}>
            <CustomSelect obj={meter} field="UnidadeMedida" items={apiData.Measurements} label="Unidade de Medida" handleChange={setField} required sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomSelect obj={meter} field="TipoRegisto" items={apiData.Types} label="Tipo de registo" handleChange={setField} required sx={{ width: "50%" }}/>
          </div>
          <div style={{ display: "flex" }}>
            <TextField label="Instalação" variant="standard" required disabled sx={{ width: "50%", marginRight: "10px" }} defaultValue={instalacao.Nome}/>
            <CustomTextField obj={meter} field="SerialNumber" label="Serial Number" handleChange={setField} sx={{ width: "50%" }} hasError={hasError} getHelperText={getError}/>
          </div>
          <div style={{ display: "flex" }}>
            <CustomTextField obj={meter} field="OpenEnergyNodeId" label="Open Energy Node ID" handleChange={setField} required sx={{ width: "50%", marginRight: "10px" }} hasError={hasError} getHelperText={getError}/>
            <CustomTextField obj={meter} field="CapacidadeMaxima" label="Capacidade Maxima (Wh)" handleChange={setField} sx={{ width: "50%" }} hasError={hasError} getHelperText={getError}/>
          </div>
          <div style={{ display: "flex" }}>
            <CustomSelect obj={meter} field="TarifarioEnergia" items={[apiData.DefaultTariff]} label="Tarifário de energia" handleChange={setField} required sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomDatePicker obj={meter} field="DataInicio" label="Data de início" sx={{ width: "50%" }} handleChange={setField} hasError={hasError} getHelperText={getError}/>
          </div>
          <div style={{ display: "flex" }}>
            <CustomSelect obj={meter} field="Responsability" items={apiData.responsabilityChoices} label="Responsabilidade" handleChange={(_, value) => setResponsability(parseInt(value, 10))} sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomSelect obj={meter} disabled={!(meter.Responsability == 1)} field="InstallerId" items={apiData.Installers} label="Instalador" handleChange={setField} sx={{
            width: "50%",
        }}/>
          </div>
          <FormGroup sx={{ width: "50%", minWidth: 120 }}>
            <FormControlLabel control={<CustomSwitch obj={meter} field="LeituraConsumoGeral" handleChange={setField}/>} label="Leitura de Consumo geral?"/>
          </FormGroup>
        </Stack>
        <ImagePicker img={meter.Imagem} setImg={(file) => setField("Imagem", file)} resetImg={resetImg} sx={{
            height: "373px",
        }}/>
      </div>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
      <Features features={meter.Funcionalidades} setFeatures={setFeatures}/>
    </div>);
};
export default MeterForm;
