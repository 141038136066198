import { routes } from "./routes";
import TariffService from "./service";
import TariffListController from "./list/controller";
import TariffWizardController from "./wizard/controller";
import TariffDetailsController from "./details/controller";

export default angular
  .module("app.tariff", [])
  .config(routes)
  .controller("TariffListController", TariffListController)
  .controller("TariffWizardController", TariffWizardController)
  .controller("TariffDetailsController", TariffDetailsController)
  .service("TariffService", TariffService).name;
