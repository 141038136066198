import { useEffect, useState } from "react";
import { validateObj } from "./utils";
const createTouched = () => {
    const temp = {};
    for (const key in temp) {
        temp[key] = false;
    }
    return temp;
};
const createErrors = () => {
    return {};
};
export const useFormHook = (defaultValues, validators, data = {}) => {
    const [obj, setObj] = useState(Object.assign({}, defaultValues, data));
    const [errors, setErrors] = useState(createErrors());
    const [touched, setTouched] = useState(createTouched());
    const setField = (field, value) => {
        setObj((prevValue) => Object.assign({}, prevValue, { [field]: value }));
        setTouched((prevTouched) => Object.assign({}, prevTouched, { [field]: true }));
    };
    const set = (obj) => {
        setObj(obj);
    };
    const reset = () => {
        setObj(Object.assign({}, defaultValues, data));
        setErrors(createErrors());
        setTouched(createTouched());
    };
    const isValid = () => {
        return Object.values(errors).every((error) => error === "");
    };
    const hasErr = (field, force) => (touched[field] || force) &&
        typeof errors[field] === "string" &&
        errors[field].length > 0;
    const getErr = (field, force) => hasErr(field, force) ? errors[field] : null;
    useEffect(() => setErrors(validateObj(obj, validators)), [obj]);
    return { values: obj, setField, isValid, hasErr, getErr, set, reset };
};
