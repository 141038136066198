export default class TariffService {
  constructor($q, Tariff, Site, Tariffcycle, AuthenticationService, Cycle) {
    this.$q = $q;
    this.Tariff = Tariff;
    this.Site = Site;
    this.Tariffcycle = Tariffcycle;
    this.Auth = AuthenticationService;
    this.Cycle = Cycle;
  }

  powers = () => [1.15, 2.30, 3.45, 4.6, 5.75, 6.9, 10.35, 13.8, 17.25, 20.7, 27.6, 34.5, 41.4];

  /*
   * Get list of tariffs based on entity of user
   */
  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    filter = filter || {};
    filter.where = filter.where || {};

    filter.where["entityId"] = user.selected.entityId // filtrar pela entidade da instalação escolhida

    filter.include = [
      "tariffcycle",
      {
        relation: "smartmeters",
        scope: {
          fields: {
            id: true,
            aggregatorId: true
          },
        },
      },
    ];

    this.Tariff.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Tariff.find({
          filter: filter
        }).$promise.then((r) => {
          return defer.resolve({
            total: c.count,
            data: r,
          });
        })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  /*
   * Get list of tariffs based on entity of user
   */
  get = (id) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    this.Tariff.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          {
            relation: "tariffcycle",
            scope: {
              include: "cycle"
            }
          },
          {
            relation: "smartmeters",
            scope: {
              fields: {
                id: true,
                aggregatorId: true
              },
            },
          },
        ],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  // Generator template for energy cycles
  tableGenerator = () => {
    let defer = this.$q.defer();
    let table = [];
    // Days of week
    // This is moment's ISO Day of Week (1 - Monday, 7 - Sunday
    for (let dayWeek = 1; dayWeek < 8; dayWeek++) {
      // Every 15 min
      let o = {
        dayOfWeek: dayWeek,
        timetable: [],
      };
      for (let i = 0; i < 24 * 4; i++) {
        o.timetable.push({
          i: i,
        });
      }
      table.push(o);
    }
    defer.resolve(table);
    return defer.promise;
  };

  /*
   * Return list of chargers grouped by site
   */
  smartmeters = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        where: {
          entityId: {
            inq: user.entityIds,
          },
        },
        include: "smartmeters",
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  cycles = () => {
    let defer = this.$q.defer();
    this.Tariffcycle.find({
      filter: {
        include: 'cycle'
      }
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    if (!data.entityId)
      data.entityId = user.selected.entityId;
    this.Tariff.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

TariffService.$inject = [
  "$q",
  "Tariff",
  "Site",
  "Tariffcycle",
  "AuthenticationService",
  "Cycle"
];
