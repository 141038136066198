export default class DashbordSwitchController {
  constructor(UIService, $interval, DashboardService, SmartmeterService) {
    this.UI = UIService;
    this.$interval = $interval;
    this.Dashboard = DashboardService;
    this.Smartmeter = SmartmeterService;
    this.loaded = false;
    this.loadData();
    // The span it stops from polling after pressing a button (in seconds)
    this.PRESS_INTERVAL = 10;
  }

  setState = (obj, val) => {
    if (obj.state == val) {
      return "btn-primary";
    }
    return "btn-default";
  };

  setButtonState = (obj, state) => {
    if (obj.value == state.value) {
      return "pmd-btn-raised";
    }
    return "pmd-btn-outline";
  };

  loadData = () => {
    // Interruptor Shelly
    this.Dashboard.getSmartmeters("63a4c9a62dba4e2b68227770").then((r) => {
      let list = [];
      r.forEach((x) => {
        x.features.forEach((f, i) => {
          f.smartmeter = x;
          f.index = i;
          f.states = f.states || [];
          if (f.states.length > 0) {
            list.push(f);
            this.poll(f);
            this.$interval(() => {
              this.poll(f);
            }, 15 * 1000);
          }
        });
      });
      this.data = list;
      this.loaded = true;
    });
    // Controlo de Rega
    this.Dashboard.getSmartmeters("63e0db2724233337d3006f04").then((r) => {
      let listRega = [];
      r.forEach((x) => {
        x.features.forEach((f, i) => {
          f.smartmeter = x;
          f.index = i;
          f.states = f.states || [];
          if (f.states.length > 0) {
            listRega.push(f);
            this.poll(f, i);
            this.$interval(() => {
              this.poll(f, i);
            }, 15 * 1000);
          }
        });
      });
      this.dataRega = listRega;
      this.loadedRega = true;
    });
  };

  poll = (obj) => {
    if (!obj.pressed) {
      this.Smartmeter.state(obj.smartmeter.id, obj.index).then((r) => {
        obj.value = r.value;
      });
    } else {
      // If we have pressed less than 10 seconds ago, do nothing...
      if (moment().diff(obj.pressed, "seconds") < this.PRESS_INTERVAL) {
        // Do nothing...
      } else {
        this.Smartmeter.state(obj.smartmeter.id, obj.index).then((r) => {
          // If the value is different, show error, but update anyway
          if (obj.state !== r.value) {
            this.UI.addToast(
              "Ocorreu um erro na ligação entre o servidor e o sensor. Verifique a ligação de rede."
            );
            // let alert = this.UI.showAlert("Ocorreu um erro na ligação entre o servidor e o sensor. Verifique a ligação de rede.");
            // alert.finally(() => {});
          }
          obj.value = r.value;
          obj.pressed = null;
        });
      }
    }
  };

  trigger = (obj, feature, state) => {
    this.Smartmeter.trigger(obj.smartmeter.id, feature, state).then((r) => {
      obj.value = state.value;
    });
  };
}

DashbordSwitchController.$inject = [
  "UIService",
  "$interval",
  "DashboardService",
  "SmartmeterService",
];
