import { IconButton, TextField, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { ApiData } from "../../../utils";
import { CustomSelect } from "../../../utils/CustomComponents";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";
const Feature = ({ feature, handleChange, deleteFeature }) => {
    const apiData = useContext(ApiData);
    return (<div style={{ display: "flex" }}>
      <TextField label="Nome" value={feature.Nome} onChange={(e) => handleChange("Nome", e.target.value)} variant="standard" sx={{ marginRight: "10px", width: "35%" }}/>
      <CustomSelect obj={feature} field="UnidadeMedida" handleChange={handleChange} items={apiData.Measurements} label="Unidade de Medida" sx={{ minWidth: "180px", marginRight: "10px", width: "35%" }}/>
      <TextField label="FeedId" value={feature.FeedId} onChange={(e) => handleChange("FeedId", e.target.value)} variant="standard" sx={{ minWidth: "60px", marginRight: "10px", width: "15%" }}/>
      <TextField label="CapacidadeMaxima" value={feature.CapacidadeMaxima} onChange={(e) => handleChange("CapacidadeMaxima", e.target.value)} variant="standard" sx={{ marginRight: "10px", width: "15%" }}/>
      <IconButton onClick={deleteFeature} color="error">
        <Tooltip title="Remover Funcionalidade">
          <Icon path={mdiDelete} size={1}/>
        </Tooltip>
      </IconButton>
    </div>);
};
export default Feature;
