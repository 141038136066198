export default class InstallerService {
  constructor($q, AuthenticationService, Installer, $http) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Installer = Installer;
    this.$http = $http;
  }

  list = (filter) => {
    let defer = this.$q.defer();

    this.Installer.count({
      filter: filter
    }).$promise.then(c => {
      this.Installer.find({
        filter: filter
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Installer.findById({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/solar/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  save = data => {
    let defer = this.$q.defer();
    this.Installer.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };
}

InstallerService.$inject = ['$q', 'AuthenticationService', 'Installer', '$http'];
