export default class LayoutController {
  constructor($scope, $rootScope, AuthenticationService, AuthorizationService, $state, UIService, NotificationService, $injector, $interval, SiteService) {
    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.NotificationService = NotificationService;
    this.Site = SiteService;
    this.$state = $state;
    this.$scope = $scope;
    this.menuItems = [];
    this.UI = UIService;
    this.$injector = $injector;
    this.$interval = $interval;
    $scope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
      $rootScope.status = data;
    });

    $scope.$on('$menuRefactor', () => {
      let c = angular.copy(this.menuItems);
      this.mobileItems = c.filter(r => {
        return r.mobile == true && !r.hasOwnProperty('sections')
      });
    })

    $scope.$on('$entityUpdate', () => {
      this.user = AuthenticationService.getUser();
      this.AuthenticationService.setSites(this.loadSites());
    });

    $scope.$on('$siteUpdate', () => {
      this.user = AuthenticationService.getUser();
      $state.refresh();
    });

    $scope.$on('$inlineHelpUpdate', () => {
      this.user = AuthenticationService.getUser();
    });

    this.menuItems = [];

    this.user = AuthenticationService.getUser();

    if (this.user) {
      this.Site.get(this.user.selected.siteId).then(r => {
        this.selectedSiteName = r.name;
        this.licenseInfo = r.license;
        if (r.photo)
          this.Site.getPhoto(r.photo.filename, r.photo.container).then(_ => {
            this.siteImg = URL.createObjectURL(_.data);
          });
      })
      AuthenticationService.hasReadErrors().then(r => {
        this.hasReadError = r.hasError;
        this.readErrors = r.errors;
      });
    }


    this.isAuthenticated = () => {
      AuthenticationService.isAuthenticated();
    };

    this.loadNotifications();
    NotificationService.listener((c) => {
      this.loadNotifications();
    });

    this.parseMenu();
  }

  isAdmin = () => this.AuthorizationService.isAdmin();

  showLicense = () => {
    this.UI.showDialog({
      template: require('./license.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.license = this.licenseInfo;

        $scope.ok = () => {
          $scope.$close();
        }
      }]
    });
  }

  loadEntities = () => {
    let entities = this.AuthorizationService.getEntities();
    let entity = this.user.selected.entityId;
    let o = entities.find(r => r.id == entity)
    if (o) {
      o.expanded = true;
    }
    entities.sort((a,b) => a.name.localeCompare(b.name));
    return entities;
  };

  loadSites = () => {
    return this.AuthorizationService.getSites();
  };

  clearSites = () => {
    this.AuthenticationService.setSites(this.loadSites());
  };

  loadNotifications = () => {
    this.NotificationService.load().then(r => {
      this.notifications = r;
    });
  };

  setSite = (site, entitySites) => {
    this.AuthenticationService.updateSite(site, entitySites);
  };

  updateNotification = notification => {
    notification.seen = true;
    if (this.notifications.unseen > 0) {
      this.notifications.unseen--;
    }
    notification.$save();
  };

  deleteNotifications = () => {
    this.UI.showConfirm("Apagar notificações?").then(r => {
      if (r) {
        this.NotificationService.delete().then(r => {
          this.notifications = r;
        });
      }
    });
  }

  logout = () => {
    this.AuthenticationService.logout();
  };

  isWorthShowingSites = () => {
    let entities = this.loadEntities();
    if (entities.length == 0) {
      return false;
    } else {
      let sites = [];
      entities.forEach(r => {
        sites.push(...r.sites);
      });
      return sites.length > 1;
    }

  }

  toggleInlineHelp = () => {
    this.AuthenticationService.toggleInlineHelp();
  };

  parseMenu = () => {
    const jsonMenu = require('../navigation.json');
    // Getting list of all states, so that role will be loaded from states...
    let states = this.$state.get();
    states = states.filter(s => {
      // Ignore abstract routes and placeholders
      return s.hasOwnProperty('template') && s.hasOwnProperty('controller');
    });

    // Iterate the sidebar options through the states, to get the required role for it
    let menu = [];
    jsonMenu.forEach((section, index) => {
      section.order = index;
      if (section.dynamic && section.dynamic == true) {
        // Inject siteId data
        let where = section.where || {};
        where.siteId = { inq: [this.user.selected.siteId] }
        // Load data based on model
        this.$injector.get(section.model).count({
          where: where
        }).$promise.then(r => {
          if (r.count > 0) {
            menu.push(section);
          }
        });
      } else if (section.hasOwnProperty('sections')) {
        let k = [];
        section.sections.forEach(o => {
          let s = states.find(s => s.name.includes(o.state));
          if (angular.isDefined(s)) {
            if (s.hasOwnProperty('role')) {
              if (this.AuthorizationService.canPerform(s.role))
                k.push(o)
            } else k.push(o);
          } else {
            // Do nothing... :)
          }
        });
        if (k.length > 0) {
          section.sections = k;
          menu.push(section);
        }
      } else {
        let s = states.find(r => r.name.includes(section.state));
        if (angular.isDefined(s)) {
          if (s.hasOwnProperty('role')) {
            if (this.AuthorizationService.canPerform(s.role)) {
              menu.push(section)
            }
          } else {
            menu.push(section);
          }
        } else {
          // Do nothing... :)
        }
      }
    });
    this.menuItems = menu;
    let counter = 0;
    this.t = this.$interval(() => {
      menu.forEach(entry => {
        if (!entry.hasOwnProperty('mobile')) {
          entry.mobile = true;
        }
      });
      this.menuItems = menu;
      this.$scope.$broadcast('$menuRefactor');
      if (++counter == 10) {
        this.$interval.cancel(this.t);
      }
    }, 150);
  };
}

LayoutController.$inject = ['$scope', '$rootScope', 'AuthenticationService', 'AuthorizationService', '$state', 'UIService', 'NotificationService', "$injector", "$interval", "SiteService"];
