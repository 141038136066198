
export default class AdaptiveDashboardListController {
  constructor($state, AdaptiveDashboardService) {
    this.Service = AdaptiveDashboardService;
    this.isLoaded = false;
    this.loadData();
  }

  loadData = () => {
    this.Service.getDashList().then((dashList) => {
      this.dashList = dashList;
      this.isLoaded = true;
    });
  }
}

AdaptiveDashboardListController.$inject = ['$state', 'AdaptiveDashboardService'];
