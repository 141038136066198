import 'propellerkit-range-slider';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

import angular from 'angular';

export default angular.module('app.interface.slider', [])
  .directive('slider', function () {
    return {
      restrict: 'A',
      scope: {
        ngModel: "=",
        options: "="
      },
      link: ($scope, $element, $attrs) => {

        $scope.tooltip = angular.isDefined($attrs.hover) ?
          $scope.$parent.$eval($attrs.hover) : false;

        let element = $element.parent().hasClass('pmd-slider') ? $element.parent() : $element;

        let parsedValue = null;

        $scope.config = {
          start: $scope.ngModel || $scope.options.range.min,
          connect: 'lower',
          ariaFormat: wNumb({
            decimals: 0
          }),
          format: wNumb({
            decimals: 0
          }),
          range: $scope.options.range
        };

        if ($scope.tooltip === true) {
          $scope.config.tooltips = wNumb({decimals: 0});
        }

        if (angular.isDefined($scope.options.step)) {
          $scope.config.step = $scope.options.step;
        }

        if (angular.isDefined($scope.options.snap)) {
          $scope.config.snap = $scope.options.snap;
        }

        if (angular.isDefined($scope.options.pips)) {
          $scope.config.pips = $scope.options.pips;
        }

        let slider = noUiSlider.create(element[0], $scope.config);

        slider.on('slide', (val) => {
          parsedValue = parseInt(val[0]);
          return $scope.$apply(() => $scope.ngModel = parsedValue);
        });

        $scope.$watch(function () {
          return $scope.ngModel;
        }, function (val) {
          if (val !== parsedValue) {
            slider.set(val);
          }
        });
      }
    }
  })
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Slider',
      description: 'Make selections from a range of values.',
      version: '1.0.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
