export default angular.module('app.interface.transfer', [])
  .controller('pmdTransferController', ['$scope', '$attrs', function ($scope, $attrs) {
    $scope.$watch(() => {
      return $scope.ngDisabled;
    }, (val) => {
      $scope.disabled = val;
    });
    // Track list
    $scope.left = $scope.options && $scope.options.list ? $scope.options.list : [];
    $scope.right = $scope.ngModel;
    // Remove duplicates if right is selected
    $scope.left = $scope.left.filter(el => {
      if ($scope.options.key) {
        return !$scope.right.find(r => r[$scope.options.key] == el[$scope.options.key]);
      } else {
        return !$scope.right.includes(el);
      }
    });

    $scope.select = data => {
      if ($scope.disabled)
        return;
      data.selected = !data.selected;
    }

    $scope.label = $scope.options.label || 'name';

    $scope.showData = data => {
      // If has keys, show by label param, else show entire
      if (Object.keys(data).length > 0) {
        return data[$scope.label];
      } else {
        return data;
      }
    }

    $scope.moveLeft = () => {
      if ($scope.disabled) {
        return
      }
      for (let i = 0; i < $scope.right.length; i++) {
        if ($scope.right[i].selected) {
          $scope.right[i].selected = false;
          $scope.left.push($scope.right[i]);
          $scope.right.splice(i, 1);
        }
      }
      $scope.rightSelectAll = false;
      $scope.onChanged();
    }

    $scope.moveRight = () => {
      if ($scope.disabled) {
        return
      }
      for (let i = 0; i < $scope.left.length; i++) {
        if ($scope.left[i].selected) {
          $scope.left[i].selected = false;
          $scope.right.push($scope.left[i]);
          $scope.left.splice(i, 1);
        }
      }
      $scope.leftSelectAll = false;
      $scope.onChanged();
    }

    $scope.sendAllLeft = () => {
      if ($scope.disabled) {
        return
      }
      $scope.left.forEach(r => {
        r.selected = false;
      });
      $scope.left.push(...$scope.right);
      $scope.right = [];
    }

    $scope.sendAllRight = () => {
      if ($scope.disabled) {
        return
      }
      $scope.right.forEach(r => {
        r.selected = false;
      });
      $scope.right.push(...$scope.left);
      $scope.left = [];
    }
  }])
  .directive('pmdTransfer', function () {
    return {
      restrict: 'E',
      require: 'ngModel',
      bindToController: false,
      controller: 'pmdTransferController',
      scope: {
        ngModel: "=",
        options: "=",
        onChanged: "&?",
        ngDisabled: "=?"
      },
      template: require('./pmd-transfer.html'),
      replace: true,
      link: (scope, element, attrs, controller) => {}
    };
  })
  .name;
