export default class LoginController {
  constructor($rootScope, $scope, $state, $window, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.email = "";
    this.password = "";
    this.error = "";
    this.waiting = false;
    this.emailCompleted = false;

    this.cLogin();

    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.providers = data.providers;
      this.data = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
    });

    $window.addEventListener(
      "message",
      (message) => {
        $scope.$apply(() => {
          let data = message.data;
          if (data.type && data.type === "oauth") {
            if (data.result === true) {
              this.UI.addToast("A redireccionar...");
              location.reload(true);
            } else {
              this.UI.addToast(
                "Impossível autenticar com a sua conta social, por favor entre novamente com a sua password da conta da plataforma HGP Mobility. Caso não saiba essa password, proceda à recuperação da mesma"
              );
            }
          }
        });
      },
      false
    );
  }

  createSSO = (provider) => {
    let opt = {};
    if (provider.hasOwnProperty("color")) {
      opt["background-color"] = provider.color;
    }
    if (provider.hasOwnProperty("text")) {
      opt["color"] = provider.text;
    }
    return opt;
  };

  magiclink = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza um email");
      return;
    }
    this.emailCompleted = true;
    this.Authentication.magiclink(this.email).then((r) => {
      this.cMailbox();
    });
  };

  cPass = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza o email");
      return;
    }
    this.emailCompleted = true;
    this.card = "password";
    this.cardTitle = "Introduza palavra-passe de " + this.email;
  };

  cLogin = () => {
    this.card = "login";
    this.cardTitle = "Iniciar sessão";
  };

  cMailbox = () => {
    this.card = "mailbox";
    this.cardTitle = "Verifique a caixa de entrada";
  };

  performLogin = () => {
    this.waiting = true;
    this.Authentication.login(this.email, this.password)
      .then((res) => {
        this.waiting = false;
        if (res.twofactor) {
          this.$state.go("auth.two-factor", {
            allow: true,
          });
        } else {
          this.$state.go("app.dashboard");
        }
      })
      .catch((err) => {
        this.waiting = false;
        switch (err.data.error.code) {
          case "LOGIN_FAILED":
            this.UI.addToast("Email ou password errados");
            break;
          case "LOGIN_BLOCKED":
            this.UI.addToast("A conta está bloqueada");
            break;
        }
      });
  };

  handle = (provider) => {
    let width = 650;
    let height = 700;
    let left = screen.width / 2 - width / 2;
    let top = screen.height / 2 - height / 2;
    this.$window.open(
      `/auth/${provider.name}`,
      "",
      `toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=0,width=${width},height=${height},left=${left},top=${top}`
    );
  };
}

LoginController.$inject = [
  "$rootScope",
  "$scope",
  "$state",
  "$window",
  "AuthenticationService",
  "UIService",
];
