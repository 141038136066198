export default class UserAdminController {
  constructor($rootScope, $state, AdministrationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Administration = AdministrationService;
    this.UI = UIService;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
    });

    this.type = 'password';
    this.iconType = 'mdi-eye-off';
    this.user = {
      imap: false,
      email: '',
      name: '',
      password: ''
    };
    this.sendEmail = false;
  }

  randomizePassword = () => {
    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < 12; x++) {
      let i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    this.user.password = pass;
  };

  showPassword = () => {
    if (this.type.includes('password')) {
      this.type = 'text';
      this.iconType = 'mdi-eye';
    } else {
      this.type = 'password';
      this.iconType = 'mdi-eye-off';
    }
  };

  createAccount = () => {
    if (this.user.imap) {
      this.randomizePassword();
    }
    this.Administration.createUser(this.user).then(() => {
      this.UI.addToast('Utilizador adicionado');
      this.$state.go('app.administration.main');
    });
  }



}

UserAdminController.$inject = ['$rootScope', '$state', 'AdministrationService', 'UIService'];
