export function routes($stateProvider) {
  $stateProvider
    .state('app.leituras', {
      url: '/leituras',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.leituras.list', {
      url: '/widget?{measure:string}&{load:bool}',
      template: require('./list/view.html'),
      controller: 'LeiturasListController',
      controllerAs: 'vm',
      params: {
        load: false
      }
    })
    .state('app.leituras.table', {
      url: '/table?{page:int}&{order:string}&{sort:string}',
      template: require('./table/view.html'),
      controller: 'LeiturasTableController',
      controllerAs: 'vm'
    })
    .state('app.leituras.smartmeter', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
      template: require('./smartmeter/view.html'),
      controller: 'LeiturasSmartmeterController',
      controllerAs: 'vm'
    })
    .state('app.leituras.aggregate', {
      url: '/{id}/aggregate?{from:string}&{to:string}',
      template: require('./aggregate/view.html'),
      controller: 'LeiturasAggregateController',
      controllerAs: 'vm',
    })
    .state('app.leituras.registers', {
      url: '/{id}/registers?{from:string}&{to:string}&{page:int}&{order:string}&{sort:string}',
      template: require('./registers/view.html'),
      controller: 'LeiturasRegisterController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
