import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button, Stack, ListItemText, List, ThemeProvider, ListItemAvatar, ListItemSecondaryAction, Tooltip, Checkbox, ListItemButton, Typography, } from "@mui/material";
import { theme } from "../../wizard/utils";
import UserAvatar from "../../users/UserAvatar";
import "../Roles.css";
const UsersModalComponent = ({ open, onClose, modalType, usersList, }) => {
    const [users, setUsers] = useState(usersList);
    const [newUsers, setNewUsers] = useState([]);
    const [removeUsers, setRemoveUsers] = useState([]);
    const [inputText, setInputText] = useState("");
    useEffect(() => {
        setUsers(usersList);
    }, [users, newUsers, removeUsers, usersList]);
    const closeModal = () => {
        setUsers([]);
        setInputText("");
        onClose(newUsers, removeUsers);
    };
    const cancelModal = () => {
        modalType ? setNewUsers([]) : setRemoveUsers([]);
        closeModal();
    };
    const handleAddClick = (user) => {
        if (modalType) {
            setNewUsers((prevSelectedUsers) => {
                if (prevSelectedUsers.includes(user)) {
                    return prevSelectedUsers.filter((selectedUser) => selectedUser !== user); // remover do array
                }
                else {
                    return [...prevSelectedUsers, user]; // adicionar ao array
                }
            });
        }
        else {
            setRemoveUsers((prevSelectedUsers) => {
                if (prevSelectedUsers.includes(user)) {
                    return prevSelectedUsers.filter((selectedUser) => selectedUser !== user); // remover do array
                }
                else {
                    return [...prevSelectedUsers, user]; // adicionar ao array
                }
            });
        }
    };
    const addUsers = () => {
        //console.log("Utilizadores a adicionar: ", newUsers);
        //console.log("Utilizadores a remover: ", removeUsers);
        closeModal();
    };
    const handleText = (e) => {
        // search by name or email
        const filtered = usersList.filter((user) => user.name.includes(e.target.value) ||
            user.email.includes(e.target.value));
        setUsers(filtered);
        setInputText(e.target.value);
    };
    return (<ThemeProvider theme={theme}>
      <Modal open={open} onClose={cancelModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modalUserStyle" sx={{ height: users.length === 0 ? "auto" : "90%" }}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {modalType ? "Adicionar " : "Remover "} Utilizador
            </Typography>

            {users.length === 0 ? (<Typography id="modal-modal-info" variant="subtitle1" component="h2">
                Sem utilizadores associados
              </Typography>) : (<div style={{ width: "50%", margin: "auto" }}>
                <TextField id="outlined-basic" label="Pesquisa" variant="standard" fullWidth value={inputText} onChange={handleText}/>
              </div>)}
          </Stack>
          <Box sx={{ maxHeight: "80%", overflow: "auto" }}>
            <List>
              {users.map((user) => (<ListItemButton key={user.id} onClick={() => handleAddClick(user)}>
                  <ListItemAvatar>
                    <UserAvatar name={user.name}/>
                  </ListItemAvatar>

                  <ListItemText primary={user.name} secondary={user.email}/>
                  <ListItemSecondaryAction>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Adicionar utilizador">
                        <Checkbox checked={modalType
                ? newUsers.includes(user)
                : removeUsers.includes(user)}/>
                      </Tooltip>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItemButton>))}
            </List>
          </Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} marginRight={2} marginTop={1.5}>
            <Button sx={{ color: "black" }} onClick={cancelModal}>
              Cancelar
            </Button>
            <Button onClick={addUsers}>Ok</Button>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>);
};
export default UsersModalComponent;
