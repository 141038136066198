export default class AlarmListController {
  constructor($state, UIService, AlarmService) {
    this.$state = $state;
    this.UI = UIService;
    this.Alarm = AlarmService;

    AlarmService.loadFilterData()
      .then((r) => {
        this.params = r;
        this.loaded = true;

        if (!$state.params.filter) {
          this.filter = {
            time: {
              from: moment().subtract(7, "day").startOf("day"),
              to: moment().endOf("day"),
            },
            sites: r.sites,
            evses: r.evses,
            levels: r.levels,
          };
        } else {
          this.filter = $state.params.filter;
        }

        this.loadData();
      })
      .catch((e) => {
        this.UI.addToast("Erro ao carregar dados para filtragem");
      });
  }

  loadData = () => {
    let siteIds = this.filter.sites.map((r) => r.id);
    let evseIds = this.filter.evses.map((r) => r.id);
    let levels = this.filter.levels.map((r) => r.id);
    this.loaded = false;
    this.Alarm.list({
      filter: {
        where: {
          siteId: {
            inq: siteIds,
          },
          evseId: {
            inq: evseIds,
          },
          level: {
            inq: levels,
          },
        },
        order: "timestamp DESC",
      },
    }).then((r) => {
      this.total = r.total;
      this.data = r.data;
      this.start = 1 || 0;
      this.end = r.data.length || 0;
      this.loaded = true;
    });
  };

  show = (row) => {};

  filterLevels = () => {
    this.dialogFilter(this.params.levels, "name").then((r) => {
      this.filter.levels = r;
    });
  };

  filterEvses = () => {
    let siteIds = this.filter.sites.map((r) => r.id);
    let evses = this.params.evses.filter(
      (r) => siteIds.indexOf(r.siteId) != -1
    );
    this.dialogFilter(evses, "alias").then((r) => {
      this.filter.evses = r;
    });
  };

  filterSites = () => {
    this.dialogFilter(this.params.sites, "name").then((r) => {
      this.filter.sites = r;
      let siteIds = this.filter.sites.map((a) => a.id);
      this.filter.evses = this.params.evses.filter(
        (a) => siteIds.indexOf(a.siteId) != -1
      );
    });
  };

  readableSiteFilter = () => {
    if (!this.params) {
      return "";
    }

    if (this.filter.sites.length === this.params.sites.length) {
      return "Todas as instalações selecionadas";
    }

    if (this.filter.sites.length === 1) {
      return this.filter.sites[0].name;
    } else {
      return `${this.filter.sites.length} instalações selecionadas`;
    }
  };

  readableEvseFilter = () => {
    if (!this.params) {
      return "";
    }

    if (this.filter.evses.length === this.params.evses.length) {
      return "Todas os carregadores selecionados";
    }

    if (this.filter.evses.length === 1) {
      return this.filter.evses[0].alias;
    } else {
      return `${this.filter.evses.length} carregadores selecionados`;
    }
  };

  readableLevelFilter = () => {
    if (!this.params) {
      return "";
    }

    if (this.filter.levels.length === this.params.levels.length) {
      return "Todos os níveis selecionados";
    }

    if (this.filter.levels.length === 1) {
      return this.filter.levels[0].name;
    } else {
      return `${this.filter.levels.length} níveis selecionados`;
    }
  };

  dialogFilter = (list, property) => {
    return this.UI.showDialog({
      template: require("./select.dialog.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          // Arrays of data
          $scope.property = property;
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.selectAll = () => {
            $scope.list.forEach((r) => {
              r.selected = true;
            });
          };

          $scope.all = () => {
            let exists = $scope.list.filter((r) => r.selected === false);
            return exists.length > 0;
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    });
  };

  getAlarmLevel = (alarm) => {
    return this.Alarm.getReadableLevel(alarm);
  };

  getAlarmError = (alarm) => {
    return this.Alarm.getReadableError(alarm);
  };
}

AlarmListController.$inject = ["$state", "UIService", "AlarmService"];
