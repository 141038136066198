import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import Feature from "./Feature";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { createFuncionalidade } from "../../../hooks/utils";
const Features = ({ features, setFeatures }) => {
    const handleFeatureChange = (index, field, value) => {
        const updatedFeatures = features;
        updatedFeatures[index] = Object.assign({}, updatedFeatures[index], {
            [field]: value,
        });
        setFeatures(updatedFeatures);
    };
    const addFeature = () => {
        const updatedFeatures = features;
        updatedFeatures.push(createFuncionalidade({}));
        setFeatures(updatedFeatures);
    };
    const removeFeature = (index) => {
        const updatedFeatures = features;
        updatedFeatures.splice(index, 1);
        setFeatures(updatedFeatures);
    };
    return (<div>
      <div style={{ display: "flex" }}>
        <Typography sx={{ mt: 1, mb: 1, width: "100%" }}>
          Funcionalidades Incorporadas
        </Typography>
        <IconButton onClick={addFeature} color="primary">
          <Tooltip title="Adicionar Funcionalidade">
            <Icon path={mdiPlus} size={1}/>
          </Tooltip>
        </IconButton>
      </div>
      <Stack spacing={3} sx={{ mt: 1 }}>
        {features.map((feature, index) => (<Feature key={index} feature={feature} deleteFeature={() => removeFeature(index)} handleChange={(field, value) => handleFeatureChange(index, field, value)}/>))}
      </Stack>
    </div>);
};
export default Features;
