export function routes($stateProvider) {
  $stateProvider
    .state("app.alarms", {
      url: "/alarm",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.alarms.list", {
      url: "?{page:int}&{term:string}&{order:string}&{sort:string}",
      template: require("./list/view.html"),
      controller: "AlarmListController",
      controllerAs: "vm",
      //role: ["readAlarm"],
    });
}

routes.$inject = ["$stateProvider"];
