export default class SmartmeterService {
  constructor(
    $q,
    $rootScope,
    AuthenticationService,
    AuthorizationService,
    Location,
    Smartmeter,
    Consumption,
    Site,
    Measure,
    Asset,
    $http
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Location = Location;
    this.Smartmeter = Smartmeter;
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.Consumption = Consumption;
    this.Measure = Measure;
    this.Site = Site;
    this.Asset = Asset;
    this.$http = $http;
  }

  resposabilityChoices = () => [
    { id: 0, name: "Streamline" },
    { id: 1, name: "Instalador" },
    { id: 2, name: "Cliente" },
  ];

  state = (id, feature) => {
    return this.Smartmeter.state({ id: id, feature: feature }).$promise;
  };

  trigger = (id, feature, state) => {
    return this.Smartmeter.trigger({ id: id, feature: feature, state: state })
      .$promise;
  };

  types = () => {
    return [
      { id: 0, name: "Consumo" },
      { id: 1, name: "Produção Bruta" },
      { id: 2, name: "Outro" },
      { id: 3, name: "Parcial" },
      { id: 4, name: "Excedente" },
      { id: 5, name: "Produção Líquida" },
    ];
  };

  units = () => {
    let defer = this.$q.defer();
    this.Measure.find()
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    data.lastModified = new Date();
    this.Smartmeter.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http
      .get("api/assets/containers/smartmeters/files/" + file + "/download", {
        responseType: "blob",
      })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  table = (from, to, id) => {
    let defer = this.$q.defer();
    this.Smartmeter.table({
      id: id,
      from: from,
      to: to,
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  hasRegistos = (measureId) => {
    //list of measureIds that dont have aggregates, used in smartmeter list for linking to 'registos' directly
    const measureIds = ["60acdc5b0b903917588ec45b", "60acdc530b903917588ec45a"];
    if (measureIds.includes(measureId)) return false;
    return true;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    filter = filter || {};
    filter.where = filter.where || {};
    filter.where.siteId = filter.where.siteId || { inq: user.siteIds };

    this.Smartmeter.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Smartmeter.find({
          filter: {
            where: filter.where,
            include: [
              "measure",
              "aggregator",
              "site",
              "tariff",
              "tariffwater",
              "device",
            ],
            order: "active DESC", // Show actives first
          },
        })
          .$promise.then((r) => {
            defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  instant = (id, feature) => {
    return this.Smartmeter.instant({id, feature}).$promise;
  }

  listByLocation = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let where = {};
    if (!this.Authorization.belongsTo("administrator")) {
      where = {
        entityId: {
          inq: user.entityIds,
        },
      };
    }
    this.Location.find({
      filter: {
        where: where,
        include: ["smartmeters"],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Smartmeter.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          "aggregator",
          "measure",
          "tariff",
          "site",
          "tariffwater",
          "device",
        ],
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

SmartmeterService.$inject = [
  "$q",
  "$rootScope",
  "AuthenticationService",
  "AuthorizationService",
  "Location",
  "Smartmeter",
  "Consumption",
  "Site",
  "Measure",
  "Asset",
  "$http",
];
