class AdaptiveDashboardWidget {
  constructor(widget, AdaptiveDashboardService, $timeout) {
    this.Service = AdaptiveDashboardService;
    this.$timeout = $timeout;
    this.widget = widget;
    this.widget.loaded = false;
    this.date = moment();
    this.widget.overrideUnit = this.getOverride("unit", widget.unit.unit);
  }

  getOverride = (key, defaultValue) => this.Service.getOverride(this.widget.measureId, key, defaultValue);

  getColSize = (type) => {
    switch (type) {
      case 'xs':
        return Math.min(this.widget.serialized.size_x*6, 12);
      case 'sm':
        return Math.min(this.widget.serialized.size_x*4, 12);
      case 'md':
        return Math.min(this.widget.serialized.size_x*2, 12);
      case 'lg':
        return this.widget.serialized.size_x;
      default:
        return this.widget.serialized.size_x;
    }
  }
}
module.exports = AdaptiveDashboardWidget;
