export function routes($stateProvider) {
  $stateProvider
    .state('app.installer', {
      url: '/installer',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.installer.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'InstallerListController',
      controllerAs: 'vm',
      role: ['controlPanel']
    }).state('app.installer.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'InstallerDetailsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    }).state('app.installer.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'InstallerDetailsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    });
}
//TODO replace controlPanel with specific permission?
routes.$inject = ['$stateProvider'];
