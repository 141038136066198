import { routes } from './routes';
import TariffWaterService from './service';
import TariffWaterListController from './list/controller';
import TariffWaterDetailsController from './details/controller';

export default angular.module('app.tariffWater', [])
    .config(routes)
    .service('TariffWaterService', TariffWaterService)
    .controller('TariffWaterListController', TariffWaterListController)
    .controller('TariffWaterDetailsController', TariffWaterDetailsController)
    .name;
