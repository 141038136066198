import { mdiDelete, mdiImageOutline, mdiUpload } from "@mdi/js";
import { Fab, Paper, Tooltip } from "@mui/material";
import Icon from "@mdi/react";
import React, { useEffect, useRef, useState } from "react";
const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
    });
};
export const ImagePicker = (props) => {
    const { img, resetImg, sx, setImg } = props;
    const [imgPreview, setImgPreview] = useState("");
    const inputFile = useRef(null);
    useEffect(() => {
        if (img)
            readFileAsync(img).then(setImgPreview);
        else
            setImgPreview("");
    }, [img]);
    const openFilePicker = () => inputFile.current.click();
    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files) {
            if (files.length == 1)
                setImg(e.target.files[0]);
            else
                resetImg();
        }
    };
    return (<Paper variant="outlined" sx={Object.assign({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            padding: "5px",
            aspectRatio: "1/1",
        }, sx)}>
      {imgPreview ? (<img src={imgPreview} style={{ height: "100%", maxWidth: "100%" }}></img>) : (<Icon path={mdiImageOutline} size={3} color="rgba(50,50,50,0.5)"/>)}
      <div style={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
        }}>
        {img ? (<Fab size="small" color="error" onClick={(e) => {
                e.preventDefault();
                resetImg();
            }}>
            <Tooltip title="Remover Imagem">
              <Icon path={mdiDelete} size={1}/>
            </Tooltip>
          </Fab>) : (<Fab size="small" color="primary" onClick={openFilePicker}>
            <input style={{ display: "none", marginRight: "5px" }} accept="image/*" type="file" onChange={handleFileChange} ref={inputFile}/>
            <Tooltip title="Adicionar Imagem">
              <Icon path={mdiUpload} size={1}/>
            </Tooltip>
          </Fab>)}
      </div>
    </Paper>);
};
