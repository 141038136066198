import {
  routes
} from './routes';
import SmartmeterService from './service';
import SmartmeterListController from './list/controller';
import SmartmeterDetailsController from './details/controller';
import SmartmeterRegisterController from './registers/controller';
import SmartmeterLeiturasController from './leituras/controller';
import MeasureDataGrid from './list/table.tsx';
import { react2angular } from 'react2angular'

export default angular.module('app.smartmeter', []) //TODO rename registers -> aggregate & leituras -> registers
  .component('smartmetersList', react2angular(MeasureDataGrid, ["viewTableData", "cloneDialog", "showDetails", "data", "viewLeituras"], ["SmartmeterService", "UIService", "DashboardService"]))
  .config(routes)
  .service('SmartmeterService', SmartmeterService)
  .controller('SmartmeterListController', SmartmeterListController)
  .controller('SmartmeterDetailsController', SmartmeterDetailsController)
  .controller('SmartmeterRegisterController', SmartmeterRegisterController)
  .controller('SmartmeterLeiturasController', SmartmeterLeiturasController)
  .name;
