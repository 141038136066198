export default class ConstantDetailsController {
  constructor($state, UIService, ConstantService, AuthorizationService, FileUploader) {
    this.$state = $state;
    this.UI = UIService;
    this.Constant = ConstantService;
    this.Authorization = AuthorizationService;

    this.id = $state.params.id;

    if (this.id) {
      this.label = "Editar";
      this.loadData();
    } else {
      this.label = "Adicionar";
      this.loadAllowed();
    }
  }

  loadData = () => {
    this.Constant.get(this.id).then(r => {
      this.data = r;
      this.loadAllowed();
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar constante");
      this.$state.go('app.constant.list');
    });
  };

  loadAllowed = () => {
    this.Constant.getAllowed().then(r => {
      const data = JSON.parse(JSON.stringify(r));
      this.allowed = data;
      this.allowedModels = Object.keys(data);
      if (this.data && this.data.model)
        this.allowedProperties = this.allowed[this.data.model];
      else
        this.allowedProperties = [];
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar modelos permitidos");
      this.$state.go('app.constant.list');
    });
  }

  onModelChange = () => {
    this.data.property = undefined;
    this.allowedProperties = this.allowed[this.data.model];
  }

  save = () => {
    if (this.validate()) {
      this.Constant.save(this.data).then(r => {
        if (this.id) {
          this.UI.addToast("Constante alterada com sucesso");
          this.$state.go('app.constant.list');
        } else {
          this.$state.go('app.constant.list');
          this.UI.addToast("Constante adicionada com sucesso");
        }
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar constante");
      });
    }
  };

  validate = () => {
    if (!this.data) {
      this.UI.addToast("Preencha os dados para salvar");
      return false;
    }
    if (!this.data.model) {
      this.UI.addToast("Escolha um modelo");
      return false;
    }
    if (!this.data.property){
      this.UI.addToast("Escolha uma propriedade");
      return false;
    }
    if (!this.data.value) {
      this.UI.addToast("Insira um valor");
      return false;
    }
    if (!this.allowedProperties.includes(this.data.property)) {
      this.UI.addToast("Propriedade Invalida");
      return false;
    }
    return true;
  };
}

ConstantDetailsController.$inject = ['$state', 'UIService', 'ConstantService', 'AuthorizationService', 'FileUploader'];
