export default class SiteMapController {
  constructor($state, SiteService, UIService) {
    this.Site = SiteService;
    this.UI = UIService;

    this.mapOptions = {
      zoom: 6.5,
      center: [-7.80705, 39.74362],
    };

    this.markers = [];

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Site.list().then((r) => {
      this.data = r.data;
      r.data.forEach((site) => {
        if (site.coordinates) {
          this.markers.push({
            title: site.name,
            coordinates: [site.coordinates.lng, site.coordinates.lat],
          });
        }
      });
      this.loaded = true;
    });
  };

  navigateTo = (row) => {
    if (row.coordinates) {
      this.mapOptions.animateCamera([row.coordinates.lng, row.coordinates.lat]);
    }
  };
}

SiteMapController.$inject = ["$state", "SiteService", "UIService"];
