const moment = require("moment-timezone");
export default class AnalyticsDetails2Controller {
  constructor(DashboardService) {
    this.DashboardService = DashboardService;

    this.getData();

  }

  getData = () => {
    this.last24hrsLoaded = false;
    this.DashboardService.last24hrs().then((r) => {
      // this.createLast24HrsChart(r);
      this.createDayChart(r);
      this.last24hrsLoaded = true;
    });

    this.consumptionLoaded = false;
    // Energy Consumption - using measureId
    this.DashboardService.dashboardConsumption('60acdc1c0b903917588ec458').then((r) => {
      this.createConsumptionChart(r);
      this.consumptionLoaded = true;
    });

    this.financeLoaded = false;
    this.DashboardService.dashboardFinance().then((r) => {
      this.createFinanceChart(r);
      this.financeLoaded = true;
    });

    this.consumptionMonthLoaded = false;
    this.DashboardService.dashboardConsumption('60acdc1c0b903917588ec458', true).then((r) => {
      this.createConsumptionMonthChart(r);
      this.consumptionMonthLoaded = true;
    });

    this.financeMonthLoaded = false;
    this.DashboardService.dashboardFinance(true).then((r) => {
      this.createFinanceMonthChart(r);
      this.financeMonthLoaded = true;
    });

    this.consumptionYearLoaded = false;
    this.DashboardService.dashboardConsumptionYear('60acdc1c0b903917588ec458').then((r) => {
      this.createConsumptionYearChart(r);
      this.consumptionYearLoaded = true;
    });
  };

  createConsumptionChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[], []];
    let series = ["Consumo", "Produção"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumption = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kWh",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 3,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionMonthChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[], []];
    let series = ["Consumo", "Produção"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumptionMonth = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kWh",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 3,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionYearChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[], []];
    let series = ["Consumo", "Produção"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumptionYear = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kWh",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 3,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceChart = (origin) => {
    let labels = [];
    let data = [[], []];
    let datasets = [];
    let series = ["Consumo", "Produção"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.finance = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 3,
                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceMonthChart = (origin) => {
    let labels = [];
    let data = [[], []];
    let datasets = [];
    let series = ["Consumo", "Produção"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.financeMonth = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 3,
                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  // createLast24HrsChart = (origin) => {
  //   let labels = [];
  //   let data = [[], [], []];
  //   let datasets = [];
  //   let series = ["Produção", "Consumo", "Excedente"];
  //   let colors = ["#FDA50F", "#AA3033", "#512da8"];
  //   // let tints = ["#D59899BF", "#FED287BF", "#977ED3BF"];
  //   let transparent = "#00000000";
  //
  //   origin.forEach((row) => {
  //     labels.push(moment(row.date).tz('Etc/GMT+0'));
  //     data[0].push(row.production);
  //     data[1].push(row.consumption);
  //     data[2].push(row.return);
  //   });
  //
  //   data.forEach((r, i) => {
  //     datasets.push({
  //       label: series[i],
  //       data: r,
  //       // backgroundColor: tints[i],
  //       backgroundColor: colors[i],
  //       borderColor: colors[i],
  //       pointBorderColor: transparent,
  //       pointBackgroundColor: transparent,
  //       // pointHoverBackgroundColor: tints[i],
  //       pointHoverBorderColor: transparent,
  //       fill: true
  //     });
  //   });
  //
  //   this.last24hrs = {
  //     labels: labels,
  //     datasets: datasets,
  //     data: data,
  //     series: series,
  //     options: {
  //       tooltips: {
  //         enabled: true,
  //         callbacks: {
  //           label: function (tooltipItem, data) {
  //             let dataset = data.datasets[tooltipItem.datasetIndex];
  //             let value;
  //             if (dataset.label === "Excedente") // Show tooltip for Excedente with positive value even though the actual value is negative
  //               value = -dataset.data[tooltipItem.index];
  //             else
  //               value = dataset.data[tooltipItem.index];
  //             return dataset.label + ": " + value;
  //           }
  //         }
  //       },
  //       legend: {
  //         display: true,
  //         position: 'bottom'
  //       },
  //       animation: false,
  //       maintainAspectRatio: false,
  //       scales: {
  //         xAxes: [
  //           {
  //             stacked: true,
  //             gridLines: {
  //               display: true
  //             },
  //             scaleLabel: {
  //               display: false
  //             },
  //             display: true,
  //             type: "time",
  //             time: {
  //               tooltipFormat: "HH:mm",
  //               unit: "minute",
  //               unitStepSize: 60,
  //               displayFormats: {
  //                 minute: "HH:mm"
  //               }
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             stacked: true,
  //             id: "kWh",
  //             gridLines: {
  //               display: false
  //             },
  //             scaleLabel: {
  //               display: false
  //             },
  //             display: true,
  //             ticks: {
  //               beginAtZero: true,
  //               maxTicksLimit: 6,
  //               callback: function (value, index, values) {
  //                 return (Math.round((value + Number.EPSILON) * 100) / 100);
  //               }
  //             }
  //           }
  //         ]
  //       }
  //     }
  //   };
  // };

  // Output array with times from 00:00 to 23:00
  createFullDayLabels = () => {
    let l = [];
    let time = moment.utc('00:00', 'HH:mm');
    for (let i = 0; i < 24; i++) {
      l.push(time.format('HH:mm'));
      time.add(1, 'h');
    }
    return l;
  };

  // Output an object with data for chart and other relevant info for background colors
  createDayData = (origin, colors, tints) => {
    let d = [[], [], []];
    let colorIndex = 0;
    for (let i = 0; i < origin.length; i++) {
      let m = moment.utc(origin[i].date);
      d[0][m.hour()] = origin[i].production;
      d[1][m.hour()] = origin[i].consumption;
      d[2][m.hour()] = origin[i].return;
    }

    // If we don't have data for this hour, use old data
    if (moment().minute() < 15) {
      let m = moment.utc(origin[origin.length - 1].date);
      d[0][m.hour()] = origin[0].production;
      d[1][m.hour()] = origin[0].consumption;
      d[2][m.hour()] = origin[0].return;
      colorIndex = m.hour();
    } else {
      colorIndex = moment.utc(origin[origin.length - 1].date).hour() + 1;
    }

    let backgroundColors = [[], [], []];
    for (let i = 0; i < 24; i++) {
      if (i < colorIndex) {
        backgroundColors[0][i] = colors[0];
        backgroundColors[1][i] = colors[1];
        backgroundColors[2][i] = colors[2];
      } else {
        backgroundColors[0][i] = tints[0];
        backgroundColors[1][i] = tints[1];
        backgroundColors[2][i] = tints[2];
      }
    }

    return {
      data: d,
      backgroundColors: backgroundColors
    }
  };

  createDayChart = (origin) => {
    let labels = this.createFullDayLabels();
    let datasets = [];
    let series = ["Produção", "Consumo", "Excedente"];
    let colors = ["#FDA50F", "#AA3033", "#512DA8"];
    let tints = ["#FFE6BDBF", "#EAC5C6BF", "#C4B5E9BF"];
    let transparent = "#00000000";

    let dayData = this.createDayData(origin, colors, tints);
    let data = dayData.data;

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        // backgroundColor: tints[i],
        backgroundColor: dayData.backgroundColors[i],
        borderColor: dayData.backgroundColors[i],
        pointBorderColor: transparent,
        pointBackgroundColor: transparent,
        // pointHoverBackgroundColor: tints[i],
        pointHoverBorderColor: transparent,
        fill: true
      });
    });

    this.last24hrs = {
      labels: labels,
      datasets: datasets,
      data: data,
      series: series,
      options: {
        cornerRadius: 10,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let value;
              if (dataset.label === "Excedente") // Show tooltip for Excedente with positive value even though the actual value is negative
                value = -dataset.data[tooltipItem.index];
              else
                value = dataset.data[tooltipItem.index];
              return dataset.label + ": " + value;
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true
            }
          ],
          yAxes: [
            {
              stacked: true,
              id: "kWh",
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 9,
                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };
}

AnalyticsDetails2Controller.$inject = ["DashboardService"];
