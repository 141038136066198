import { routes } from './routes';
import LeiturasService from './service';
import LeiturasListController from './list/controller';
import LeiturasTableController from './table/controller';
import LeiturasAggregateController from './aggregate/controller';
import LeiturasSmartmeterController from './smartmeter/controller';
import LeiturasRegisterController from './registers/controller';

import MeasureDataGrid from './smartmeter/table.tsx';
import { react2angular } from 'react2angular'

export default angular.module('app.leituras', [])
  .config(routes)
  .service('LeiturasService', LeiturasService)
  .component('leiturasSmartmeters', react2angular(MeasureDataGrid, ["viewAggregate", "cloneDialog", "showDetails", "data", "viewRegisters"], ["SmartmeterService", "UIService", "DashboardService"]))
  .controller('LeiturasListController', LeiturasListController)
  .controller('LeiturasTableController', LeiturasTableController)
  .controller('LeiturasSmartmeterController', LeiturasSmartmeterController)
  .controller('LeiturasAggregateController', LeiturasAggregateController)
  .controller('LeiturasRegisterController', LeiturasRegisterController)
  .name;
