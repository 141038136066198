export default class EvChargerService {
  constructor($q, EvCharger, EvTransaction, Register, EvGroup, AuthenticationService) {
    this.$q = $q;
    this.EvCharger = EvCharger;
    this.EvTransaction = EvTransaction;
    this.Register = Register;
    this.EvGroup = EvGroup;
    this.Auth = AuthenticationService;
  }

  adopt = (id, partNumber) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let siteId = user.selected.siteId;
    this.EvCharger.prototype$updateAttributes(
      { id: id },
      {
        siteId: siteId,
        partNumber: partNumber
      }
    ).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  pending = () => {
    let defer = this.$q.defer();
    this.EvCharger.pending().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getConfig = (id) => {
    return this.EvCharger.getConfig({id: id}).$promise;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    filter = filter || {};
    filter.where = filter.where || {};
    filter.where.siteId = { inq: user.siteIds };

    this.EvCharger.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.EvCharger.find({
          filter: {
            where: filter.where,
            include: ["site", {
              relation: "qol",
              scope: {
                where: {
                  timestamp: {
                    between: [moment().subtract(30, 'd').startOf('d'), moment()]
                  }
                }
              }
            }],
            order: 'active DESC' // Show actives first
          },
        })
          .$promise.then((r) => {
            defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.EvCharger.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          "site"
        ],
      },
    }).$promise.then((r) => defer.resolve(r)).catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    data.lastModified = new Date();
    this.EvCharger.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/ev/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  }

  getGroups = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.EvGroup.find(
      {
        filter: {
          where: {
            siteId: { inq: user.siteIds },
            active: true
          },
          include: {
            relation: 'chargers',
            scope: {
              where: {
                active: true
              }
            }
          }
        }
      }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  saveGroup = (data) => {
    let defer = this.$q.defer();
    this.EvGroup.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  saveProfile = (data) => {
    let defer = this.$q.defer();
    this.EvProfile.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  readStatus = status => {
    switch (status) {
      case "EmergencyStop":
        return "Paragem via botão de emergência";
      case "PowerLoss":
        return "Perda de energia";
      case "DeAuthorized":
        return "Cartão expirado";
      case "Remote":
        return "Remotamente";
      case "Local":
        return "Utilizador";
      case "EVDisconnected":
        return "Carga concluída";
      default:
        return "-";
    }
  }

  readConnector = (connector, charger) => {
    let text = `Potência: ${connector.power / 1000}kW<br/>`;
    // If is charging, add information
    if (connector.hasOwnProperty('charging')) {
      text = `${(connector.charging.w / 1000).toFixed(1)}/${connector.power / 1000}kW<br/><hr>`;
    }
    if (charger.online == true) {
      switch (connector.status) {
        case "Available":
          text += "Disponível para carga";
          break;
        case "Preparing":
          text += "Ocupado (A iniciar carga)";
          break;
        case "Charging":
          text += "A carregar";
          break;
        case "SuspendedEVSE":
          text += "A carregar (Carga suspensa pelo carregador)";
          break;
        case "SuspendedEV":
          text += "A carregar (Carga suspensa pelo veículo)";
          break;
        case "Finishing":
          text += "Ocupado (Remova carro para concluir carga)";
          break;
        case "Reserved":
          text += "Reservado";
          break;
        case "Unavailable":
          text += "Indisponível para carga";
          break;
        case "Faulted":
          text += "Com falha no sistema";
          break;
      }
    } else {
      text += "Offline"
    }
    return text;
  }

  dashboardEV = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardEV({
      siteId: [user.siteIds],
    }).$promise.then((c) => defer.resolve(c)).catch((e) => defer.reject(e));
    return defer.promise;
  }

  unlock = (id, connectorId) => {
    let defer = this.$q.defer();
    this.EvCharger.unlock({
      id: id,
      connectorId: connectorId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  reset = (id) => {
    let defer = this.$q.defer();
    this.EvCharger.reset({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  update = (id, url, date) => {
    let defer = this.$q.defer();
    this.EvCharger.firmwareUpdate({
      id: id,
      url: url,
      date: date
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getLastSessions = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.EvTransaction.find({
      filter: {
        where: {
          siteId: {
            inq: user.siteIds
          }
        },
        order: 'start DESC',
        limit: 25
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  listTransactions = (filter) => {
    let defer = this.$q.defer();
    this.EvTransaction.count({ where: filter.where }).$promise.then((r) => {
      this.EvTransaction.find({
        filter: filter,
      })
        .$promise.then((list) => {
          defer.resolve({
            total: r.count,
            data: list,
          });
        })
        .catch((err) => {
          defer.reject(err);
        })
        .catch((err) => {
          defer.reject(err);
        });
    });
    return defer.promise;
  };

  stopTransaction = (id, connectorId, transactionId) => {
    let defer = this.$q.defer();
    this.EvCharger.stopTransaction({
      id: id,
      connectorId: connectorId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getLoadFromGroup = (id) => {
    let defer = this.$q.defer();
    this.EvGroup.loadChart({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }
}

EvChargerService.$inject = ['$q', 'EvCharger', 'EvTransaction', 'Register', 'EvGroup', 'AuthenticationService'];
