export default class LocationDetailsController {
  constructor($state, UIService, LocationService, AuthorizationService, SiteService) {
    this.$state = $state;
    this.UI = UIService;
    this.Location = LocationService;
    this.Site = SiteService;
    this.Authorization = AuthorizationService;

    this.id = $state.params.id;
    this.siteId = $state.params.siteId;
    this.isCompleted = false;
    this.data = {};
    this.site = {};

    if (this.id) {
      this.label = "Editar";
    } else {
      this.label = "Adicionar";
      this.data = {
        entityId: $state.params.entityId
      };
    }
    this.loadData();
  }

  loadData = () => {
    this.Site.list().then(res => {
      this.sites = res.data;
      if (this.siteId) {
        this.Site.get(this.siteId).then(res => {
          this.site = res;
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao carregar instalação");
        });
      }
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar instalações");
    });

    if (this.id) {
      this.Location.get(this.id).then(res => {
        this.data = res;
        this.isCompleted = true;
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao carregar espaço");
      });
    }

    this.loaded = true;
  }

  locations = () => {
    this.$state.go('app.location.details', {
      siteId: this.id
    });
  }

  save = () => {
    if (this.validate()) {
      this.Location.save(this.data).then(r => {
        if (this.id)
          this.UI.addToast("Espaço alterado com sucesso");
        else
          this.UI.addToast("Espaço adicionado com sucesso");

        this.$state.go('app.location.details', {
          id: r.id,
          siteId: this.siteId
        });
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar espaço");
      });
    }
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do espaço");
      return false;
    }
    if (!this.site.name) {
      this.UI.addToast("Indique a instalação a que pertence o espaço");
      return false;
    }
    this.data.siteId = this.site.id;
    return true;
  }

  editable = () => {
    return this.Authorization.canPerform(['editSite']);
  }
}

LocationDetailsController.$inject = ['$state', 'UIService', 'LocationService', 'AuthorizationService', 'SiteService'];
