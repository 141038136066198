export default class LocationListController {
  constructor(LocationService, $state) {
    this.Location = LocationService;
    this.loadData();
  }

  loadData = () => {
    this.Location.list().then(r => {
      this.data = r.data;
      this.total = r.total;
      this.start = 1 || 0;
      this.end = r.data.length || 0;
      this.loaded = true;
    });
  }
}

LocationListController.$inject = ['LocationService', '$state'];
