export default class DeviceListController {
  constructor($state, DeviceService, UIService) {
    this.$state = $state;
    this.Device = DeviceService;
    this.UI = UIService;

    this.loaded = false;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "desc",
      order: "id"
    };
    this.data = [];

    this.loadData();
  }

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {
    };

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `vendor`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}d`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "vendor";
    this.loadData();
  };

  loadData = () => {
    this.$state.go("app.device.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Device.list(this.createFilter()).then((r) => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      if (this.total > r.data.length) {
        this.hasNavigation = true;
      } else {
        this.hasNavigation = false;
      }
      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast("Não foi possivel carregar equipamentos");
    });
  };
}

DeviceListController.$inject = ['$state', 'DeviceService', 'UIService'];
