export function routes($stateProvider) {
    $stateProvider
      .state('app.tariff', {
        url: '/tariff',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('app.tariff.list', {
        url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
        template: require('./list/view.html'),
        controller: 'TariffListController',
        controllerAs: 'vm',
        role: ['tariffList']
      })
      .state('app.tariff.wizard', {
        url: '/wizard',
        template: require('./wizard/view.html'),
        controller: 'TariffWizardController',
        controllerAs: 'vm',
        role: ['tariffAdd']
      })
      .state('app.tariff.details', {
        url: '/:id',
        template: require('./details/view.html'),
        controller: 'TariffDetailsController',
        controllerAs: 'vm',
        role: ['tariffEdit']
      })
  }

  routes.$inject = ['$stateProvider'];
