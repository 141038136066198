export default class MeasureService {
  constructor($q, AuthenticationService, Measure, $http) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Measure = Measure;
    this.$http = $http;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    let filterHardware = filter;
    if (!filterHardware) {
      filterHardware = {
        where: {}
      };
    }

    this.Measure.count({
      where: filterHardware.where
    }).$promise.then(c => {
      this.Measure.find({
        filter: filterHardware
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Measure.findById({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/measures/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  save = data => {
    let defer = this.$q.defer();
    this.Measure.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };
}

MeasureService.$inject = ['$q', 'AuthenticationService', 'Measure', '$http'];
