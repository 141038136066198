export default class RegisterListController {
  constructor($state, $scope, $timeout, $interval, UIService, RegisterService) {
    // Models
    this.$state = $state;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.UI = UIService;
    this.Register = RegisterService;
    // Variables
    this.loaded = false;
    this.data = [];

    this.loadData();
  }

  loadData = () => {
    this.Register.list().then((res) => {
      this.data = res.data;
      this.total = res.total;
      this.start = 1 || 0;
      this.end = res.data.length || 0;
      this.loaded = true;
    });
  }
}

RegisterListController.$inject = ["$state", "$scope", "$timeout", "$interval", "UIService", "RegisterService",];
