export default class ConstantService {
  constructor($q, AuthenticationService, Constant, $http) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Constant = Constant;
    this.$http = $http;
  }

  list = (filter) => {
    let defer = this.$q.defer();

    this.Constant.count({
      filter: filter
    }).$promise.then(c => {
      this.Constant.find({
        filter: filter
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Constant.findById({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  };

  getAllowed = () => {
    let defer = this.$q.defer();
    this.Constant.allowed().$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    this.Constant.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getDefaultValues = (model) => {
    let defer = this.$q.defer();
    this.Constant.defaultValues({model}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getAffectedRecords = (constantId) => {
    let defer = this.$q.defer();
    this.Constant.affectedRecords({constantId}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  removeConstant = (constant) => {
    let defer = this.$q.defer();

    this.Constant.destroyById({
      id: constant.id,
    })
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };
}

ConstantService.$inject = ['$q', 'AuthenticationService', 'Constant', '$http'];
