import { Divider, IconButton, ListItem, ListItemButton, ListItemText, Stack, TextField, Tooltip, Typography, } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiData } from "../utils";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { getCount, getEmpresasLazy } from "../api";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
const EmpresaForm = ({ state: { getErr, hasErr, isValid, setField, values: empresa }, selectEmpresa, forceShowErrors, isSelected, }) => {
    const apiData = useContext(ApiData);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemsMax, setItemsMax] = useState(0);
    const isItemLoaded = (index) => !!items[index];
    useEffect(() => {
        getCount("entities").then((count) => setItemsMax(count));
    }, []);
    const loadMoreItems = (startIndex, stopIndex) => {
        if (loading)
            return;
        setLoading(true);
        getEmpresasLazy(startIndex, stopIndex).then((data) => {
            setItems((prevItems) => prevItems.concat(data));
            setLoading(false);
        });
    };
    const Row = ({ index, style }) => {
        // Render each item in the list
        const item = items[index];
        if (!item)
            return null;
        return (<ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => selectEmpresa(item)}>
          <ListItemText primary={item.name}/>
        </ListItemButton>
      </ListItem>);
    };
    const hasError = (field) => hasErr(field, forceShowErrors) && !isSelected;
    const getError = (field) => !isSelected ? getErr(field, forceShowErrors) : undefined;
    return (<div>
      <Typography sx={{ mt: 1, mb: 1 }}>Passo 1 - Criar Empresa</Typography>
      <div style={{ display: "flex" }}>
        <Stack spacing={1} sx={{ width: "100%", marginRight: "10px" }}>
          <TextField label="NIF" required value={empresa.taxNumber} disabled={isSelected} error={hasError("taxNumber")} helperText={getError("taxNumber")} onChange={(e) => setField("taxNumber", e.target.value)} variant="standard"/>
          <TextField label="Nome" required value={empresa.name} disabled={isSelected} error={hasError("name")} helperText={getError("name")} onChange={(e) => setField("name", e.target.value)} variant="standard"/>
          <TextField label="Morada" required value={empresa.address} disabled={isSelected} error={hasError("address")} helperText={getError("address")} onChange={(e) => setField("address", e.target.value)} variant="standard"/>
          <div style={{ display: "flex" }}>
            <TextField label="Código Postal" value={empresa.zipcode || ""} disabled={isSelected} error={hasError("zipcode")} helperText={getError("zipcode")} onChange={(e) => setField("zipcode", e.target.value)} variant="standard" sx={{ width: "30%", marginRight: "10px" }}/>
            <TextField label="Localidade" required value={empresa.location} disabled={isSelected} error={hasError("location")} helperText={getError("location")} onChange={(e) => setField("location", e.target.value)} variant="standard" sx={{ width: "70%" }}/>
          </div>
          <TextField label="URL" value={empresa.url || ""} disabled={isSelected} error={hasError("url")} helperText={getError("url")} onChange={(e) => setField("url", e.target.value)} variant="standard"/>
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ marginRight: 2, marginLeft: 2 }}>
          Ou
        </Divider>
        <Stack spacing={1} sx={{ width: 300 }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Typography>Selecionar empresa:</Typography>
            </div>
            <div>
              <IconButton onClick={() => selectEmpresa(null)}>
                <Tooltip title="Limpar">
                  <Icon path={mdiClose} size={1}/>
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemsMax} loadMoreItems={loadMoreItems} minimumBatchSize={10} threshold={5}>
            {({ onItemsRendered, ref }) => (<FixedSizeList height={300} width={300} itemCount={itemsMax} itemSize={40} onItemsRendered={onItemsRendered} ref={ref}>
                {Row}
              </FixedSizeList>)}
          </InfiniteLoader>
        </Stack>
      </div>
    </div>);
};
export default EmpresaForm;
