export default class HardwareService {
  constructor($q, AuthenticationService, Hardware) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Hardware = Hardware;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let filterHardware = filter;
    if (!filterHardware) {
      filterHardware = {
        where: {}
      };
    }
    this.Hardware.count({ where: filterHardware.where }).$promise.then(c => {
      this.Hardware.find({ filter: filterHardware }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  get = id => {
    let defer = this.$q.defer();
    this.Hardware.findById({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    data.entityId = user.selected.entityId;
    this.Hardware.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }
}

HardwareService.$inject = ['$q', 'AuthenticationService', 'Hardware'];
