import { FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import React from "react";
export const CustomSelect = (props) => {
    const { required, field, obj, handleChange, label, sx, items, disabled = false, } = props;
    const getValue = () => {
        if (items.length == 0)
            return "";
        if (obj[field] == undefined)
            return "";
        return obj[field];
    };
    return (<FormControl variant="standard" required={required} disabled={disabled} sx={Object.assign({ minWidth: 120 }, sx)}>
      <InputLabel>{label}</InputLabel>
      <Select value={getValue()} onChange={(e) => handleChange(field, e.target.value)} label={label}>
        {items.map((item) => (<MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>))}
      </Select>
    </FormControl>);
};
