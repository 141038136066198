const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const generateRandomPassword = (length) => {
    const characters = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "abcdefghijklmnopqrstuvwxyz",
        "0123456789",
        "_?!",
    ];
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = getRandomInt(0, characters.length - 1);
        const randomCharIndex = getRandomInt(0, characters[randomIndex].length - 1);
        password += characters[randomIndex][randomCharIndex];
    }
    return password;
};
//TODO maybe move to apidata context hook instead of calling everytime
export const getAccessToken = () => getCookie("access_token");
const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};
export const getTariffName = (meterName) => {
    switch (meterName) {
        case "Consumo Operador":
            return "Tarifário Rede";
        case "Excedente Solar":
            return "Tarifário Excedente";
        case "Produção Solar":
            return "Tarifário Produção";
        default:
            return `Tarifário ${meterName}`;
    }
};
