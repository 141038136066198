import {
    routes
  } from './routes';
  import AnalyticsService from './service';
  import AnalyticsDetailsController from './details/controller';
  import AnalyticsDetails2Controller from './details2/controller';

  export default angular.module('app.analytics', [])
    .config(routes)
    .service('AnalyticsService', AnalyticsService)
    .controller('AnalyticsDetailsController', AnalyticsDetailsController)
    .controller('AnalyticsDetails2Controller', AnalyticsDetails2Controller)
    .name;
