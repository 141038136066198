import {
  routes
} from './routes';
import HardwareService from './service';
import HardwareListController from './list/controller';
import HardwareDetailsController from './details/controller';

export default angular.module('app.hardware', [])
  .config(routes)
  .service('HardwareService', HardwareService)
  .controller('HardwareListController', HardwareListController)
  .controller('HardwareDetailsController', HardwareDetailsController)
  .name;
