export default class RegisterService {
  constructor($q, Register) {
    this.$q = $q;
    this.Register = Register;
  }

  list = () => {
    let defer = this.$q.defer();
    this.Register.count().$promise.then((c) => {
      this.Register.find({
        filter: {
          include: [
            {
              relation: "smartmeter"
            }
          ]
        }
      }).$promise.then((r) => {
        let a = {
          total: c.count,
          data: r
        };
        defer.resolve(a);
      }).catch((e) => {
        defer.resolve(e);
      });
    });
    return defer.promise;
  };
}

RegisterService.$inject = ["$q", "Register"];
