export default class AggregatorAddController {
  constructor(
    $state,
    $scope,
    UIService,
    AggregatorService,
    HardwareService,
    SiteService, FileUploader
  ) {
    this.$state = $state;
    this.$scope = $scope;
    this.UI = UIService;
    this.Aggregator = AggregatorService;
    this.Hardware = HardwareService;
    this.Site = SiteService;
    // variables
    this.loaded = false;
    this.id = $state.params.id;
    this.filter = {
      where: {}
    };

    this.data = {};

    if (this.id) {
      this.label = "Editar";
      this.isNewInstance = false;
    } else {
      this.label = "Adicionar";
      this.isNewInstance = true;
    }
    this.loadData();

    this.uploader = new FileUploader({
      url: "/api/assets/containers/gateways/files",
      queueLimit: 1,
      autoUpload: true,
    });
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == "queueLimit") {
        this.uploader.clearQueue();
        this.uploader.addToQueue(item);
      }
    };
    this.uploader.onSuccessItem = (item, response) => {
      this.data.photo = {
        container: response[0].metadata.container,
        original: response[0].originalName,
        filename: response[0].filename,
        size: response[0].length,
      }
    }
  }

  loadData = () => {
    this.Site.list(this.filter)
      .then((res) => {
        this.siteData = res.data;
        if (this.siteId) {
          this.Site.get(this.siteId)
            .then((res) => {
              this.site = res;
            })
            .catch(() => {
              this.UI.addToast("Ocorreu um erro ao carregar hardware");
            });
        }
      })
      .catch(() => {
        this.UI.addToast("Ocorreu um erro ao carregar hardware");
      });

    this.Hardware.list()
      .then((res) => {
        this.hardwareData = res.data;
        if (this.hardwareId) {
          this.Hardware.get(this.hardwareId)
            .then((res) => {
              this.hardware = res;
            })
            .catch(() => {
              this.UI.addToast("Ocorreu um erro ao carregar hardware");
            });
        }
      })
      .catch(() => {
        this.UI.addToast("Ocorreu um erro ao carregar hardware");
      });

    if (this.id) {
      this.Aggregator.get(this.id)
        .then((res) => {
          this.data = res;
          if (this.data.hardware) {
            this.hardware = angular.copy(this.data.hardware);
          }
          if (this.data.site) {
            this.site = angular.copy(this.data.site);
          }
          if (this.data.photo) {
            this.hasPhoto = true;
            this.getPhoto(this.data);
          }
          this.loaded = true;
        })
        .catch(() => {
          this.UI.addToast("Ocorreu um erro ao carregar gateways");
        });
    } else {
      this.loaded = true;
    }
  };

  getPhoto = (aggregator) => {
    let filename = aggregator.photo.filename;
    this.Aggregator.getPhoto(filename)
      .then((r) => {
        this.photo = URL.createObjectURL(r.data);
        this.loadedPhoto = true;
      }).catch((err) => {
        this.UI.addToast("Erro a carregar fotografia do agregador");
      });
  }

  showCards = () => {
    return this.id != undefined;
  };

  addSmartmeter = () => {
    this.$state.go("app.smartmeter.details", {
      id: null,
    });
  };

  save = () => {
    if (this.validate()) {
      this.Aggregator.save(this.data)
        .then((r) => {
          this.UI.addToast("Alterações submetidas com sucesso");
          this.$state.go("app.aggregator.list");
        })
        .catch(() => {
          this.UI.addToast("Ocorreu um erro ao submeter alterações");
        });
    }
  };

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome da gateways");
      return false;
    }
    if (!this.data.device) {
      this.UI.addToast("Indique o nome do dispositivo");
      return false;
    }
    if (!this.hardware.name) {
      this.UI.addToast("Indique o hardware");
      return false;
    }
    if (!this.site.name) {
      this.UI.addToast("Indique a instalação do gateways");
      return false;
    }
    if (!this.data.nodeId) {
      this.UI.addToast("Indique o id do ZT Node");
      return false;
    }
    if (!this.data.ztNodeName) {
      this.UI.addToast("Indique o nome do ZT Node");
      return false;
    }
    if (!this.data.ztIpAddress) {
      this.UI.addToast("Indique o ip do ZT Node");
      return false;
    }
    if (!this.data.serialnumber) {
      this.UI.addToast("Indique o número de série");
      return false;
    }
    this.data.siteId = this.site.id;
    this.data.hardwareId = this.hardware.id;
    this.data.timestamp = new Date().getDate();
    return true;
  };
}

AggregatorAddController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "AggregatorService",
  "HardwareService",
  "SiteService",
  "FileUploader"
];
