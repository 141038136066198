export function routes($stateProvider) {
  $stateProvider
    .state('app.dashboardadaptive', {
      url: '/adaptive',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.dashboardadaptive.dashboard', {
      url: '/{id}',
      template: require('./dashboard/view.html'),
      controller: 'AdaptiveDashboardController',
      controllerAs: 'vm',
    }).state('app.dashboardadaptive.wizard', {
      url: '/wizard?{id:string}',
      template: require('./wizard/view.html'),
      controller: 'AdaptiveDashboardWizardController',
      controllerAs: 'vm',
    }).state('app.dashboardadaptive.list', {
      url: '/list',
      template: require('./list/view.html'),
      controller: 'AdaptiveDashboardListController',
      controllerAs: 'vm',
    })
}

routes.$inject = ['$stateProvider'];
