import { Tooltip } from "@mui/material";
import Autocomplete, { autocompleteClasses, createFilterOptions, } from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme, styled, useTheme, } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ptPT } from "@mui/material/locale";
import React from "react";
import { VariableSizeList } from "react-window";
const customTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#1976d2",
        },
        text: {
            primary: "#FFF",
            secondary: "#FFF",
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: "#FFF",
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    color: "rgba(255, 255, 255, 0.57)",
                },
                listbox: {
                    color: "#000",
                },
                input: {
                    color: "#FFF",
                },
                noOptions: {
                    color: "#000",
                },
            },
        },
    },
}, ptPT);
const LISTBOX_PADDING = 8; // px
const isOverflowing = (ref) => {
    if (ref && ref.current) {
        return ref.current.scrollWidth > ref.current.clientWidth;
    }
    return false;
};
function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = Object.assign({}, style, {
        top: style.top + LISTBOX_PADDING,
        color: "#000",
    });
    const headerRef = React.useRef(null);
    const typoRef = React.useRef(null);
    if (dataSet.hasOwnProperty("group")) {
        return (<Tooltip placement="left" title={isOverflowing(headerRef) ? dataSet.group : null} arrow>
        <ListSubheader key={dataSet.key} component="div" style={inlineStyle} ref={headerRef} sx={{
                bgcolor: grey[300],
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}>
          {dataSet.group}
        </ListSubheader>
      </Tooltip>);
    }
    return (<Tooltip placement="left" title={isOverflowing(typoRef) ? dataSet[1].name : null} arrow>
      <Typography component="li" {...dataSet[0]} style={inlineStyle} noWrap ref={typoRef}>
        {`${dataSet[1].name}`}
      </Typography>
    </Tooltip>);
}
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps}/>;
});
function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children } = props;
    const other = Object.assign({}, props);
    delete other.children;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const getChildSize = (child) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
    const gridRef = useResetCache(itemCount);
    return (<div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList itemData={itemData} height={getHeight() + 2 * LISTBOX_PADDING} width="100%" ref={gridRef} outerElementType={OuterElementType} innerElementType="ul" itemSize={(index) => getChildSize(itemData[index])} overscanCount={5} itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>);
});
const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});
const filterOptions = createFilterOptions({
    stringify: (option) => `${option.entityName} ${option.name}`,
});
export default function EntityPicker({ options, selectedSite, setSite, }) {
    if (options.length === 0)
        return <div></div>;
    const data = options.flatMap((e) => e.sites.map((s) => Object.assign({}, s, { entityName: e.name, entityId: e.id })));
    const selectedOption = data.find((s) => s.id === selectedSite);
    const textRef = React.useRef(null);
    return (<ThemeProvider theme={customTheme}>
      <Autocomplete sx={{
            width: 200,
            height: "100%",
            margin: 1,
            "& .MuiInputBase-root:before": {
                borderColor: "rgba(255, 255, 255, 0.42)",
            },
            "& .MuiInputBase-root:after": {
                borderColor: "rgba(138, 107, 0, 0.42)",
            },
        }} disableClearable disableListWrap isOptionEqualToValue={(option, value) => option.id === value.id} filterOptions={filterOptions} PopperComponent={StyledPopper} ListboxComponent={ListboxComponent} noOptionsText="Não encontrado" options={data} handleHomeEndKeys={false} defaultValue={selectedOption} groupBy={(option) => option.entityName} getOptionLabel={(option) => option.name} onChange={(event, value) => {
            if (value.id === selectedSite)
                return;
            const entity = options.find((e) => e.id === value.entityId);
            setSite(entity.sites.find((s) => s.id === value.id), entity.sites);
        }} renderInput={(params) => (<Tooltip title={isOverflowing(textRef) ? selectedOption.name : null} arrow>
            <TextField {...params} inputRef={textRef} variant="standard" label="INSTALAÇÃO"/>
          </Tooltip>)} renderOption={(props, option, state) => [props, option, state.index]} 
    // TODO: Post React 18 update - validate this conversion, look like a hidden bug
    renderGroup={(params) => params}/>
    </ThemeProvider>);
}
