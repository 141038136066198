import React, { useState } from "react";
import { Card, ThemeProvider, Tabs, Tab } from "@mui/material";
import { theme } from "./wizard/utils";
import UsersIndex from "./users/UsersIndex";
import RolesIndex from "./roles/RolesIndex";
const Main = ({ $state, AdministrationService }) => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, tabValue) => {
        setActiveTab(tabValue);
    };
    return (<ThemeProvider theme={theme}>
      <Card sx={{ width: "100%", padding: "10px", minWidth: "600px" }}>
        <Tabs aria-label="Tabs" value={activeTab} onChange={handleTabChange}>
          <Tab label="Utilizadores"/>
          <Tab label="Cargos"/>
        </Tabs>

        {activeTab === 0 && <UsersIndex $state={$state} AdministrationService={AdministrationService}/>}
        {activeTab === 1 && <RolesIndex $state={$state}/>}
      </Card>
    </ThemeProvider>);
};
export default Main;
