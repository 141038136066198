export const LEVEL = {
  BLUE: 0,
  YELLOW: 1,
  ORANGE: 2,
  RED: 3,
};

export const ALARM_MESSAGES = {
  CARD_UNAUTHORIZED: "CardUnauthorized",
  CARD_EXPIRED: "CardExpired",
  CARD_NON_EXIST: "CardNonExist",
  CARD_BLOCKED: "CardBlocked",
  DEAUTHORIZED: "Deauthorized",
  POWERLESS: "PowerLoss",
  CONNECTION_LOST: "ConnectionLost",
  CONNECTION_RESTORED: "ConnectionRestored",
  OCPP_ERROR: "OCPPError",
  CIRCUIT_ERROR: "CircuitError",
};

export default class AlarmService {
  constructor($q, AuthenticationService, Alarm, Site) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Alarm = Alarm;
    this.Site = Site;
  }

  loadFilterData = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        include: ["evses"],
        where: { entityId: user.selected.entityId },
      },
    })
      .$promise.then((r) => {
        let sites = r;
        let t = r.map((r) => r.evses);
        let evses = [];
        t.forEach((e) => evses.push(...e));
        let levels = [
          {
            id: 0,
            name: "INFORMAÇÃO"
          },
          {
            id: 1,
            name: "MÉDIO",
          },
          {
            id: 2,
            name: "GRAVE",
          },
          {
            id: 3,
            name: "MUITO GRAVE",
          },
        ];
        defer.resolve({
          sites: sites,
          evses: evses,
          levels: levels,
        });
        // Load chargers
      })
      .catch((e) => {
        defer.reject(e);
      });

    return defer.promise;
  };

  list = (filter) => {
    filter.filter.include = ["site", "evse"];
    let defer = this.$q.defer();
    this.Alarm.count(filter.where).$promise.then((r) => {
      this.Alarm.find(filter)
        .$promise.then((list) => {
          defer.resolve({
            total: r.count,
            data: list,
          });
        })
        .catch((err) => {
          defer.reject(err);
        })
        .catch((err) => {
          defer.reject(err);
        });
    });
    return defer.promise;
  };

  getReadableLevel = (alarm) => {
    switch (alarm.level) {
      case LEVEL.BLUE:
        return "INFORMAÇÃO";
      case LEVEL.YELLOW:
        return "MÉDIO";
      case LEVEL.ORANGE:
        return "GRAVE";
      case LEVEL.RED:
        return "MUITO GRAVE";
    }
  };

  getReadableError = (alarm) => {
    switch (alarm.error) {
      case ALARM_MESSAGES.CARD_UNAUTHORIZED:
        return "Cartão não autorizado";
      case ALARM_MESSAGES.CARD_EXPIRED:
        return "Cartão expirado";
      case ALARM_MESSAGES.CardNonExist:
        return "Cartão não registado";
      case ALARM_MESSAGES.CARD_BLOCKED:
        return "Cartão bloqueado";
      case ALARM_MESSAGES.DEAUTHORIZED:
        return "Acesso negado ao carregador";
      case ALARM_MESSAGES.POWERLESS:
        return "O carregador sofreu uma perda de energia";
      case ALARM_MESSAGES.CONNECTION_LOST:
        return "O carregador perdeu a ligação à plataforma";
      case ALARM_MESSAGES.CONNECTION_RESTORED:
        return "O carregador retomou a ligação à plataforma";
      case ALARM_MESSAGES.OCPP_ERROR:
        return "Ocorreu um erro no protocolo de comunicação com o carregador (OCPP)";
      case ALARM_MESSAGES.CIRCUIT_ERROR:
        return "O carregador reporta um problema no circuito elétrico";
    }
  };
}

AlarmService.$inject = ["$q", "AuthenticationService", "Alarm", "Site"];
