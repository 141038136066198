import {
  routes
} from './routes';
import AdaptiveDashboardService from './service';
import AdaptiveDashboardController from './dashboard/controller';
import AdaptiveDashboardWizardController from './wizard/controller';
import AdaptiveDashboardListController from './list/controller';

export default angular.module('app.dashboardadaptive', [])
  .config(routes)
  .service('AdaptiveDashboardService', AdaptiveDashboardService)
  .controller('AdaptiveDashboardController', AdaptiveDashboardController)
  .controller('AdaptiveDashboardWizardController', AdaptiveDashboardWizardController)
  .controller('AdaptiveDashboardListController', AdaptiveDashboardListController)
  .name;
