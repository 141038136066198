export default class ConstantListController {
  constructor(ConstantService, $state, UIService, $scope) {
    this.Constant = ConstantService;
    this.UI = UIService;
    this.$state = $state;
    this.loaded = false;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "desc",
      order: "name"
    };
    this.loadData();
    //TODO group by the group property?
  }

  loadData = () => {
    this.$state.go("app.constant.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.Constant.list(this.createFilter()).then(r => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast("Não foi possivel carregar constantes");
    });
  };

  createFilter = () => {
    let where = {};
    if (!this.filter.term.isEmpty()) {
      const pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      where.or = [];
      where.or.push({group: pattern})
      where.or.push({model: pattern})
      where.or.push({property: pattern})
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  removeConstant = (constant) => {
    let dialog = this.UI.showConfirm(`Deseja remover constante ${constant.model}.${constant.property} do sistema?`);
    dialog.then(() => {
      this.Constant.removeConstant(constant).then((result) => {
        let index = this.data.indexOf(constant);
        this.data.splice(index, 1);
        let snackbar = this.UI.addSnackbar('Constante removida', 'Anular', true);
        snackbar.then((res) => {
          if (res) {
            delete constant.id;
            this.Constant.save(constant).then((res) => {
              this.data.splice(index, 0, res);
            });
          }
        })
        //this.loadInfo();
      });
    });
  }

  modifyConstant = (constant) => {
    this.Constant.getAffectedRecords(constant.id).then((r) => {
      this.UI.showDialog({
        size: 'lg',
        template: require("./modify.dialog.html"),
        controller: ["$scope", "$filter", "UIService", ($scope, $filter, UIService) => {
            $scope.data = constant;
            $scope.affected = [];
            $scope.oldValue = constant.value;
            $scope.affected = r.records;
            $scope.keys = r.properties;



            $scope.cancel = function () {
              $scope.$dismiss("cancel");
            };

            $scope.canOk = () => {
              return $scope.data != null;
            };

            $scope.validate = () => {
              if (!$scope.data)
                return false;
              if (!$scope.data.value)
                return false;
              return true;
            };

            $scope.ok = () => {
              if ($scope.validate())
                $scope.$close($scope.data);
            };
          },
        ],
      }).then(r => {
        let data = r;
        this.Constant.save(data)
          .then((r) => {
            this.UI.addToast("Constante modificada com sucesso");
            this.loadData();
          })
          .catch(() => {
            this.UI.addToast("Ocorreu um erro ao modificar constante");
          });
      });
    })

  }
}

ConstantListController.$inject = ['ConstantService', '$state', 'UIService', "$scope"];
