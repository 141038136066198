import {
  routes
} from './routes';
import RegisterService from './service';
import RegisterListController from './list/controller';

export default angular.module('app.register', [])
  .config(routes)
  .service('RegisterService', RegisterService)
  .controller('RegisterListController', RegisterListController)
  .name;