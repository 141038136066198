import moment from "moment";
import { useFormHook } from "../../hooks";
const defaultValues = {
    DataInicio: moment(),
    DataFim: moment().add(2, "years"),
    Energia: true,
    Solar: true,
    ParciaisEnergia: false,
    Agua: false,
    CO2: false,
    EV: false,
    Portas: false,
    Temperatura: false,
    feedRedeId: "",
    feedRedeCapacity: "",
    feedProdId: "",
    feedProdCapacity: "",
    feedExcedId: "",
    feedExcedCapacity: "",
    Acerto: true,
};
const validators = [
    {
        field: "DataFim",
        validate: (licenciamento) => licenciamento.DataFim.isAfter(licenciamento.DataInicio),
        helperText: "Data de Fim não pode ser inferior a data de inicio.",
    },
    {
        field: "DataInicio",
        validate: (licenciamento) => licenciamento.DataInicio.isBefore(licenciamento.DataFim),
        helperText: "Data de Inicio não pode ser superior a Data de fim.",
    },
];
export const useLicenciamento = (defaults = {}) => useFormHook(defaultValues, validators, defaults);
