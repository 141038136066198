export function routes($stateProvider) {
  $stateProvider
    .state('app.hardware', {
      url: '/hardware',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.hardware.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'HardwareListController',
      controllerAs: 'vm',
      role: ['hardwareList']
    }).state('app.hardware.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'HardwareDetailsController',
      controllerAs: 'vm',
      role: ['hardwareEdit']
    }).state('app.hardware.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'HardwareDetailsController',
      controllerAs: 'vm',
      role: ['hardwareAdd']
    });
}

routes.$inject = ['$stateProvider'];
