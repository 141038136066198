window.$ = window.jQuery;
/* Ensure async/await funcionality */
require("regenerator-runtime/runtime");

import Administration from "./modules/administration";
import Aggregator from "./modules/aggregators";
import Alarm from "./modules/alarm";
import Analytics from "./modules/analytics";
import { ApplicationInterceptor } from "./app.interceptor";
import Authentication from "./modules/authentication";
import Authorization from "./modules/authorization";
import Entity from "./modules/entity";
import EvCharger from "./modules/ev";
import Event from "./modules/event";
import Hardware from "./modules/hardware";
import Interface from "./modules/interface";
import Leituras from "./modules/leituras";
import Location from "./modules/location";
import Measure from "./modules/measure";
import Register from "./modules/register";
import Site from "./modules/site";
import Device from "./modules/device";
/* Client modules */
import Smartmeter from "./modules/smartmeters";
import Tariff from "./modules/tariff";
import TariffWater from "./modules/tariffwater";
import Tariffcycle from "./modules/tariffcycle";
import Installer from "./modules/installer";
import DashboardAdaptive from "./modules/dashboardadaptive";
import Constant from "./modules/constant";
/* Angular Dependencies */
import angular from "angular";
import angularLocalePT from "angular-i18n/pt-pt";
import chartJS from "angular-chart.js";
import Interpolate from './interpolate.js';
import TracePlugin from './trace.js';
import config from "./app.config";
import "angular-file-upload";
let uiRouter = require("@uirouter/angularjs").default;
import "@uirouter/angularjs/release/stateEvents";
/* Client Modules */
import lbServices from "./modules/loopback/lbServices";
import ngAnimate from "angular-animate";
import ngCookies from "angular-cookies";
import ngResource from "angular-resource";
import ngSanitize from "angular-sanitize";
import uiSelect from "ui-select";

require("angularjs-gauge");
require("angular-schema-form");
require("angular-schema-form-bootstrap");
require("ui-select/dist/select.min.css");
moment.locale("pt");

let app = angular.module("app", [
  uiRouter,
  "ui.router.state.events",
  ngResource,
  ngSanitize,
  ngAnimate,
  ngCookies,
  uiSelect,
  "angularFileUpload",
  "schemaForm",
  angularLocalePT,
  chartJS,
  "angularjs-gauge",
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  Smartmeter,
  Entity,
  Site,
  Measure,
  Leituras,
  Tariff,
  Analytics,
  Alarm,
  Event,
  Aggregator,
  Location,
  Hardware,
  Register,
  EvCharger,
  Tariffcycle,
  TariffWater,
  Device,
  Installer,
  Constant,
  DashboardAdaptive
]);

app.config(config);
app.run(ApplicationInterceptor);
app.run([
  "$rootScope",
  ($rootScope) => {
    $rootScope.uiBase = {
      name: "SLT MongoDB",
      description: "Streamline Admin Template - MongoDB",
      version: "2.0.2",
    };
  },
]);

app.run([
  "$templateCache",
  function (templateCache) {
    templateCache.put(
      "decorators/bootstrap/default.html",
      require("./modules/interface/templates/formfield.html")
    );
  },
]);

// UI-Select config
app.config([
  "uiSelectConfig",
  function (uiSelectConfig) {
    uiSelectConfig.theme = "bootstrap";
    uiSelectConfig.resetSearchInput = false;
    uiSelectConfig.skipFocusser = true;
  },
]);

// Add refresh shortcut to do an F5 via $state
app.config([
  "$provide",
  function ($provide) {
    $provide.decorator("$state", [
      "$delegate",
      "$stateParams",
      function ($delegate, $stateParams) {
        $delegate.refresh = function () {
          return location.reload(true);
        };
        return $delegate;
      },
    ]);
  },
]);

// ChartJS Provieder
app.config([
  "ChartJsProvider",
  function (ChartJsProvider) {

    //Interpolate(Chart);
    //TracePlugin(Chart);

    Chart.elements.Rectangle.prototype.draw = function () {

      var ctx = this._chart.ctx;
      var vm = this._view;
      var left, right, top, bottom, signX, signY, borderSkipped, radius;
      var borderWidth = vm.borderWidth;
      // Set Radius Here
      // If radius is large enough to cause drawing errors a max radius is imposed
      var cornerRadius = 2;

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |
      var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom]
      ];

      // Find first (starting) corner with fallback to 'bottom'
      var borders = ['bottom', 'left', 'top', 'right'];
      var startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      var corner = cornerAt(0);
      var width, height, x, y, nextCorner, nextCornerId
      var x_tl, x_tr, y_tl, y_tr;
      var x_bl, x_br, y_bl, y_br;
      ctx.moveTo(corner[0], corner[1]);

      for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        var nextCornerId = i + 1;
        if (nextCornerId == 4) {
          nextCornerId = 0
        }

        var nextCorner = cornerAt(nextCornerId);

        var width = corners[2][0] - corners[1][0];
        var height = corners[0][1] - corners[1][1];
        var x = corners[1][0];
        var y = corners[1][1];

        var radius = cornerRadius;

        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
          radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
          radius = Math.floor(Math.abs(width) / 2);
        }

        if (height < 0) {
          // Negative values in a standard bar chart
          x_tl = x; x_tr = x + width;
          y_tl = y + height; y_tr = y + height;

          x_bl = x; x_br = x + width;
          y_bl = y; y_br = y;

          // Draw
          ctx.moveTo(x_bl + radius, y_bl);
          ctx.lineTo(x_br - radius, y_br);
          ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
          ctx.lineTo(x_tr, y_tr + radius);
          ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
          ctx.lineTo(x_tl + radius, y_tl);
          ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
          ctx.lineTo(x_bl, y_bl - radius);
          ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else if (width < 0) {
          // Negative values in a horizontal bar chart
          x_tl = x + width; x_tr = x;
          y_tl = y; y_tr = y;

          x_bl = x + width; x_br = x;
          y_bl = y + height; y_br = y + height;

          // Draw
          ctx.moveTo(x_bl + radius, y_bl);
          ctx.lineTo(x_br - radius, y_br);
          ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
          ctx.lineTo(x_tr, y_tr + radius);
          ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
          ctx.lineTo(x_tl + radius, y_tl);
          ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
          ctx.lineTo(x_bl, y_bl - radius);
          ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else {
          //Positive Value
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
        }
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
    };

    var horizonalLinePlugin = {
      afterDraw: function (chartInstance) {
        var scale
        let sValue;
        var canvas = chartInstance.chart;
        var ctx = canvas.ctx;
        var index;
        var line;
        var style;

        if (chartInstance.options.lines) {
          for (index = 0; index < chartInstance.options.lines.length; index++) {
            line = chartInstance.options.lines[index];

            if (!line.style) {
              style = "rgba(169,169,169, .6)";
            } else {
              style = line.style;
            }

            if (line.axis == 'x') {
              scale = chartInstance.options.scales.xAxes[0];

            } else if (line.axis == 'y') {
              scale = chartInstance.options.scales.yAxes[0];
            }
            scale = chartInstance.scales[scale.id];

            if (line.value) {
              sValue = scale.getPixelForValue(line.value);
            } else {
              sValue = 0;
            }

            ctx.lineWidth = line.width || 1;

            line.dashed = line.dashed || [];

            if (sValue) {
              ctx.beginPath();
              ctx.setLineDash(line.dashed);
              if (line.axis == 'x') {
                ctx.moveTo(sValue, 0);
                ctx.lineTo(sValue, canvas.height);
              } else if (line.axis == 'y') {
                ctx.moveTo(0, sValue);
                ctx.lineTo(canvas.width, sValue);
              }
              ctx.strokeStyle = style;
              ctx.stroke();
            }

            if (line.text) {
              ctx.fillStyle = style;
              var centerX = ((chartInstance.chartArea.left + chartInstance.chartArea.right) / 2);
              ctx.fillText(line.text, centerX, sValue - (ctx.lineWidth * 2));
            }
            // Clear
            ctx.setLineDash([]);
          }
          return;
        };
      }
    };
    Chart.pluginService.register(horizonalLinePlugin);

    // write text plugin
    Chart.pluginService.register({
      afterUpdate: function (chart) {
        if (chart.config.options.elements.center) {
          var helpers = Chart.helpers;
          var centerConfig = chart.config.options.elements.center;
          var globalConfig = Chart.defaults.global;
          var ctx = chart.chart.ctx;

          var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
          var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

          if (centerConfig.fontSize)
            var fontSize = centerConfig.fontSize;
          // figure out the best font size, if one is not specified
          else {
            ctx.save();
            var fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
            var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
            var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

            do {
              ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
              var textWidth = ctx.measureText(maxText).width;

              // check if it fits, is within configured limits and that we are not simply toggling back and forth
              if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize)
                fontSize += 1;
              else {
                // reverse last step
                fontSize -= 1;
                break;
              }
            } while (true)
            ctx.restore();
          }

          // save properties
          chart.center = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
          };
        }
      },
      afterDraw: function (chart) {
        if (chart.center) {
          var ctx = chart.chart.ctx;

          var i, a, s;
          var n = chart;
          var total = 0;
          for (i = 0, a = (n.data.datasets || []).length; a > i; ++i) {
            s = n.getDatasetMeta(i);
            var x;
            for (x = 0; x < s.data.length; x++) {
              if (!s.data[x].hidden)
                total += n.data.datasets[i].data[x];
            }
          }

          ctx.save();
          ctx.font = chart.center.font;
          ctx.fillStyle = chart.center.fillStyle;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillText(total, centerX, centerY);
          ctx.restore();
        }
      },
    });

    Chart.plugins.register({
      afterDatasetsDraw: function (chartInstance, easing) {
        // To only draw at the end of animation, check for easing === 1
        var ctx = chartInstance.chart.ctx;

        chartInstance.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.getDatasetMeta(i);
          let ctrl = meta.controller.chart;
          let datalabel = ctrl.options.datalabel;
          if (!meta.hidden) {
            if (datalabel && datalabel.display == true) {
              meta.data.forEach(function (element, index) {
                if (index == 0 || index == meta.data.length - 1) {
                  return;
                }

                let browserZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;
                let zoom = 0;
                if (browserZoomLevel >= 1) {
                  zoom = 0.25;
                } else {
                  zoom = 1 - browserZoomLevel;
                }
                let requiredIncrease = 1 + zoom;

                // Draw the text in black, with the specified font
                ctx.fillStyle = "rgba(0, 0, 0, .84)";

                var fontSize = 14 * requiredIncrease;
                var fontStyle = "normal";
                var fontFamily = "Roboto";
                ctx.font = Chart.helpers.fontString(
                  fontSize,
                  fontStyle,
                  fontFamily
                );

                // Just naively convert to string for now
                var dataString = dataset.data[index].toString();

                // Make sure alignment settings are correct
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                let append = datalabel.append || "";

                var padding = 10 * requiredIncrease;
                var position = element.tooltipPosition();
                ctx.fillText(
                  `${dataString}${append}`,
                  position.x,
                  position.y - fontSize + (padding / 2)
                );
                if (datalabel.labels == true && dataset[index].label) {
                  fontSize = 10 * requiredIncrease;
                  ctx.font = Chart.helpers.fontString(
                    fontSize,
                    fontStyle,
                    fontFamily
                  );
                  ctx.fillText(
                    dataset[index].label,
                    position.x,
                    position.y - fontSize - padding
                  );
                }
              });
            }
          }
        });
      },
    });
  }
]);
