export function routes($stateProvider) {
  $stateProvider
    .state('app.measure', {
      url: '/measure',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.measure.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'MeasureListController',
      controllerAs: 'vm',
      role: ['measureList']
    }).state('app.measure.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'MeasureDetailsController',
      controllerAs: 'vm',
      role: ['measureEdit']
    }).state('app.measure.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'MeasureDetailsController',
      controllerAs: 'vm',
      role: ['measureAdd']
    });
}

routes.$inject = ['$stateProvider'];
