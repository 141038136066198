import { routes } from "./routes";
import EntityService from "./service";
import EntityListController from "./list/controller";
import EntityDetailsController from "./details/controller";

export default angular
  .module("app.entity", [])
  .config(routes)
  .service("EntityService", EntityService)
  .controller("EntityListController", EntityListController)
  .controller("EntityDetailsController", EntityDetailsController).name;
