import {
  routes
} from './routes';
import AggregatorService from './service';
import AggregatorListController from './list/controller';
import AggregatorDetailsController from './details/controller';

export default angular.module('app.aggregator', [])
  .config(routes)
  .service('AggregatorService', AggregatorService)
  .controller('AggregatorListController', AggregatorListController)
  .controller('AggregatorDetailsController', AggregatorDetailsController)
  .name;