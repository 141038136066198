export function routes($stateProvider) {
  $stateProvider
    .state('app.location', {
      url: '/location',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.location.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'LocationListController',
      controllerAs: 'vm',
      //role: ['readLocation']
    }).state('app.location.details', {
      url: '/{id}&{siteId}',
      template: require('./details/view.html'),
      controller: 'LocationDetailsController',
      controllerAs: 'vm',
      //role: ['readLocation']
    }).state('app.location.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'LocationDetailsController',
      controllerAs: 'vm',
      //role: ['createLocation']
    });
}

routes.$inject = ['$stateProvider'];
