import { useState } from "react";
import { submit } from "../api";
import { useEmpresa, useInstalacao, useLicenciamento, useConta, useMeters, } from "./utils";
export const useWizard = (apiData) => {
    const empresa = useEmpresa();
    const [isSelected, setIsSelected] = useState(false);
    const [submited, setSubmited] = useState(false);
    const instalacao = useInstalacao();
    const licenciamento = useLicenciamento();
    const meters = useMeters();
    const conta = useConta();
    const selectEmpresa = (emp) => {
        if (emp != null) {
            empresa.set(emp);
            setIsSelected(true);
        }
        else {
            empresa.reset();
            setIsSelected(false);
        }
    };
    const isValid = (step) => {
        switch (step) {
            case "empresa":
                return empresa.isValid() || isSelected;
            case "instalacao":
                return instalacao.isValid();
            case "licenciamento":
                return licenciamento.isValid();
            case "meters":
                return ((!licenciamento.values.Energia || meters.ConsumoOperador.isValid()) &&
                    (!licenciamento.values.Solar ||
                        (meters.ProdSolar.isValid() && meters.ExcedenteSolar.isValid())));
            case "conta":
                return conta.isValid();
            default:
                return false;
        }
    };
    const submitWizard = (callbackProgress) => {
        if (submited)
            return;
        setSubmited(true);
        const meterarray = [];
        if (licenciamento.values.Energia)
            meterarray.push(meters.ConsumoOperador.values);
        if (licenciamento.values.Solar)
            meterarray.push(meters.ProdSolar.values, meters.ExcedenteSolar.values);
        return submit(empresa.values, instalacao.values, licenciamento.values, meterarray, apiData, conta.values, callbackProgress);
    };
    const reset = () => {
        empresa.reset();
        instalacao.reset();
        licenciamento.reset();
        meters.reset();
        conta.reset();
        setIsSelected(false);
        setSubmited(false);
    };
    return {
        empresa: {
            state: empresa,
            selectEmpresa,
            isSelected,
        },
        licenciamento,
        instalacao,
        meters,
        conta,
        isValid,
        isSubmited: submited,
        submit: submitWizard,
        reset,
    };
};
