export default class DashboardTemperatureController {
  constructor($state, UIService, DashboardService) {
    this.$state = $state;
    this.UI = UIService;
    this.Dashboard = DashboardService;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Dashboard.getSmartmeters("60acdc5b0b903917588ec45b").then(r => {
      this.smartmeters = r;
      this.smartmeters.forEach(a => {
        a.loaded = false;
        a.date = a.date || moment.utc();
        this.getDayRegister(a, a.date, "quarter")
      })
    });
  }

  getDayRegister = (obj, date, unit) => {
    this.Dashboard.getSmartmeter24hrs(obj.id, moment.utc(date).toISOString(), unit).then(r => {
      this.setChartData(obj, r);
      obj.loaded = true;
    });
  }

  setChartData = (row, origin) => {

    if (!Array.isArray(origin) || !origin.length) return;

    let labels = origin.map(r => r.time);
    let colors = ["#FDA50F"];
    let tints = ["#FDA50F"];

    let datasets = [];
    let data = [[]];
    let series = ["Temperatura"];
    origin.forEach((r, i, a) => {
      data[0].push(r.value);
    });

    let info = {
      top: origin.reduce((prev, current) => (prev.value > current.value) ? prev : current),
      bottom: origin.reduce((prev, current) => (prev.value < current.value) ? prev : current),
    }

    // For line charts, we adjust like this
    data.forEach((r, i) => {
      datasets.push({
        labels: series[i],
        fill: true,
        strokeColor: colors[i],
        lineTension: 0.2,
        borderWidth: 0,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBorderColor: colors[i],
        pointBackgroundColor: colors[i],
        pointRadius: 0.2,
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        pointHoverRadius: 0.2,
      });
    });

    row.info = info;
    row.chart = {

      labels: labels.map(label => label.replace(/Z$/, '')),
      datasets: datasets,
      data: data,
      series: series,
      options: {
        tooltips: {
          position: 'nearest',
          intersect: false,
          callbacks: {
            title: (data) => {
              return moment(data[0].xLabel).format("D/MM HH:00");
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time", // add this!
              parser: 'HH:mm',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                }
              },
            }
          ],
          yAxes: [
            {
              id: "ºC",
              position: 'left',
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false,
              },
              display: true,
              ticks: {
                max: origin.max,
                maxTicksLimit: 5,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return Math.round((value + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2);
                }
              }
            }
          ]
        }
      }
    }
    if (row.limit) {
      row.chart.options.lines = [{
        axis: 'y',
        value: row.limit.bottom,
        dashed: [10, 5],
        style: "rgba(0, 0, 0, .84)",
        text: "Limite inferior",
        width: 1,
      }, {
        axis: 'y',
        value: row.limit.top,
        dashed: [10, 5],
        style: "rgba(0, 0, 0, .84)",
        text: "Limite superior",
        width: 1,
      }];
    }
  }

  setDayLabel = row => {
    if (!row || !row.date) return "";
    if (moment.utc(row.date).isSame(moment.utc(), 'day')) {
      return "Hoje";
    } else {
      return moment.utc(row.date).format("DD/MM/YYYY")
    }
  }

  setNextDate = (row) => {
    row.loaded = false;
    row.date = moment.utc(row.date).add(1, 'day');
    this.getDayRegister(row, row.date, "quarter");
  }

  setPreviousDate = (row) => {
    row.loaded = false;
    row.date = moment.utc(row.date).subtract(1, 'day');
    this.getDayRegister(row, row.date, "quarter");
  }
}

DashboardTemperatureController.$inject = ['$state', 'UIService', 'DashboardService']
