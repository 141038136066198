import { useState, useEffect, createContext } from "react";
import { getInstallers, getMeasures, getResponsabilityChoices, getTemplateTariff, getTypes, } from "../api";
export const useApi = () => {
    const [installers, setInstallers] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [types, setTypes] = useState([]);
    const [responsabilityChoices, setResponsabilityChoices] = useState([]);
    const [tariff, setTariff] = useState({});
    useEffect(() => {
        getMeasures().then((m) => setMeasures(m));
        setTypes(getTypes());
        setResponsabilityChoices(getResponsabilityChoices());
        getTemplateTariff().then((t) => setTariff(t));
        getInstallers().then((t) => setInstallers(t));
    }, []);
    return {
        Installers: installers,
        Measurements: measures,
        responsabilityChoices,
        Types: types,
        DefaultTariff: tariff,
    };
};
export const ApiData = createContext({
    Measurements: [],
    Types: [],
    Empresas: [],
    Installers: [],
    responsabilityChoices: [],
    DefaultTariff: {},
});
export const getMeasurement = (apiData, id) => apiData.Measurements.find((mes) => mes.id == id) || {
    name: "Energia Elétrica",
    unit: "W",
    id: id,
    icon: "bolt",
};
