import { routes } from './routes';
import InstallerService from './service';
import InstallerListController from './list/controller';
import InstallerDetailsController from './details/controller';

export default angular.module('app.installer', [])
  .config(routes)
  .service('InstallerService', InstallerService)
  .controller('InstallerListController', InstallerListController)
  .controller('InstallerDetailsController', InstallerDetailsController)
  .name;
