export function routes($stateProvider) {

  $stateProvider
    .state('app.administration', {
      url: '/administration',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.evProfiles', {
      url: '/ev-profiles',
      template: require('./ev-profiles/view.html'),
      controller: 'EvProfileListController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.logging', {
      url: '/logging',
      template: require('./logging/logging.view.html'),
      controller: 'LoggingController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.system', {
      url: '/system',
      template: require('./system/system.view.html'),
      controller: 'SystemController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.settings', {
      url: '/settings',
      template: require('./settings/view.html'),
      controller: 'SettingsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.main', {
      url: '/',
      template: require('./main/main.view.html'),
      controller: 'ManagementController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.users', {
      url: '/users',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.users.add', {
      url: '/add',
      template: require('./main/users/add/add.view.html'),
      controller: 'UserAdminController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.users.edit', {
      url: '/edit/:id',
      template: require('./main/users/edit/edit.view.html'),
      controller: 'UserEditAdminController',
      controllerAs: 'vm',
      role: ['controlPanel']

    })
    .state('app.administration.roles', {
      url: '/roles',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.roles.edit', {
      url: '/edit/:id',
      template: require('./main/roles/edit/edit.view.html'),
      controller: 'RoleEditController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.roles.add', {
      url: '/add',
      template: require('./main/roles/add/add.view.html'),
      controller: 'RoleAddController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.backlog', {
      url: '/backlog',
      template: require('./backlog/view.html'),
      controller: 'BacklogController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.wizard', {
      url: '/wizard',
      template: require('./main/wizard/view.html'),
      controller: null,
      role: ['controlPanel']
    })
    .state('app.administration.mainV2', {
      url: '/main',
      template: require('./main/main.html'),
      controller: null,
      role: ['controlPanel']
    })
    .state('app.administration.users.editV2', {
      url: '/editV2/{id:string}',
      template: require('./main/users/edit/edit.viewV2.html'),
      controller: null,
      role: ['controlPanel']
    })
    .state('app.administration.roles.editV2', {
      url: '/editV2/{id:string}',
      template: require('./main/roles/edit/edit.viewV2.html'),
      controller: null,
      role: ['controlPanel']
    })
    .state('app.administration.users.addV2', {
      url: '/addV2',
      template: require('./main/users/add/add.viewV2.html'),
      controller: null,
      role: ['controlPanel']
    })
    .state('app.administration.roles.addV2', {
      url: '/addV2/',
      template: require('./main/roles/add/add.viewV2.html'),
      controller: null,
      role: ['controlPanel']
    })
    ;
}

routes.$inject = ['$stateProvider'];
