import { routes } from './routes';
import MeasureService from './service';
import MeasureListController from './list/controller';
import MeasureDetailsController from './details/controller';

export default angular.module('app.measure', [])
  .config(routes)
  .service('MeasureService', MeasureService)
  .controller('MeasureListController', MeasureListController)
  .controller('MeasureDetailsController', MeasureDetailsController)
  .name;
