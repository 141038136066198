import moment from "moment";
import { useFormHook } from "../../hooks";
const defaultFuncionalidade = {
    Nome: "Geral (Shelly)",
    UnidadeMedida: "60acdc1c0b903917588ec458",
    CapacidadeMaxima: "",
    FeedId: "",
};
export const createFuncionalidade = (data) => Object.assign({}, defaultFuncionalidade, data);
const defaultMeter = {
    Nome: "",
    Ativo: true,
    Observacoes: "",
    UnidadeMedida: "60acdc1c0b903917588ec458",
    TipoRegisto: "0",
    OpenEnergyNodeId: "",
    SerialNumber: "",
    CapacidadeMaxima: "",
    DataInicio: moment(),
    TarifarioEnergia: "633c3e2c0bc9dad1501c6953",
    LeituraConsumoGeral: true,
    Funcionalidades: [createFuncionalidade({})],
};
const validators = [
    {
        field: "Nome",
        validate: (meter) => meter.Nome.length >= 3,
        helperText: "Nome invalido.",
    },
    {
        field: "OpenEnergyNodeId",
        validate: (meter) => meter.OpenEnergyNodeId.length >= 1,
        helperText: "Node Id invalido.",
    },
];
export const useMeters = (defaults = {
    ConsumoOperador: { Nome: "Consumo Operador" },
    ExcedenteSolar: { Nome: "Excedente Solar" },
    ProdSolar: { Nome: "Produção Solar" },
}) => {
    const ConsumoOperador = useMeter(defaults.ConsumoOperador);
    const ExcedenteSolar = useMeter(defaults.ExcedenteSolar);
    const ProdSolar = useMeter(defaults.ProdSolar);
    const reset = () => {
        ConsumoOperador.reset();
        ExcedenteSolar.reset();
        ProdSolar.reset();
    };
    return { ConsumoOperador, ExcedenteSolar, ProdSolar, reset };
};
const useMeter = (defaults) => useFormHook(defaultMeter, validators, defaults);
