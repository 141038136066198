export default class LocationService {
  constructor($q, AuthenticationService, Location) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Location = Location;
  }

  list = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Location.count({
      where: {
        entityId: user.selected.entityId
      }
    }).$promise.then(c => {
      this.Location.find({
        filter: {
          where: {
            entityId: user.selected.entityId
          }
        }
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  get = id => {
    let defer = this.$q.defer();
    this.Location.findById({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    data.entityId = user.selected.entityId;
    this.Location.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }
}

LocationService.$inject = ['$q', 'AuthenticationService', 'Location'];
