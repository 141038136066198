import { mdiCrosshairsGps } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton, Stack, TextField, Tooltip, Typography, } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ApiData } from "../utils";
import { CustomSelect, CustomTextField, ImagePicker, } from "../utils/CustomComponents";
export const getCodigoOE = (nome) => nome
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s/g, "");
const InstalacaoForm = ({ state: { getErr, hasErr, isValid, setField, values: instalacao }, empresa, forceShowErrors, }) => {
    const apiData = useContext(ApiData);
    useEffect(() => {
        //TODO change to set()?
        setField("Nome", instalacao.Nome || empresa.name);
        setField("CodigoOE", instalacao.CodigoOE || getCodigoOE(empresa.name));
        setField("Morada", instalacao.Morada || empresa.address);
        setField("CodigoPostal", instalacao.CodigoPostal || empresa.zipcode || "");
        setField("Localidade", instalacao.Localidade || empresa.location);
    }, []);
    const hasError = (field) => hasErr(field, forceShowErrors);
    const getError = (field) => getErr(field, forceShowErrors);
    const locationGetter = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setField("Latitude", position.coords.latitude.toString());
            setField("Longitude", position.coords.longitude.toString());
        });
    };
    const resetImg = () => setField("Imagem", undefined);
    return (<div>
      <Typography sx={{ mt: 1, mb: 1 }}>Passo 2 - Criar Instalação</Typography>
      <div style={{ display: "flex" }}>
        <Stack spacing={1} sx={{ width: "100%", marginRight: "10px" }}>
          <div style={{ display: "flex" }}>
            <CustomTextField required label="Nome" sx={{ width: "80%", marginRight: "10px" }} field="Nome" defaultValue={instalacao.Nome || empresa.name} obj={instalacao} handleChange={setField} hasError={hasError} getHelperText={getError}/>
            <CustomTextField label="Código OE" sx={{ width: "20%" }} field="CodigoOE" obj={instalacao} defaultValue={instalacao.CodigoOE || getCodigoOE(empresa.name)} handleChange={setField} hasError={hasError} getHelperText={getError}/>
          </div>
          <CustomTextField required label="Morada" field="Morada" defaultValue={instalacao.Morada || empresa.address} obj={instalacao} handleChange={setField} hasError={hasError} getHelperText={getError}/>
          <div style={{ display: "flex" }}>
            <CustomTextField label="Código Postal" sx={{ width: "30%", marginRight: "10px" }} defaultValue={instalacao.CodigoPostal || empresa.zipcode} field="CodigoPostal" obj={instalacao} handleChange={setField} hasError={hasError} getHelperText={getError}/>
            <CustomTextField label="Localidade" sx={{ width: "70%" }} field="Localidade" obj={instalacao} defaultValue={instalacao.Localidade || empresa.location} handleChange={setField} hasError={hasError} getHelperText={getError}/>
          </div>
          <CustomTextField label="Email Técnico" field="EmailTecnico" obj={instalacao} handleChange={setField} hasError={hasError} getHelperText={getError}/>
          <div style={{ display: "flex" }}>
            <TextField label="Latitude" value={instalacao.Latitude} error={hasError("Latitude")} helperText={getError("Latitude")} onChange={(e) => setField("Latitude", e.target.value)} variant="standard" sx={{ width: "50%", marginRight: "10px" }}/>
            <TextField label="Longitude" value={instalacao.Longitude} error={hasError("Longitude")} helperText={getError("Longitude")} onChange={(e) => setField("Longitude", e.target.value)} variant="standard" sx={{ width: "50%" }}/>
            <IconButton onClick={locationGetter} color="primary">
              <Tooltip title="Obter Localização automaticamente">
                <Icon path={mdiCrosshairsGps} size={1}/>
              </Tooltip>
            </IconButton>
          </div>
          <CustomSelect obj={instalacao} field="InstallerId" items={apiData.Installers} label="Instalador" handleChange={setField}/>
        </Stack>
        <ImagePicker img={instalacao.Imagem} setImg={(file) => setField("Imagem", file)} resetImg={resetImg} sx={{
            height: "302px",
        }}/>
      </div>
    </div>);
};
export default InstalacaoForm;
