import angular from "angular";

export default class LeiturasSmartmeterController {
  constructor($state, $scope, $timeout, $interval, UIService, SmartmeterService, SiteService, TariffService, TariffWaterService, AggregatorService, AuthorizationService, AuthenticationService) {
    // Models
    this.$state = $state;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.UI = UIService;
    this.Smartmeter = SmartmeterService;
    this.Site = SiteService;
    this.Tariff = TariffService;
    this.Tariffwater = TariffWaterService;
    this.Aggregator = AggregatorService;
    this.Authorization = AuthorizationService;
    this.Authentication = AuthenticationService;
    // Variables
    this.loaded = false;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "desc",
      order: "name"
    };
    this.data = [];
    this.user = this.Authentication.getUser();
    this.loadData();
  }

  loadData = () => {
    this.$state.go("app.leituras.smartmeter", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Smartmeter.list(this.createFilter()).then((r) => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.hasNavigation = this.total > r.data.length;
      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast("Não foi possivel carregar smart meters");
    });
  };

  createFilter = () => {
    let prop = "";
    let pattern = "";
    let where = {};

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      where[prop] = pattern;
    }
    where.siteId = this.user.selected.siteId;
    where.active = true;
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  viewAggregate = (id, $evt) => {
    $evt.stopPropagation();
    this.$state.go('app.leituras.aggregate', { id: id });
  }

  viewRegisters = (id, $evt) => {
    $evt.stopPropagation();
    this.$state.go('app.leituras.registers', { id: id });
  }

  greaterThan = (prop, val) => {
    return (item) => {
      return item[prop] > val;
    };
  };

  update = () => {
    this.Smartmeter.save(this.newDetails).then((r) => {
      this.newDetails = r;
      this.details = angular.copy(r);
      this.UI.addToast("Dados atualizados com sucesso");
    });
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

}

LeiturasSmartmeterController.$inject = ["$state", "$scope", "$timeout", "$interval", "UIService", "SmartmeterService", "SiteService", "TariffService", "TariffWaterService", "AggregatorService", "AuthorizationService", "AuthenticationService"];
