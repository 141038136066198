export default class AggregatorService {
  constructor(
    $q,
    $rootScope,
    AuthenticationService,
    AuthorizationService,
    Site,
    Aggregator,
    $http
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Site = Site;
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.Aggregator = Aggregator;
    this.$http = $http;
  }

  save = (data) => {
    let defer = this.$q.defer();
    this.Aggregator.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    filter = filter || {};

    filter.where = filter.where || {};
    filter.where.siteId = { inq: user.siteIds };
    filter.include = [
      {
        relation: "site",
        scope: {
          include: "entity",
        },
      },
      "hardware",
      "smartmeters",
    ];

    this.Aggregator.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Aggregator.find({
          filter: filter
        })
          .$promise.then((r) => {
            defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Aggregator.findById({
      id: id,
      filter: {
        include: [
          "hardware",
          "site",
          { relation: "smartmeters", scope: { include: "location" } },
        ],
      },
    })
      .$promise.then((r) => {
        defer.resolve(r);
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/gateways/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  }
}

AggregatorService.$inject = [
  "$q",
  "$rootScope",
  "AuthenticationService",
  "AuthorizationService",
  "Site",
  "Aggregator",
  "$http"
];
