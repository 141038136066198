export function routes($stateProvider) {
  $stateProvider
    .state('app.analytics', {
      url: '/analytics',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.analytics.details', {
      url: '/{smartmeterId}',
      template: require('./details/view.html'),
      controller: 'AnalyticsDetailsController',
      controllerAs: 'vm'
    }).state('app.analytics.details2', {
      url: '/details2/{smartmeterId}',
      template: require('./details2/view.html'),
      controller: 'AnalyticsDetails2Controller',
      controllerAs: 'vm',
      params: {
        filter: null,
      }
    });
}

routes.$inject = ['$stateProvider'];
