import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomTextField } from "../utils/CustomComponents";
const ContaForm = ({ forceShowErrors, state: { getErr, hasErr, isValid, setField, values: conta }, }) => {
    const hasError = (field) => hasErr(field, forceShowErrors);
    const getError = (field) => getErr(field, forceShowErrors);
    return (<div>
      <Typography sx={{ mt: 1, mb: 1 }}>Passo 5 - Criar Conta</Typography>
      <Stack spacing={1}>
        <CustomTextField obj={conta} field="Nome" required handleChange={setField} label="Nome" hasError={hasError} getHelperText={getError}/>
        <CustomTextField obj={conta} field="Email" required handleChange={setField} label="Email" hasError={hasError} getHelperText={getError}/>
      </Stack>
    </div>);
};
export default ContaForm;
