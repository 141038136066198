import { mdiAlertCircle, mdiCheck, mdiHelp, mdiSkipNext } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, Card, Divider, LinearProgress, Step, StepLabel, Stepper, ThemeProvider, Typography, } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pt";
import React, { useState } from "react";
import "./Wizard.css";
import { SubmissionStatus } from "./api";
import ContaForm from "./steps/Conta";
import EmpresaForm from "./steps/Empresa";
import InstalacaoForm from "./steps/Instalacao";
import LicenciamentoForm from "./steps/Licenciamento";
import MetersForm from "./steps/Meters";
import { ApiData, theme, useApi } from "./utils";
import { useWizard } from "./hooks";
const steps = ["Empresa", "Instalação", "Licenciamento", "Meters", "Conta"];
const Wizard = () => {
    const [activeStep, setActiveStep] = useState(0);
    const apiData = useApi();
    const [forceShowErrors, setForceShowErrors] = useState(false);
    const [successfull, setSuccessfull] = useState();
    const [submissions, setSubmissions] = useState([]);
    const { empresa, licenciamento, instalacao, conta, isValid, meters, isSubmited, submit, reset, } = useWizard(apiData);
    const submissioncallback = (name, status) => {
        const updatedSubmissions = submissions;
        updatedSubmissions.push({ name, status });
        setSubmissions(updatedSubmissions);
    };
    const getSubmissionStatusIcon = (status) => {
        switch (status) {
            case SubmissionStatus.failed:
                return <Icon path={mdiAlertCircle} size={1}/>;
            case SubmissionStatus.skipped:
                return <Icon path={mdiSkipNext} size={1}/>;
            case SubmissionStatus.submitted:
                return <Icon path={mdiCheck} size={1}/>;
            default:
                return <Icon path={mdiHelp} size={1}/>;
        }
    };
    const getCurrentStep = () => {
        switch (activeStep) {
            case 0:
                return "empresa";
            case 1:
                return "instalacao";
            case 2:
                return "licenciamento";
            case 3:
                return "meters";
            case 4:
                return "conta";
            default:
                return null;
        }
    };
    const handleNext = () => {
        if (forceShowErrors)
            setForceShowErrors(false);
        const currentStep = getCurrentStep();
        if (!isValid(currentStep)) {
            setForceShowErrors(true);
            return;
        }
        if (currentStep === "conta") {
            submit(submissioncallback).then(setSuccessfull);
        }
        if (currentStep === "licenciamento" &&
            !licenciamento.values.Energia &&
            !licenciamento.values.Solar)
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        else
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        if (activeStep === 4 &&
            !licenciamento.values.Energia &&
            !licenciamento.values.Solar)
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        else
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStep = (nextStep) => {
        if (nextStep > activeStep) {
            let i = activeStep;
            while (i < nextStep) {
                handleNext();
                i++;
            }
        }
        else if (nextStep < activeStep) {
            setActiveStep(nextStep);
        }
    };
    const handleReset = () => {
        reset();
        setActiveStep(0);
        setSuccessfull(undefined);
    };
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (<EmpresaForm state={empresa.state} forceShowErrors={forceShowErrors} selectEmpresa={empresa.selectEmpresa} isSelected={empresa.isSelected}/>);
            case 1:
                return (<InstalacaoForm state={instalacao} forceShowErrors={forceShowErrors} empresa={empresa.state.values}></InstalacaoForm>);
            case 2:
                return (<LicenciamentoForm state={licenciamento} forceShowErrors={forceShowErrors}></LicenciamentoForm>);
            case 3:
                return (<MetersForm obj={meters} forceShowErrors={forceShowErrors} licenciamento={licenciamento.values} instalacao={instalacao.values}/>);
            case 4:
                return (<ContaForm state={conta} forceShowErrors={forceShowErrors}></ContaForm>);
            default:
                return null;
        }
    };
    return (<ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt">
        <Card sx={{ width: "100%", padding: "10px", minWidth: "600px" }}>
          <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (<Step key={label}>
                  
                  <StepLabel>
                    <Button onClick={() => handleStep(index)}>
                      {label}
                    </Button>
                  </StepLabel>
                </Step>);
        })}
          </Stepper>
          <Divider sx={{ marginTop: 2 }}/>
          {activeStep === steps.length ? (<div>
              <Typography sx={{ mt: 1, mb: 1 }}>
                Todos os passos foram terminados!
              </Typography>
              {submissions.map((submission, index) => (<Typography key={index}>
                  {submission.name} {getSubmissionStatusIcon(submission.status)}
                </Typography>))}

              {isSubmited && successfull !== undefined ? (successfull ? (<Typography sx={{ mt: 1, mb: 1 }} color="success">
                    Submetido com sucesso
                  </Typography>) : (<Typography sx={{ mt: 1, mb: 1 }} color="error">
                    Ocorreu um erro durante a submissão.
                  </Typography>)) : (<LinearProgress />)}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }}/>
                <Button onClick={handleReset}>Reiniciar</Button>
              </Box>
            </div>) : (<div>
              <Box sx={{ p: 3, minHeight: "600px" }}>
                <ApiData.Provider value={apiData}>
                  {renderStepContent(activeStep)}
                </ApiData.Provider>
              </Box>
              <Divider sx={{ marginTop: 2 }}/>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Anterior
                </Button>
                <Box sx={{ flex: "1 1 auto" }}/>
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finalizar" : "Seguinte"}
                </Button>
              </Box>
            </div>)}
        </Card>
      </LocalizationProvider>
    </ThemeProvider>);
};
export default Wizard;
