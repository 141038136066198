import angular from 'angular';
import { routes } from './administration.routes'

import ManagementController from './main/main.controller';
import UserAdminController from './main/users/add/add.controller';
import UserEditAdminController from './main/users/edit/edit.controller';
import RoleEditController from './main/roles/edit/edit.controller';
import AdministrationService from "./administration.service";
import RoleAddController from "./main/roles/add/add.controller";
import LoggingController from "./logging/logging.controller";
import SystemController from "./system/system.controller";
import SettingsController from './settings/controller';
import EvProfileListController from './ev-profiles/controller';
import BacklogController from './backlog/controller';

import Wizard from './main/wizard/Wizard';
import Main from './main/Main';
import UserEdit from './main/users/edit/UserEdit';
import GroupEdit from './main/roles/edit/RoleEdit';
import UserAdd from './main/users/add/UserAdd';
import RoleAdd from './main/roles/add/RoleAdd';
import { react2angular } from 'react2angular'

export default angular.module('app.administration', [])
  .component('wizard', react2angular(Wizard))
  .component('main', react2angular(Main, [], ['$state', 'AdministrationService']))
  .component('editUser', react2angular(UserEdit, [], ['$state', 'AdministrationService']))
  .component('editGroup', react2angular(GroupEdit, [], ['$state', 'AdministrationService']))
  .component('addUser', react2angular(UserAdd, [], ['$state', 'AdministrationService']))
  .component('addRole', react2angular(RoleAdd, [], ['$state', 'AdministrationService']))
  .config(routes)
  .controller('BacklogController', BacklogController)
  .controller('ManagementController', ManagementController)
  .controller('LoggingController', LoggingController)
  .controller('SystemController', SystemController)
  .controller('UserAdminController', UserAdminController)
  .controller('RoleEditController', RoleEditController)
  .controller('RoleAddController', RoleAddController)
  .controller('UserEditAdminController', UserEditAdminController)
  .controller('SettingsController', SettingsController)
  .controller('EvProfileListController', EvProfileListController)
  .service('AdministrationService', AdministrationService)
  .filter('secondsToTime', function () {
    function padTime(t) {
      return t < 10 ? "0" + t : t;
    }

    return function (_seconds) {
      if (typeof _seconds !== "number" || _seconds < 0)
        return "00:00:00";

      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);

      return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
    };
  })
  .name;
