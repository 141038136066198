export default class HardwareDetailsController {
  constructor($state, UIService, HardwareService, AuthorizationService, SiteService) {
    this.$state = $state;
    this.UI = UIService;
    this.Hardware = HardwareService;
    this.Site = SiteService;
    this.Authorization = AuthorizationService;

    this.id = $state.params.id;
    this.isCompleted = false;
    this.data = {};
    this.site = {};
    this.filter = {
      where: {}
    };

    if (this.id) {
      this.label = "Editar";
    } else {
      this.label = "Adicionar";
    }
    this.loadData();
  }

  loadData = () => {
    this.Site.list(this.filter).then(res => {
      this.sites = res.data;
      if (this.siteId) {
        this.Site.get(this.siteId).then(res => {
          this.site = res;
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao carregar instalação");
        });
      }
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar instalações");
    });

    if (this.id) {
      this.Hardware.get(this.id).then(res => {
        this.data = res;
        this.isCompleted = true;
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao carregar espaço");
      });
    }

    this.loaded = true;
  }

  save = () => {
    if (this.validate()) {
      this.Hardware.save(this.data).then(r => {
        if (this.id)
          this.UI.addToast("Hardware alterado com sucesso");
        else
          this.UI.addToast("Hardware adicionado com sucesso");
        this.$state.go('app.hardware.list');
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar hardware");
      });
    }
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o tipo de hardware");
      return false;
    }
    return true;
  }

  editable = () => {
    return this.Authorization.canPerform(['editSite']);
  }
}

HardwareDetailsController.$inject = ['$state', 'UIService', 'HardwareService', 'AuthorizationService', 'SiteService'];
