export default class AnalyticsService {
  constructor($q, Site, Authentication, Register, Measure) {
    this.$q = $q;
    this.Site = Site;
    this.Auth = Authentication;
    this.Register = Register;
    this.Measure = Measure;
  }

  loadFilterData = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    // Site filter
    let siteFilter = {
      filter: {
        include: [
          "locations",
          {
            relation: "aggregators",
            scope: {
              include: "smartmeters",
            },
          },
        ],
        where: {
          id: {
            inq: user.siteIds,
          },
        },
      },
    };
    this.Measure.find()
      .$promise.then((m) => {
        this.Site.find(siteFilter)
          .$promise.then((r) => {
            let sites = [];
            let locations = [];
            let smartmeters = [];
            let aux;
            sites = r.map((data) => {
              return {
                id: data.id,
                name: data.name,
              };
            });

            aux = r.map((r) => r.locations);
            aux.forEach((location) => {
              location.forEach((row) => {
                locations.push(row);
              });
            });
            aux = r.map((r) => r.aggregators);
            aux.forEach((data) => {
              data.forEach((row) => {
                // add siteId via location to the smartmeter
                row.smartmeters.forEach((s) => {
                  let location = locations.find((a) =>
                    a.id.localeCompare(s.locationId)
                  );
                  if (location) {
                    s.siteId = location.siteId;
                  }
                });
                smartmeters.push(...row.smartmeters);
              });
            });

            defer.resolve({
              sites: sites,
              smartmeters: smartmeters,
              measures: m,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  chart = (from, to, smartmeter, scale, siteId) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    switch (scale.id) {
      case 0:
        this.Register.chart15min({
          from: from,
          to: to,
          smartmeter: smartmeter,
          siteId: siteId,
          entityId: user.selected.entityId,
        }).$promise.then((r) => defer.resolve(r))
          .catch((e) => defer.reject(e));
        break;
      case 1:
        this.Register.chart1hour({
          from: from,
          to: to,
          smartmeter: smartmeter
        }).$promise.then((r) => defer.resolve(r))
          .catch((e) => defer.reject(e));
        break;
      case 2:
        this.Register.chart1day({
          from: from,
          to: to,
          smartmeter: smartmeter
        }).$promise.then((r) => defer.resolve(r))
          .catch((e) => defer.reject(e));
        break;
      case 3:
        this.Register.chart1month({
          from: from,
          to: to,
          smartmeter: smartmeter
        }).$promise.then((r) => defer.resolve(r))
          .catch((e) => defer.reject(e));
        break;
    }

    return defer.promise;
  };
}

AnalyticsService.$inject = [
  "$q",
  "Site",
  "AuthenticationService",
  "Register",
  "Measure",
];
