export default class SmartmeterRegisterController {
  constructor($state, UIService, SmartmeterService) {
    this.$state = $state;
    this.UI = UIService;
    this.Smartmeter = SmartmeterService;

    this.id = $state.params.id;

    if ($state.params.from && $state.params.to) {
      this.params = {};
      this.params.from = moment.utc($state.params.from);
      this.params.to = moment.utc($state.params.to);
    } else {
      this.params = {
        from: moment.utc().subtract(7, "day"),
        to: moment.utc(),
      };
    }
    this.Smartmeter.get(this.id).then(r => {
      this.data = r;
    });
    this.loadData();
  }

  filterKeys = (keys) => {
    const replace = {"date": "Data", "total": "Total"}
    const ret = []
    for (const key of keys) {
      if (replace[key])
        ret.push(replace[key]);
      else
        ret.push(key);
    }
    return ret;
  }

  loadData = () => {
    this.loaded = false;
    this.$state.go(
      "app.smartmeter.registers",
      {
        id: this.id,
        from: this.params.from.format("YYYY-MM-DD"),
        to: this.params.to.format("YYYY-MM-DD"),
      },
      {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: "replace",
        // inherit the current params on the url
        inherit: true,
      }
    );
    this.Smartmeter.table(this.params.from, this.params.to, this.id).then(
      (r) => {
        // Digest table
        this.table = {};
        if (!r.isEmpty()) {
          this.table.fields = Object.keys(r[0]);
          this.table.fieldNames = this.filterKeys(this.table.fields);
          this.table.data = r;
        }
        this.loaded = true;
      }
    );
  };

  exportCSV = ($event) => {
    let document = angular.element('#table-data');
    var csv = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");

        for (var j = 0; j < cols.length; j++)
            row.push(cols[j].innerText);

        csv.push(row.join(";"));
    }

    // Download CSV file
    // Formato do nome Instalacao-local-registo-data
    let name = this.id;
    if (this.data.location && this.data.location.site) {
      name = `${this.data.location.site.name}-${this.data.location.name}`;
    }
    this.downloadCSV(csv.join("\n"), `${name}-${this.params.from.format("YYYY-MM-DD")}-${this.params.to.format("YYYY-MM-DD")}.csv`);
  };

  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
}
}

SmartmeterRegisterController.$inject = [
  "$state",
  "UIService",
  "SmartmeterService",
];
