export function routes($stateProvider) {
  $stateProvider
    .state('app.device', {
      url: '/devices',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.device.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
      template: require('./list/view.html'),
      controller: 'DeviceListController',
      controllerAs: 'vm',
      role: ['deviceList']
    }).state('app.device.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'DeviceDetailsController',
      controllerAs: 'vm',
      role: ['deviceEdit']
    }).state('app.device.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'DeviceDetailsController',
      controllerAs: 'vm',
      role: ['deviceAdd']
    });
}

routes.$inject = ['$stateProvider'];
