import { routes } from './routes';
import ConstantService from './service';
import ConstantListController from './list/controller';
import ConstantDetailsController from './details/controller';

export default angular.module('app.constant', [])
  .config(routes)
  .service('ConstantService', ConstantService)
  .controller('ConstantListController', ConstantListController)
  .controller('ConstantDetailsController', ConstantDetailsController)
  .name;
