import {
  routes
} from './routes';
import LocationService from './service';
import LocationListController from './list/controller';
import LocationDetailsController from './details/controller';

export default angular.module('app.location', [])
  .config(routes)
  .service('LocationService', LocationService)
  .controller('LocationListController', LocationListController)
  .controller('LocationDetailsController', LocationDetailsController)
  .name;
