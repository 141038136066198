export function routes($stateProvider) {
  $stateProvider
    .state('app', {
      template: require('./layout/layout.view.html'),
      controller: 'LayoutController',
      controllerAs: 'vm'
    })
    .state('auth', {
      template: require('./layout/main.view.html'),
    })
    .state('tv', {
      url: '/tv',
      template: '<div style="margin-left: 32px; margin-right: 32px" ui-view></div>',
    })
    .state('app.dashboard', {
      template: require('./dashboard/dashboard.view.html'),
      url: '/',
      title: 'Dashboard',
      controller: 'DashboardController',
      controllerAs: 'vm'
    })
    .state('app.dashboardenergy', {
      template: require('./dashboardenergy/dashboardenergy.view.html'),
      url: '/energy',
      controller: 'DashboardEnergyController',
      controllerAs: 'vm',
      params: {
        date: moment.utc()
      }
    })
    .state('app.dashboardwater2', {
      template: require('./dashboardwater2/dashboardwater2.view.html'),
      url: '/water2',
      controller: 'DashboardWater2Controller',
      controllerAs: 'vm',
      params: {
        date: moment.utc()
      }
    })
    .state('app.dashboardinstant', {
      template: require('./dashboardinstant/view.html'),
      url: '/instant',
      controller: 'DashboardInstantController',
      controllerAs: 'vm',
      params: {
        date: moment.utc()
      }
    })
    .state('app.dashboardsolar', {
      template: require('./dashboardsolar/view.html'),
      url: '/solar',
      controller: 'DashboardSolarController',
      controllerAs: 'vm',
      params: {
        date: moment.utc()
      }
    })
    .state('app.dashboardadmin', {
      template: require('./dashboardadmin/view.html'),
      url: '/dashadmin',
      controller: 'AdminDashboardController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.dashboardwater', {
      template: require('./dashboardwater/dashboardwater.view.html'),
      url: '/water',
      controller: 'DashboardWaterController',
      controllerAs: 'vm'
    })
    .state('app.dashboardtemp', {
      template: require('./dashboardtemp/view.html'),
      url: '/temperature',
      controller: 'DashboardTemperatureController',
      controllerAs: 'vm'
    })
    .state('app.dashboardswitch', {
      template: require('./switch/view.html'),
      url: '/switch',
      controller: 'DashboardSwitchController',
      controllerAs: 'vm'
    })
    .state('app.humidity', {
      template: require('./humidity/view.html'),
      url: '/humidity',
      controller: 'DashboardHumidityController',
      controllerAs: 'vm'
    })
    .state('app.condutivity', {
      template: require('./condutivity/view.html'),
      url: '/condutivity',
      controller: 'DashboardCondutivityController',
      controllerAs: 'vm'
    })
    .state('app.dashboardco2', {
      template: require('./dashboardco2/dashboardco2.view.html'),
      url: '/co2',
      controller: 'DashboardCo2Controller',
      controllerAs: 'vm'
    })
    .state('app.dashboarddoor', {
      template: require('./dashboarddoor/view.html'),
      url: '/doors',
      controller: 'DashboardDoorController',
      controllerAs: 'vm'
    })
    .state('app.dashboardev', {
      template: require('./dashboardev/view.html'),
      url: '/evs',
      controller: 'DashboardEVController',
      controllerAs: 'vm'
    })
    .state('app.profile', {
      template: require('./profile/profile.view.html'),
      url: '/profile?link',
      title: 'Perfil',
      controller: 'ProfileController',
      controllerAs: 'vm',
      role: ['$authenticated']
    });
}

routes.$inject = ['$stateProvider'];
