import { useFormHook } from "../../hooks";
import { isValidCodigoPostal, isValidEmail } from "../../utils";
const defaultValues = {
    Nome: "",
    CodigoOE: "",
    Morada: "",
    CodigoPostal: "",
    Localidade: "",
    EmailTecnico: "",
    Latitude: "",
    Longitude: "",
};
const validators = [
    {
        field: "Nome",
        validate: (instalacao) => instalacao.Nome.length >= 3,
        helperText: "Nome invalido.",
    },
    {
        field: "Morada",
        validate: (instalacao) => instalacao.Morada.length >= 5,
        helperText: "Morada invalida.",
    },
    {
        field: "Localidade",
        validate: (instalacao) => !instalacao.Localidade || instalacao.Localidade.length >= 3,
        helperText: "Localidade invalida.",
    },
    {
        field: "Latitude",
        validate: (instalacao) => !instalacao.Latitude || !Number.isNaN(parseFloat(instalacao.Latitude)),
        helperText: "Latitude invalida.",
    },
    {
        field: "Longitude",
        validate: (instalacao) => !instalacao.Longitude || !Number.isNaN(parseFloat(instalacao.Longitude)),
        helperText: "Longitude invalida.",
    },
    {
        field: "CodigoPostal",
        validate: (instalacao) => !instalacao.CodigoPostal || isValidCodigoPostal(instalacao.CodigoPostal),
        helperText: "Codigo Postal invalido, e.g: '0000-000'.",
    },
    {
        field: "EmailTecnico",
        validate: (instalacao) => !instalacao.EmailTecnico || isValidEmail(instalacao.EmailTecnico),
        helperText: "Email Tecnico invalido, e.g: 'name@provider.com'.",
    },
];
export const useInstalacao = (defaults = {}) => useFormHook(defaultValues, validators, defaults);
