import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
export const CustomDatePicker = (props) => {
    const { field, obj, handleChange, label, sx, hasError, getHelperText, maxDate, minDate, } = props;
    const middleHandleChange = (value) => {
        if (value != null)
            handleChange(field, value);
    };
    return (<DatePicker label={label} defaultValue={obj[field]} format="DD/MM/YYYY" sx={sx} onChange={(val) => middleHandleChange(val)} minDate={minDate} maxDate={maxDate} slotProps={{
            textField: {
                helperText: hasError && getHelperText && getHelperText(field),
            },
        }}/>);
};
