export default class EvChargerListController {
  constructor($state, EvChargerService, UIService) {
    this.$state = $state;
    this.Ev = EvChargerService;
    this.UI = UIService;
    this.loaded = false;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "desc",
      order: "name"
    };
    this.data = [];
    this.loadData();
  }

  loadData = () => {
    this.$state.go("app.ev.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Ev.list(this.createFilter()).then((r) => {
      r.data.forEach(d => {
        d.connectors = d.connectors.filter(c => c.id > 0)
      });
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      if (this.total > r.data.length) {
        this.hasNavigation = true;
      } else {
        this.hasNavigation = false;
      }
      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast("Não foi possivel carregar carregadores");
    });
  };

  add = () => {
    this.loaded = false;
    this.UI.showDialog({
      template: require('./add.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.items = [];
        $scope.loaded = false;
        $scope.data = {};

        $scope.ok = () => {
          $scope.$close($scope.data);
        }
        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }

        this.Ev.pending().then(r => {
          $scope.items = r;
          $scope.loaded = true;
        });
      }]
    }).then(r => {
      if (r) {
        this.Ev.adopt(r.evse.id, r.partNumber).then(_ => {
          this.UI.addToast("Carregador adicionado com sucesso");
          this.loadData();
        }).catch(e => {
          this.loaded = true;
          this.UI.addToast("Ocorreu um erro ao adicionar carregador");
        })
      }
    }).catch(e => { });
  }

  qolCalculator = (row) => {
    row.qol = row.qol || [];
    let count = row.qol.length;
    let ok = row.qol.filter(r => r.value == 1) || [];
    let t = (ok.length / count) * 100;
    if (isNaN(t)) {
      t = 100;
    }
    return Math.round((t + Number.EPSILON) * Math.pow(10, 0)) / Math.pow(10, 0);
  }

  qolIndicator = (row) => {
    let value = this.qolCalculator(row);
    if (value == 0) {
      return "mdi-network-strength-outline";
    } else if (value < 25) {
      return "mdi-network-strength-1";
    } else if (value < 50) {
      return "mdi-network-strength-2";
    } else if (value < 75) {
      return "mdi-network-strength-3";
    } else {
      return "mdi-network-strength-4";
    }
  }

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {
    };

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}d`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  reset = row => {
    this.UI.showConfirm('Deseja reiniciar o carregador? Irá terminar qualquer sesão de carga ativa.').then(_ => {
      if (_) {
        this.Ev.reset(row.id).then(r => {
          if (r.success) {
            this.UI.addToast("O carregador vai reiniciar...");
          } else {
            this.UI.addToast("Não foi possível reinicar o carregador");
          }
        }).catch(e => {
          this.UI.addToast("Não foi possível reinicar o carregador");
        });
      }
    });
  }

  update = (row) => {
    this.UI.showDialog({
      template: require('./update.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.dateFormat = { format: 'DD-MM-YYYY HH:mm', minDate: moment() }
        $scope.data = {
          url: "",
          setDate: false,
          date: moment().nearestNext15Min()
        }
        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(data => {
      if (data) {
        this.Ev.update(row.id, data.url, data.setDate ? data.date : undefined).then(r => {
          if (r.success == true) {
            this.UI.addToast(data.setDate ? `O carregador vai ser atualizado às ${data.date.format('DD-MM-YYYY [às] HH:mm')}` : "A atualizar carregador...");
          } else {
            this.UI.addToast("Não foi possível atualizar firmware do carregador");
          }
        }).catch(e => {
          this.UI.addToast("Não foi possível atualizar firmware do carregador");
        });
      }
    });
  }

  downloadConfig = row => {
    this.UI.showDialog({
      template: require('./config.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.title = "Descarregar configuração";
        $scope.loaded = false;
        this.Ev.getConfig(row.id).then(r => {
          $scope.data = r.data.configurationKey;
          $scope.loaded = true;
        }).catch(e => {
          $scope.$dismiss();
        })

        $scope.ok = () => { $scope.$close(); }
      }]
    }).then(r => { }).catch(e => {
      this.UI.addToast("Não foi possível obter configuração do carregador.");
    })
  }
}

EvChargerListController.$inject = ['$state', 'EvChargerService', 'UIService'];
