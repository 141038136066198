export default class BacklogController {
  constructor($state, UIService, Backlog) {
    this.$state = $state;
    this.UI = UIService;
    this.Backlog = Backlog;
    this.loaded = true;

    if (!$state.params.filter) {
      this.filter = {
        time: {
          from: moment().subtract(7, "day").startOf("day"),
          to: moment().endOf("day"),
        },
        page: 1
      };
    } else {
      this.filter = $state.params.filter;
    }

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    let query = {
      filter: {
        where: {
          timestamp: {
            between: [this.filter.time.from, this.filter.time.to]
          }
        },
        include: 'user',
        order: "timestamp DESC",
      },
    }
    this.Backlog.count(query.filter.where).$promise.then((c) => {
      this.Backlog.find(query).$promise.then((r) => {
        this.total = c.count;
        this.data = r;
        this.start = 1 || 0;
        this.end = r.length || 0;
        this.loaded = true;
      });
    });
  };
}

BacklogController.$inject = ['$state', 'UIService', 'Backlog'];
