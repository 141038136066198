import { useFormHook } from "../../hooks";
import { isValidEmail } from "../../utils";
const defaultValues = {
    Nome: "",
    Email: "",
};
const validators = [
    {
        field: "Nome",
        validate: (conta) => conta.Nome.length > 5,
        helperText: "Nome invalido.",
    },
    {
        field: "Email",
        validate: (conta) => isValidEmail(conta.Email),
        helperText: "Email invalido, e.g: 'name@provider.com'.",
    },
];
export const useConta = (defaults = {}) => useFormHook(defaultValues, validators, defaults);
