import angular from 'angular';

export default angular.module('app.interface.select', [])
  .directive('pmdUiSelect', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: (scope, el, attrs) => {
        let wasNeverOpened = true;
        let label = null;
        el.addClass('pmd-textfield pmd-textfield-floating-label');
        el.children().each(c => {
          if (el.children()[c].localName === 'label') {
            label = el.children()[c];
          }
          if (el.children()[c].localName === 'ui-select') {
            if (label !== null) {
              el.bind('blur', () => {
                let hasData = angular.isDefined(angular.element(el.children()[c]).scope().$select.selected);
                if (hasData) {
                  label.style['-webkit-transform'] = 'translateY(0px)';
                  label.style['-ms-transform'] = 'translateY(0px)';
                  label.style['transform'] = 'translateY(0px)';
                  label.style['font-size'] = '14px';
                  label.style['color'] = 'rgba(0, 0, 0, .54)';
                } else {
                  label.style['-webkit-transform'] = 'translateY(26px)';
                  label.style['-ms-transform'] = 'translateY(26px)';
                  label.style['transform'] = 'translateY(26px)';
                  label.style['font-size'] = '16px';
                  label.style['color'] = 'rgba(0, 0, 0, .4)';
                }
              }
              );
              $timeout(() => {
                scope.$watch(function () {
                  return angular.element(el.children()[c]).scope().$select.selected;
                }, function (data) {
                  if (angular.isUndefined(data)) {
                    label.style['-webkit-transform'] = 'translateY(26px)';
                    label.style['-ms-transform'] = 'translateY(26px)';
                    label.style['transform'] = 'translateY(26px)';
                    label.style['font-size'] = '16px';
                    label.style['color'] = 'rgba(0, 0, 0, .4)';
                  } else if (angular.isDefined(data) && wasNeverOpened) {
                    label.style['-webkit-transform'] = 'translateY(0px)';
                    label.style['-ms-transform'] = 'translateY(0px)';
                    label.style['transform'] = 'translateY(0px)';
                    label.style['font-size'] = '14px';
                    label.style['color'] = 'rgba(0, 0, 0, .54)';
                  }
                });
                scope.$watch(function () {
                  return angular.element(el.children()[c]).scope().$select.open;
                }, function (isOpen) {
                  let selected = angular.isDefined(angular.element(el.children()[c]).scope().$select.selected);
                  if (isOpen) {
                    el.addClass('pmd-textfield-floating-label-active');
                    wasNeverOpened = false;
                    label.style['-webkit-transform'] = 'translateY(0px)';
                    label.style['-ms-transform'] = 'translateY(0px)';
                    label.style['transform'] = 'translateY(0px)';
                    label.style['font-size'] = '14px';
                    label.style['color'] = 'rgba(0, 0, 0, .54)';
                  } else {
                    el.removeClass('pmd-textfield-floating-label-active');
                    if (selected) {
                      label.style['-webkit-transform'] = 'translateY(0px)';
                      label.style['-ms-transform'] = 'translateY(0px)';
                      label.style['transform'] = 'translateY(0px)';
                      label.style['font-size'] = '14px';
                      label.style['color'] = 'rgba(0, 0, 0, .54)';
                    } else {
                      label.style['-webkit-transform'] = 'translateY(26px)';
                      label.style['-ms-transform'] = 'translateY(26px)';
                      label.style['transform'] = 'translateY(26px)';
                      label.style['font-size'] = '16px';
                      label.style['color'] = 'rgba(0, 0, 0, .4)';
                    }
                  }
                });
              });
            }
          }
        }
        );
      }
    }
  }])
  .directive('pmdSelect', ['$compile', function ($compile) {
    return {
      restrict: 'E',
      require: '?ngModel',
      scope: {
        options: '=',
        show: '@',
        label: '@',
        disable: '=?'
      },
      link: (scope, element, attrs, ngModel) => {

        scope.disable = scope.disable || false;

        scope.floating = angular.isDefined(attrs.floating) ?
          scope.$parent.$eval(attrs.floating) : false;

        //Check for disableDelete param... look at this code . WAT
        scope.noDel = (attrs.$attr.disableDelete === 'disable-delete');

        scope.$watch(function () {
          return ngModel.$modelValue;
        }, function (value) {
          if (scope.options) {
            let found = -1;
            for (let i = 0; i < scope.options.length; i++) {
              let o = scope.options[i];
              if (angular.equals(o.id, value.id)) {
                found = i;
                break;
              }
            }
            if (found === -1) {
              scope.clear();
            } else {
              scope.select(found);
            }
          }
        });

        if (ngModel.$viewValue) {
          scope.options.forEach(o => {
            if (angular.equals(o, ngModel.$viewValue)) {
              scope.selected = o[scope.show];
            }
          })
        } else {
          scope.selected = '';
        }

        scope.select = function (idx) {
          let select = scope.options[idx];
          if (select) {
            scope.selected = select[scope.show];
            ngModel.$setViewValue(select);
            ngModel.$render();
          }
        };

        scope.isSelected = function (idx) {
          if (scope.selected === '')
            return false;
          return angular.equals(ngModel.$viewValue, scope.options[idx]);
        };

        scope.hasSelection = function () {
          return scope.selected !== '';
        };

        scope.clear = function () {
          scope.selected = '';
          ngModel.$setViewValue(undefined);
          ngModel.$render();
        };


        let template =
          '<div class="pmd-textfield" ng-class="{\'pmd-textfield-floating-label\': floating, \'pmd-textfield-floating-label-completed\': floating && hasSelection() }">\n' +
          '<label ng-bind="label"></label>\n' +
          '<input type="hidden" ng-model="' + attrs.ngModel + '"/>\n' +
          '<input type="text" ng-disabled="disable" ng-value="selected" readonly data-toggle="dropdown" class="noselect form-control dropdown-toggle"><span class="pmd-textfield-focused"></span>\n' +
          '<button ng-if="hasSelection() && !noDel" ng-click="clear()" class="btn btn-xs pmd-btn-fab pmd-btn-flat pmd-ripple-effect btn-default" type="button"><i class="material-icons pmd-xs">clear</i></button>\n' +
          '  <ul class="dropdown-menu pmd-d">\n' +
          '    <li ng-if="selected === \'\'" class="disabled"><a>Escolha uma opção</a></li>\n' +
          '    <li ng-repeat="r in options" ng-click="select($index)" ng-class="{\'active\': isSelected($index)}"><a ng-bind-html="r[show] | nl2br"></a></li>\n' +
          '</ul>';

        element.replaceWith($compile(template)(scope));
      }
    }
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Select',
      description: 'Dropdown list element',
      version: '1.1.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
