export function routes($stateProvider) {
  $stateProvider
    .state('app.smartmeter', {
      url: '/smartmeter',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.smartmeter.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
      template: require('./list/view.html'),
      controller: 'SmartmeterListController',
      controllerAs: 'vm',
      role: ['smartmeterList'],
    }).state('app.smartmeter.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'SmartmeterDetailsController',
      controllerAs: 'vm',
      role: ['smartmeterEdit'],
    }).state('app.smartmeter.add', {
      url: '/add?{collectorId:string}',
      template: require('./details/view.html'),
      controller: 'SmartmeterDetailsController',
      controllerAs: 'vm',
      role: ['smartmeterAdd'],
    }).state('app.smartmeter.registers', {
      url: '/{id}/registers?{from:string}&{to:string}',
      template: require('./registers/view.html'),
      controller: 'SmartmeterRegisterController',
      controllerAs: 'vm',
    }).state('app.smartmeter.leituras', {
      url: '/{id}/leituras?{from:string}&{to:string}&{page:int}&{order:string}&{sort:string}',
      template: require('./leituras/view.html'),
      controller: 'SmartmeterLeiturasController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
