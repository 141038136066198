export default class DashboardEVController {
  constructor($interval, $scope, EvChargerService, DashboardEnergy, $timeout) {
    this.$interval = $interval;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.Ev = EvChargerService;
    this.DashboardEnergy = DashboardEnergy;
    this.interval = $interval(() => {
      this.getData();
    }, 1000 * 60);

    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
    });
    this.getData();
  }

  // Get data by charger connectors (For gauge)
  getData = () => {
    this.Ev.list({
      where: {
        active: true
      }
    }).then(r => {
      this.evs = [];
      r.data.forEach(charger => {
        charger.connectors.forEach(connector => {
          if (connector.id > 0) {
            let c = angular.copy(connector);
            c.charger = charger;
            this.evs.push(c);
          }
        });
      });
      this.createConnectorData(this.evs);
      this.evsLoaded = true;
    });
    this.DashboardEnergy.last24hrs().then(r => {
      this.createDay(r);
      this.aggregatesLoaded = true;
    });
    this.Ev.dashboardEV().then(r => {
      this.calcFootprint(r.wh);
      this.calcMonthlyRevenue(r);
    });
    this.Ev.getGroups().then(r => {
      this.groups = r;
      this.groups.forEach(g => {
        this.getBalanceData(g);
      });
    });
    this.Ev.getLastSessions().then(r => {
      this.evSessions = r;
      this.evSessionsLoaded = true;
    });
  }

  getBalanceData = group => {
    this.Ev.getLoadFromGroup(group.id).then(r => {
      this.createLoadGroupData(group, r);
    });
  }

  // Auxiliar methods for EV
  isAvailable = (row) => row.status.includes("Available");

  isCharging = (row) => row.status.includes("Charging");

  isOcuppied = (row) => row.status.includes("Preparing") || row.status.includes("Finishing") || row.status.includes("SuspendedEV") || row.status.includes("SuspendedEVSE");

  isUnavailable = (row) => row.status.includes("Unavailable") || row.status.includes("Reserved") || row.charger.online == false;

  isWithError = (row) => row.status.includes("Faulted");

  // Gauge label information
  setLabelForGauge = (row) => {
    if (row.charger.online == false) {
      return "";
    }
    if (this.isCharging(row)) {
      if (row.hasOwnProperty("delivered") && row.delivered.hasOwnProperty("wh"))
        return `${(row.delivered.wh / 1000).toFixed(1).replace(".", ",")} kWh`;
      return `0.0 kWh`;
    } else {
      return ""
    }
  }

  // Gauge value
  setGaugeValue = (row) => {
    if (row.charger.online == false)
      return 0;
    if (this.isCharging(row)) {
      if (row.hasOwnProperty("charging") && row.charging.hasOwnProperty("w"))
        return (row.charging.w / 1000).toFixed(1);
      return 0.0;
    }
    if (this.isWithError(row)) {
      row.max = 0.001;
      return 0.001;
    }
    return 0;
  }

  // Gauge charging colors based on connector status
  setChargingColor = (row) => {
    if (this.isCharging(row)) {
      if (row.limit && row.limit > 0) {
        return "#512da8";
      } else {
        return "#4285f4";
      }
    }
    if (this.isOcuppied(row)) {
      return "#C79A00";
    }
    if (this.isWithError(row)) {
      return "#b71013";
    }

    if (this.isUnavailable(row)) {
      return "#000000";
    }
    return "#4285f4";
  }

  // Gauge bottom information
  connectorStatus = row => {
    if (row.charger.online == false) {
      return "Offline";
    }
    if (this.isCharging(row)) {
      if (this.isCharging(row) && row.charging.hasOwnProperty("soc") && row.charging.soc > 0) {
        return `A carregar (${row.charging.soc}%)`;
      }
      return "A carregar";
    }
    if (this.isOcuppied(row)) {
      return "Ocupado";
    }
    if (this.isWithError(row)) {
      return "Com erro";
    }
    if (this.isUnavailable(row)) {
      return "Indisponível para carga";
    }
    return "Disponível para carga";
  }

  createDay = (origin) => {
    let series = ["Rede", "EV"];
    let colors = ["#AA3033", "#4285f4"];
    let tints = ["#EAC5C6BF", "#C4B5E9BF"];

    let dayData = this.createDayData(origin, colors, tints);
    this.getAggregates(dayData);
  }

  // Outputs the consumption for today
  getAggregates = (dayData) => {
    let dayGrid = 0;
    let costGrid = 0;
    let dayEV = 0;
    let costEV = 0;

    let indexGrid = 0;
    let indexEV = 1;

    if (dayData && dayData.data.length === 2) {
      for (let i = 0; i < dayData.colorIndex; i++) {
        // Grid
        dayGrid += dayData.data[indexGrid][i];
        costGrid += dayData.cost[indexGrid][i];
        // EV consumption
        dayEV += dayData.data[indexEV][i];
        costEV += dayData.cost[indexEV][i];
      }
    }
    this.totalGrid = Number.parseFloat(dayGrid.toFixed(3));
    this.costGrid = Number.parseFloat(costGrid.toFixed(2));

    this.totalEV = Number.parseFloat(dayEV.toFixed(3));
    this.costEV = Number.parseFloat(costEV.toFixed(2));

    if (this.totalEV > 0) {
      // Find ration between consumption and EV consumption
      this.consumedEV = 100 - ((this.totalGrid - this.totalEV) / this.totalGrid * 100);
    } else {
      this.consumedEV = 0;
    }
  }

  // Output an object with data for chart and other relevant info for background colors
  createDayData = (origin, colors, tints) => {
    let d = [[], []]; // For values and their costs
    let c = [[], []];
    let colorIndex = 0;
    for (let i = 0; i < origin.length; i++) {
      let m = moment.utc(origin[i].date);
      d[0][m.hour()] = origin[i].consumption;
      c[0][m.hour()] = origin[i].costConsumption;

      d[1][m.hour()] = origin[i].ev;
      c[1][m.hour()] = origin[i].costEV;
    }

    // If we don't have data for this hour, use old data
    if (moment().minute() < 15) {
      let m = moment.utc(origin[origin.length - 1].date);
      d[0][m.hour()] = origin[0].consumption;
      d[1][m.hour()] = origin[0].ev;

      c[0][m.hour()] = origin[0].costConsumption;
      c[1][m.hour()] = origin[0].costEV;
      colorIndex = m.hour();
    } else {
      colorIndex = moment.utc(origin[origin.length - 1].date).hour() + 1;
    }

    let backgroundColors = [[], [], []];
    for (let i = 0; i < 24; i++) {
      if (i < colorIndex) {
        backgroundColors[0][i] = colors[0];
        backgroundColors[1][i] = colors[1];
      } else {
        backgroundColors[0][i] = tints[0];
        backgroundColors[1][i] = tints[1];
      }
    }

    return {
      data: d,
      cost: c,
      backgroundColors: backgroundColors,
      colorIndex: colorIndex
    }
  };

  createConnectorData = (connectors) => {
    let labels = ["Disponível", "Ocupado", "A carregar", "Outros"];
    let colors = ["#43a047", "#c79a00", "#4285f4", "#b0bec5"];
    let available = 0, occupied = 0, charging = 0, others = 0;
    connectors.forEach(c => {
      if (c.status.includes("Unavailable") || c.status.includes("Faulted") || c.charger.online == false) {
        others++;
      } else {
        if (c.status.includes("Available")) {
          available++;
        }
        else if (this.isOcuppied(c)) {
          occupied++;
        }
        else if (this.isCharging(c)) {
          charging++;
        }
      }
    });
    let data = [available, occupied, charging, others];
    let datasets = [];
    data.forEach((r, i) => {
      datasets.push({
        label: labels[i],
        data: data[i],
        backgroundColor: colors[i],
        borderColor: colors[i],
        hoverBackgroundColor: colors[i],
        hoverBorderColor: colors[i],
        borderWidth: 0,
        fill: true,
      });
    });
    this.connectorData = {
      labels: labels,
      colors: ["#43a047", "#c79a00", "#4285f4", "#b0bec5"],
      data: data,
      datasets: datasets,
      options: {
        elements: {
        },
        cutoutPercentage: 80,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        },
      },
    };
    this.connectorAmount = {
      total: connectors.length,
      charging: charging,
      available: available,
      occupied: occupied,
      others: others
    }
    this.connectorStatusLoaded = true;
    this.$timeout(() => {
      this.showChartLegend = true;
    }, 1200);
  }

  calcFootprint = (wh) => {
    let kwh = wh / 1000;
    this.totalKwh = kwh;
    // Valores retirados de https://travelifestaybetter.com/wp-content/uploads/2019/02/17-Fuel-Conversion-Rates-to-kWh-and-CO2e.pdf
    this.litersSaved = kwh / 3.3; // 1L gasóleo ~= 10.96 kWh
    this.CO2Saved = (kwh / 3.3) * 2.68779; // 1L gasóleo = 2.68779 kg CO2
    this.footprintLoaded = true;
  }

  calcMonthlyRevenue = (data) => {
    this.totalTransactions = data.total;
    this.totalHours = data.hours;
    this.totalCost = data.cost;
    this.transactionsLoaded = true;
  }

  createLoadGroupData = (group, info) => {
    let limit = group.power + 2;
    let step = Math.ceil(limit / 3);
    limit = group.power + step;
    info.data = [info.data];
    let dataset = [];
    info.data.forEach((data) => {
      /*
      let min = 0;
      let max = group.power;
      data.forEach((p, i) => {
        data[i] = Math.floor(Math.random() * (max - min + 1) + min);
      });
      */
      dataset.push({
        backgroundColor: "#c79a00",
        borderColor: "#c79a00",
        borderWidth: 1,
      });
    });
    group.chart = {
      data: info.data,
      series: info.series,
      labels: info.labels,
      datasets: dataset,
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return `${Number(tooltipItem.yLabel)}A (${(Number(tooltipItem.yLabel) / group.power * 100).toFixed(1)})%)`
            }
          }
        },
        lines: [{
          axis: 'y',
          value: group.power,
          dashed: [10, 5],
          style: "rgba(0, 0, 0, .84)",
          text: "Limite rede",
          width: 1,
        }],
        scales: {
          xAxes: [
            {
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              beginAtZero: true,
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: limit,
                stepSize: step,
              },
              stacked: true
            },
          ]
        },
        legend: false
      }
    }
  }
}

DashboardEVController.$inject = ["$interval", "$scope", "EvChargerService", "DashboardService", "$timeout"];
