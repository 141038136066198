import { Box, FormControlLabel, FormGroup, Stack, Tab, Tabs, Typography, } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, CustomDatePicker, CustomSwitch, CustomTextField, } from "../utils/CustomComponents";
const LicenciamentoForm = ({ forceShowErrors, state: { getErr, hasErr, isValid, setField, values: licenciamento }, }) => {
    const [tab, setTab] = useState(0);
    const hasError = (field) => hasErr(field, forceShowErrors);
    const getError = (field) => getErr(field, forceShowErrors);
    return (<div>
      <Typography sx={{ mt: 1, mb: 1 }}>
        Passo 3 - Criar Licenciamento
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <Tab label="Licenciamento"/>
          <Tab label="Potências"/>
          <Tab label="Opções adicionais"/>
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Stack spacing={1}>
          <div style={{ display: "flex" }}>
            <CustomDatePicker obj={licenciamento} field="DataInicio" maxDate={licenciamento.DataFim} handleChange={setField} label="Data de início" sx={{ width: "50%", marginRight: "10px" }} hasError={hasError} getHelperText={getError}/>
            <CustomDatePicker obj={licenciamento} field="DataFim" minDate={licenciamento.DataInicio} handleChange={setField} label="Data de fim" sx={{ width: "50%" }} hasError={hasError} getHelperText={getError}/>
          </div>
          <FormGroup>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Energia" handleChange={setField}/>} label="Energia"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Solar" handleChange={setField}/>} label="Solar"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="ParciaisEnergia" handleChange={setField}/>} label="Parciais energia"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Agua" handleChange={setField}/>} label="Água"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="CO2" handleChange={setField}/>} label="CO₂"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Temperatura" handleChange={setField}/>} label="Temperatura"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Portas" handleChange={setField}/>} label="Portas"/>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="EV" handleChange={setField}/>} label="EV"/>
          </FormGroup>
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Stack spacing={1}>
          <Typography fontSize={"1.1rem"} fontWeight={500}>
            Potências Instantâneas
          </Typography>
          <Typography fontSize={"14px"} lineHeight={0.8} sx={{ opacity: 0.54 }} component="div">
            Usar feeds de <b>potência</b> e não de energia!
          </Typography>
          <div style={{ display: "flex", marginTop: "16px" }}>
            <CustomTextField obj={licenciamento} field="feedRedeId" handleChange={setField} label="ID do feed da Rede" sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomTextField obj={licenciamento} field="feedRedeCapacity" handleChange={setField} label="Capacidade Máxima (W)" required sx={{ width: "50%" }}/>
          </div>
          <div style={{ display: "flex" }}>
            <CustomTextField obj={licenciamento} field="feedProdId" handleChange={setField} label="ID do feed de Produção" sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomTextField obj={licenciamento} field="feedProdCapacity" handleChange={setField} label="Capacidade Máxima (W)" required sx={{ width: "50%" }}/>
          </div>
          <div style={{ display: "flex" }}>
            <CustomTextField obj={licenciamento} field="feedExcedId" handleChange={setField} label="ID do feed de Excedente" sx={{ width: "50%", marginRight: "10px" }}/>
            <CustomTextField obj={licenciamento} field="feedExcedCapacity" handleChange={setField} label="Capacidade Máxima (W)" required sx={{ width: "50%" }}/>
          </div>
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Stack spacing={1}>
          <FormGroup>
            <FormControlLabel control={<CustomSwitch obj={licenciamento} field="Acerto" handleChange={setField}/>} label="Acerto quarto-horário?"/>
          </FormGroup>
        </Stack>
      </TabPanel>
    </div>);
};
export default LicenciamentoForm;
