import angular from 'angular';
import {Calendar} from "fullcalendar";
import * as moment from "moment";
require('fullcalendar/dist/fullcalendar.css');
require('webpack-jquery-ui/draggable');

export default angular.module('app.interface.calendar', [])
  .directive('pmdCalendar', [() => {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        options: '=options',
        api: '=?api'
      },
      link: (scope, element, attrs, ngModel) => {

        let calendar;

        function onInit(date)  {
          let options = {
            buttonText: {
              today: 'Hoje',
              month: 'Mensal',
              week: 'Semanal',
              day: 'Diário',
              list: 'Lista'
            },
            locale: 'pt',
            themeSystem: 'bootstrap4',
            bootstrapFontAwesome: {
              close: ' mdi mdi-close',
              prev: ' mdi mdi-chevron-left',
              next: ' mdi mdi-chevron-right'
            },
            events: ngModel.$viewValue
          };
          angular.extend(options, scope.options);
          calendar = new Calendar(element, options);
          if (angular.isDefined(attrs.api)) {
            scope.api = calendar;
          }
          calendar.render();
          calendar.gotoDate(date);
        }

        onInit(moment.utc());

        scope.$watch(() => {
          return ngModel.$viewValue;
        }, () => {
          calendar.removeEventSources();
          if (ngModel.$viewValue)
            calendar.addEventSource(ngModel.$viewValue);
        }, true);
      }
    }
  }])
  .directive('draggable', () => ({
    restrict: 'A',
    scope: {},
    link: (scope, elem) => {
      elem.draggable({
        zIndex: 999,
        revert: true,      // will cause the event to go back to its
        revertDuration: 0  //  original position after the drag
      });
    }
  }))
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Calendar',
      description: 'Calendar view with events',
      version: '1.1.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
