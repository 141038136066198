export default class SiteService {
  constructor($q, AuthenticationService, Site, $http, Register, RegisterHour) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Site = Site;
    this.$http = $http;
    this.Register = Register;
    this.RegisterHour = RegisterHour;
  }

  modules = [
    "Energia",
    "Solar",
    "Parciais energia",
    "Água",
    "CO₂",
    "Temperatura",
    "Portas",
    "EV",
  ];

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    let filterHardware = filter;
    if (!filterHardware) {
      filterHardware = {
        where: {},
      };
    }
    //filter correct entity
    filterHardware.where.entityId = user.selected.entityId;

    this.Site.count({
      where: filterHardware.where,
    })
      .$promise.then((c) => {
        this.Site.find({
          filter: filterHardware,
        })
          .$promise.then((r) => {
            return defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Site.findById({
      id: id,
      filter: {
        include: ["aggregators", "locations", "chargers", "solarInstaller"],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  getPhoto = (file, container) => {
    let defer = this.$q.defer();
    this.$http
      .get(
        "api/assets/containers/" + container + "/files/" + file + "/download",
        { responseType: "blob" }
      )
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    if (!data.entityId) data.entityId = user.selected.entityId;
    this.Site.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  admin = () => {
    return this.Site.find({
      filter: {
        include: "entity",
        where: {
          active: true,
        },
      },
    }).$promise;
  };

  last24hrs = (site) => {
    return this.RegisterHour.dashboard24hrs({
      siteId: site,
      date: moment.utc().toISOString(),
    }).$promise;
  };

  registeredPotency = (id) => {
    let defer = this.$q.defer();
    this.Site.find({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => {
        defer.resolve(r.filter((x) => x.potency));
      })
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  getDayProduction = (site) => {
    return this.Register.dayProduction({
      siteId: site,
      date: moment.utc().toISOString(),
    }).$promise;
  };

  dashboardConsumptionYear = (site, measureId, liquidProduction) => {
    return this.Register.dashboardConsumptionYear({
      siteId: [site],
      measureId: measureId,
      liquidProduction: liquidProduction || false,
    }).$promise;
  };

  solarData = (site) => {
    return this.Register.solarData({
      siteId: site,
    }).$promise;
  };
}

SiteService.$inject = [
  "$q",
  "AuthenticationService",
  "Site",
  "$http",
  "Register",
  "RegisterHour",
];
