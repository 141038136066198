"use strict";

let am4core = require("@amcharts/amcharts4/core");
let am4charts = require("@amcharts/amcharts4/charts");
import pt_PT from "@amcharts/amcharts4/lang/pt_PT";

export default angular.module("chart", []).directive("chart", function () {
  return {
    restrict: "E",
    scope: {
      data: "=",
    },
    template: "<div id='chartdiv'></div>",
    replace: true,
    link: function ($scope, element, attrs) {
      var chart = am4core.create("chartdiv", am4charts.XYChart);

      $scope.$watch(
        () => {
          return $scope.data;
        },
        (data) => {
          chart.dispose();
          chart = am4core.create("chartdiv", am4charts.XYChart);
          chart.language.locale = pt_PT;

          $scope.render();
        }
      );

      $scope.render = () => {
        // Set up data source
        chart.maxValue = $scope.data.max;
        chart.data = $scope.data.map((r) => {
          return {
            value: r.value,
            date: new Date(r.date),
          };
        });
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0.5;
        dateAxis.renderer.minGridDistance = 50;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.max = chart.maxValue;

        // Create series
        function createSeries(field, name) {
          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = field;
          series.dataFields.dateX = "date";
          series.name = name;
          series.tooltipText = "[b]{valueY}[/]";
          series.strokeWidth = 2;

          /*var bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.stroke = am4core.color("#fff");
          bullet.circle.strokeWidth = 2;*/
          // Add scrollbar
          chart.scrollbarX = new am4charts.XYChartScrollbar();
          chart.scrollbarX.series.push(series);
        }

        createSeries("value", "Series #1");

        chart.cursor = new am4charts.XYCursor();
      };

      $scope.$on("$destroy", function () {
        chart.dispose();
      });
    },
  };
}).name;
