const AdaptiveDashboardWidget = require('./widget');

class AdaptiveDashboardInstant extends AdaptiveDashboardWidget {
  constructor(widget, AdaptiveDashboardService, $timeout) {
    super(widget, AdaptiveDashboardService, $timeout);
    this.widget.instants = new Array(this.widget.smartmeters.length);
    for (let i = 0; i < this.widget.instants.length; i++) {
      this.widget.instants[i] = {isLoaded: false};
    }
    this.loadDatav2(this.widget.smartmeters);
    this.widget.instant_size = Math.floor(12/(this.widget.serialized.size_x/1.5));
    //TODO refresh data on interval
  }

  getCapacity = (smartmeter, defaultValue) => {
    if (smartmeter.features.length === 1 && smartmeter.features[0].capacity)
      return smartmeter.features[0].capacity;
    else if (smartmeter.capacity)
      return smartmeter.capacity;
    else
      return this.getCapacityOverride(defaultValue);
  }

  getCapacityOverride = (defaultValue) => this.getOverride("capacity", defaultValue);
  getUnitOverride = () => this.getOverride("unit", this.widget.unit.unit);
  getAppendOverride = () => this.getOverride("append", this.getUnitOverride());
  getFractionSizeOverride = () => this.getOverride("fractionSize", 0);

  getfeedId = (smartmeter) => {
    let feedId = undefined;
    if (smartmeter.features.length === 1)
      feedId = parseInt(smartmeter.features[0].feedId);
    if (feedId === NaN)
      return ;
    return feedId;
  }

  loadDatav2 = (smartmeters) => {
    this.widget.instants = new Array(this.widget.smartmeters.length);
    for (let i = 0; i < this.widget.instants.length; i++) this.widget.instants[i] = {isLoaded: false};
    Promise.allSettled(smartmeters.map((meter) => this.Service.instant(this.getfeedId(meter)))).then((results) => {
      this.$timeout(() => {
        results.forEach((result, index) => {
          const smartmeter = this.widget.smartmeters[index];
          switch (result.status) {
            case "fulfilled":
              this.widget.instants[index] = this.getData(smartmeter, result.value);
              break;
            default:
              break;
          }
        });
        this.widget.loaded = true;
      });
    })
  }

  getData = (smartmeter, data) => {
    const p = {};
    p.value = Number(data.value);
    if (this.widget.measureId == "60acdc1c0b903917588ec458") p.value = p.value / 1000;
    p.time = moment.unix(data.time);
    p.label = smartmeter.name;
    let timeCheck = Math.abs(moment().diff(p.time, 'hour'));
    if (timeCheck > 0) {
      // Com erro
      p.isLoaded = true;
      p.error = true;
      p.type = "Leitura inválida";
    } else {
      p.isLoaded = true;
    }
    p.append = this.getAppendOverride();
    p.fractionSize = this.getFractionSizeOverride();
    p.color = this.Service.getColor(this.widget.measureId);
    p.capacity = this.getCapacity(smartmeter, p.value);
    return p;
  }


}
module.exports = AdaptiveDashboardInstant;
