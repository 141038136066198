 import angular from 'angular';

export default angular.module('app.interface.tabs', [])
      .controller('pmdTabsetController', ['$scope', function ($scope) {
    var ctrl = this,
      oldIndex;
    ctrl.tabs = [];

    this.select = (index, evt) => {
      if (!destroyed) {
        let previousIndex = findTabIndex(oldIndex);
        let previousSelected = ctrl.tabs[previousIndex];
        if (previousSelected) {
          previousSelected.tab.onDeselect({
            $event: evt,
            $selectedIndex: index
          });
          if (evt && evt.isDefaultPrevented()) {
            return;
          }
          previousSelected.tab.active = false;
        }

        let selected = ctrl.tabs[index];
        if (selected) {
          selected.tab.onSelect({
            $event: evt
          });
          selected.tab.active = true;
          ctrl.active = selected.index;
          oldIndex = selected.index;
        } else if (!selected && angular.isDefined(oldIndex)) {
          ctrl.active = null;
          oldIndex = null;
        }
      }
    };

    this.addTab = tab => {
      ctrl.tabs.push({
        tab: tab,
        index: tab.index
      });
      ctrl.tabs.sort((t1, t2) => {
        if (t1.index > t2.index) {
          return 1;
        }

        if (t1.index < t2.index) {
          return -1;
        }

        return 0;
      });

      if (tab.index === ctrl.active || !angular.isDefined(ctrl.active) && ctrl.tabs.length === 1) {
        const newActiveIndex = findTabIndex(tab.index);
        ctrl.select(newActiveIndex);
      }
    };

    ctrl.removeTab = tab => {
      let index;
      for (let i = 0; i < ctrl.tabs.length; i++) {
        if (ctrl.tabs[i].tab === tab) {
          index = i;
          break;
        }
      }

      if (ctrl.tabs[index].index === ctrl.active) {
        const newActiveTabIndex = index === ctrl.tabs.length - 1 ?
          index - 1 : index + 1 % ctrl.tabs.length;
        ctrl.select(newActiveTabIndex);
      }

      ctrl.tabs.splice(index, 1);
    };

    $scope.$watch('tabset.active', val => {
      if (angular.isDefined(val) && val !== oldIndex) {
        ctrl.select(findTabIndex(val));
      }
    });

    let destroyed;
    $scope.$on('$destroy', () => {
      destroyed = true;
    });

    function findTabIndex(index) {
      for (var i = 0; i < ctrl.tabs.length; i++) {
        if (ctrl.tabs[i].index === index) {
          return i;
        }
      }
    }
  }])
  .directive('pmdTabset', () => ({
    transclude: true,
    replace: true,
    scope: {},
    bindToController: {
      active: '=?',
    },
    controller: 'pmdTabsetController',
    controllerAs: 'tabset',
    template: () => "" +
      "<div class=\"pmd-card\" ng-class=\"{'pmd-z-depth': hasDepth }\">\n" +
      "  <div class=\"pmd-tabs\" ng-class=\"{'pmd-tabs-bg': background}\">\n" +
      "    <ul class=\"nav nav-tabs\" ng-class=\"{'nav-justified': justified}\" ng-transclude></ul>\n" +
      "  </div>\n" +
      "  <div class=\"pmd-card-body\">\n" +
      "    <div class=\"tab-content\">\n" +
      "      <div class=\"tab-pane\"\n" +
      "        ng-repeat=\"tab in tabset.tabs\"\n" +
      "        ng-class=\"{active: tabset.active === tab.index}\"\n" +
      "        pmd-tab-content-transclude=\"tab\">\n" +
      "      </div>\n" +
      "    </div>\n" +
      "  </div>\n" +
      "</div>",
    link: function (scope, element, attrs) {
      scope.background = angular.isDefined(attrs.background) ?
        scope.$parent.$eval(attrs.background) : false;
      scope.vertical = angular.isDefined(attrs.vertical) ?
        scope.$parent.$eval(attrs.vertical) : false;
      scope.justified = angular.isDefined(attrs.justified) ?
        scope.$parent.$eval(attrs.justified) : false;
      scope.hasDepth = angular.isDefined(attrs.noDepth) ?
        scope.$parent.$eval(attrs.noDepth) : true;
    }
  }))
  .directive('pmdTab', ['$parse', $parse => ({
    require: '^pmdTabset',
    replace: true,
    template: () => "" +
      "<li ng-class=\"[{active: active, disabled: disabled}, classes]\" class=\"pmd-tab nav-item\">\n" +
      "  <a href ng-click=\"select($event)\" class=\"nav-link\" pmd-tab-heading-transclude>{{heading}}</a>\n" +
      "</li>",
    transclude: true,
    scope: {
      heading: '@',
      index: '=?',
      classes: '@?',
      onSelect: '&select',
      onDeselect: '&deselect'
    },
    controller: () => {},
    controllerAs: 'tab',
    link: (scope, elm, attrs, tabsetCtrl, transclude) => {
      scope.disabled = false;
      if (attrs.disable) {
        scope.$parent.$watch($parse(attrs.disable), value => {
          scope.disabled = !!value;
        });
      }

      if (angular.isUndefined(attrs.index)) {
        if (tabsetCtrl.tabs && tabsetCtrl.tabs.length) {
          scope.index = Math.max.apply(null, tabsetCtrl.tabs.map(t => t.index)) + 1;
        } else {
          scope.index = 0;
        }
      }

      if (angular.isUndefined(attrs.classes)) {
        scope.classes = '';
      }

      scope.select = evt => {
        if (!scope.disabled) {
          let index;
          for (let i = 0; i < tabsetCtrl.tabs.length; i++) {
            if (tabsetCtrl.tabs[i].tab === scope) {
              index = i;
              break;
            }
          }

          tabsetCtrl.select(index, evt);
        }
      };

      tabsetCtrl.addTab(scope);
      scope.$on('$destroy', () => {
        tabsetCtrl.removeTab(scope);
      });

      //We need to transclude later, once the content container is ready.
      //when this link happens, we're inside a tab heading.
      scope.$transcludeFn = transclude;
    }
  })])
  .directive('pmdTabHeadingTransclude', () => ({
    restrict: 'A',
    require: '^pmdTab',
    link: (scope, elm) => {
      scope.$watch('headingElement', heading => {
        if (heading) {
          elm.html('');
          elm.append(heading);
        }
      });
    }
  }))
  .directive('pmdTabContentTransclude', () => {
    return {
      restrict: 'A',
      require: '^pmdTabset',
      link: (scope, elm, attrs) => {
        var tab = scope.$eval(attrs.pmdTabContentTransclude).tab;

        //Now our tab is ready to be transcluded: both the tab heading area
        //and the tab content area are loaded.  Transclude 'em both.
        tab.$transcludeFn(tab.$parent, contents => {
          angular.forEach(contents, node => {
            if (isTabHeading(node)) {
              //Let tabHeadingTransclude know.
              tab.headingElement = node;
            } else {
              elm.append(node);
            }
          });
        });
      }
    };

    function isTabHeading(node) {
      return node.tagName && (
        node.hasAttribute('pmd-tab-heading') ||
        node.tagName.toLowerCase() === 'pmd-tab-heading'
      );
    }
  })
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Tab',
      description: 'Group of content',
      version: '1.0.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
