import { useFormHook } from "../../hooks";
import { isValidCodigoPostal, isValidUrl } from "../../utils";
const validators = [
    {
        field: "taxNumber",
        validate: (empresa) => empresa.taxNumber.length == 9 &&
            !Number.isNaN(parseInt(empresa.taxNumber)),
        helperText: "NIF tem que conter 9 dígitos.",
    },
    {
        field: "name",
        validate: (empresa) => empresa.name.length >= 3,
        helperText: "Nome invalido.",
    },
    {
        field: "address",
        validate: (empresa) => empresa.address.length >= 5,
        helperText: "Morada invalida.",
    },
    {
        field: "url",
        validate: (empresa) => !empresa.url || isValidUrl(empresa.url),
        helperText: "URL invalido, e.g: 'https://miago.streamline.pt'.",
    },
    {
        field: "location",
        validate: (empresa) => empresa.location.length >= 3,
        helperText: "Localidade invalida.",
    },
    {
        field: "zipcode",
        validate: (empresa) => !empresa.zipcode || isValidCodigoPostal(empresa.zipcode),
        helperText: "Codigo Postal invalido, e.g: '0000-000'.",
    },
];
const defaultValues = {
    taxNumber: "",
    name: "",
    address: "",
    zipcode: "",
    location: "",
    url: "",
};
export const useEmpresa = (defaults = {}) => useFormHook(defaultValues, validators, defaults);
