export default class SystemController {
  constructor($rootScope, $http, $interval, $timeout) {
    this.date = moment.utc();
    this.loaded = false;

    this.ui = {
      base: $rootScope.uiBase,
      modules: _.sortBy($rootScope.uiModules, "name"),
    };

    $http.get("/api/status").then((res) => {
      this.serverInfo = res.data;
      this.loaded = true;
      $interval(() => {
        this.serverInfo.uptime++;
      }, 1000);
    });
  }

  envName = () => {
    if (!this.serverInfo) {
      return "";
    }
    switch (this.serverInfo.environment) {
      case "local": {
        return "Local";
      }
      case "development": {
        return "Desenvolvimento";
      }
      case "production": {
        return "Produção";
      }
    }
  };
}

SystemController.$inject = ["$rootScope", "$http", "$interval", "$timeout"];
