export default class TariffWizardController {
  constructor($state, TariffService, UIService, ConstantService) {
    this.$state = $state;
    this.Tariff = TariffService;
    this.UI = UIService;
    this.Constant = ConstantService;
    this.loadData();
    this.aux = {};
    this.data = {};
    this.powers = this.Tariff.powers();
  }

  onCyclePick = (item) => {
    this.aux.bands = angular.copy(item.cycle.bands);
    this.aux.cost = [];
    this.aux.bands.forEach((b, i) => {
      this.aux.cost.push({
        summer: 0,
        winter: 0
      });
    });
  }

  loadData = () => {
    this.Constant.getDefaultValues("tariff").then((r) => {
      this.data = Object.assign(this.data, r);
    })
    this.Tariff.cycles().then((r) => {
      this.loaded = true;
      this.cycles = r;
    }).catch((e) => {
      this.UI.addToast("Ocorreu um erro ao carregador ciclos horárias");
    });
  };

  validate = () => {
    let errors = [];
    if (!this.data.name || this.data.name.trim().length == 0) {
      errors.push("Insira um nome para o tarifário");
    }
    if (!this.data.power || isNaN(this.data.power) || this.data.power <= 0) {
      errors.push("Insira a potência contratada");
    }
    if (!this.aux.cycle) {
      errors.push("Escolha o ciclo horário");
    }
    if (this.aux.cost) {
      let hasError = false;
      this.aux.cost.forEach(c => {
        if (!c.summer || isNaN(c.summer) || c.summer <= 0)
          hasError = true;
        if (!c.winter || isNaN(c.winter) || c.winter <= 0)
          hasError = true;
      });
      if (hasError) {
        errors.push("Insira os custos relativos com o ciclo horário");
      }
    } else {
      errors.push("Insira os custos relativos com o ciclo horário");
    }
    if (errors.length > 0) {
      this.UI.showValidator(errors);
      return false;
    }
    return true;
  }

  submit = () => {
    if (this.validate()) {
      // ciclo horária
      this.data.cost = this.aux.cost;
      this.data.tariffcycleId = this.aux.cycle.id;
      // Gravar
      this.Tariff.save(this.data).then((r) => {
        this.UI.addToast("Tarifário registado com sucesso!");
        this.$state.go('app.tariff.list');
      }).catch((e) => {
        this.UI.addToast("Ocorreu um erro ao gravar tarifário");
      });
    }
  };
}

TariffWizardController.$inject = ["$state", "TariffService", "UIService", "ConstantService"];
