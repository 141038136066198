export default class LeiturasService {
  constructor(Register, RegisterMinute, RegisterAdjust, $q, Site, AuthenticationService, Smartmeter, Measure) {
    this.Register = Register;
    this.RegisterMinute = RegisterMinute;
    this.RegisterAdjust = RegisterAdjust;
    this.$q = $q;
    this.Site = Site;
    this.Auth = AuthenticationService;
    this.Smartmeter = Smartmeter;
    this.Measure = Measure;
  }

  loadSites = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let filter = {
      where: {
        entityId: user.selected.entityId
      }
    };
    this.Site.find(filter).$promise.then((r) => {
      defer.resolve(r);
    }).catch((e) => {
      defer.reject(e);
    });

    return defer.promise;
  };

  instant = (id, feature) => {
    return this.Smartmeter.instant({id, feature}).$promise;
  }

  loadMeasures = () => {
    let defer = this.$q.defer();
    this.Measure.find().$promise.then((r) => {
      defer.resolve(r);
    }).catch((e) => {
      defer.reject(e);
    });
    return defer.promise;
  };

  loadFilterData = (measure) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    this.Smartmeter.find({
      filter: {
        include: ["site", "measure"],
        where: {
          siteId: {
            inq: user.siteIds
          },
          measureId: measure,
          active: true
        }
      }
    }).$promise.then((r) => {
      defer.resolve({ smartmeters: r });
    }).catch((e) => {
      defer.reject(e);
    });
    return defer.promise;
  };

  adjust = data => {
    let defer = this.$q.defer();
    this.RegisterAdjust.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  export = (filter) => {
    filter.include = [
      "profile",
      {
        relation: "smartmeter",
        scope: {
          include: "site",
        },
      },
    ];
    // TODO: Find a way to use the export function from within the model
    let defer = this.$q.defer();
    let url = `/api/leituras/export?filter=${JSON.stringify(filter)}`;
    defer.resolve(url);
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    filter.include = [
      {
        relation: "smartmeter",
        scope: {
          include: ["site", "measure"],
        },
      },
    ];
    this.Register.count({ where: filter.where }).$promise.then((r) => {
      this.Register.find({
        filter: filter,
      })
        .$promise.then((list) => {
          defer.resolve({
            total: r.count,
            data: list,
          });
        })
        .catch((err) => {
          defer.reject(err);
        })
        .catch((err) => {
          defer.reject(err);
        });
    });
    return defer.promise;
  };

  consumption = (from, to, scale, smartmeters, filterPotency, filterWater, isFeature) => {
    let defer = this.$q.defer();
    if (scale === "3h") {
      this.RegisterMinute.analyticsConsumption3h({
        from: from,
        to: to,
        smartmeters: smartmeters,
        filterEnergy: !filterPotency,
        filterWater: filterWater,
        isFeature: isFeature || false
      }).$promise.then((r) => defer.resolve(r))
        .catch((e) => defer.reject(e));
    } else if (scale === "24h") {
      this.Register.analyticsConsumption24h({
        from: from,
        to: to,
        smartmeters: smartmeters,
        filterEnergy: !filterPotency,
        filterWater: filterWater,
        isFeature: isFeature || false
      }).$promise.then((r) => defer.resolve(r))
        .catch((e) => defer.reject(e));
    } else if (scale === "1w") {
      this.Register.analyticsConsumption1w({
        from: from,
        to: to,
        smartmeters: smartmeters,
        filterEnergy: !filterPotency,
        filterWater: filterWater,
        isFeature: isFeature || false
      }).$promise.then((r) => defer.resolve(r))
        .catch((e) => defer.reject(e));
    } else if (scale === "1m") {
      this.Register.analyticsConsumption1m({
        from: from,
        to: to,
        smartmeters: smartmeters,
        filterEnergy: !filterPotency,
        filterWater: filterWater,
        isFeature: isFeature || false
      }).$promise.then((r) => defer.resolve(r))
        .catch((e) => defer.reject(e));
    } else if (scale === "1y") {
      this.Register.analyticsConsumption1y({
        from: from,
        to: to,
        smartmeters: smartmeters,
        filterEnergy: !filterPotency,
        filterWater: filterWater,
        isFeature: isFeature || false
      }).$promise.then((r) => { defer.resolve(r); })
        .catch((e) => defer.reject(e));
    }

    return defer.promise;
  };
}

LeiturasService.$inject = ["Register", "RegisterMinute", "RegisterAdjust", "$q", "Site", "AuthenticationService", "Smartmeter", "Measure"];
