export function routes($stateProvider) {
  $stateProvider
    .state('app.site', {
      url: '/site',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.site.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'SiteListController',
      controllerAs: 'vm',
      role: ['siteList']
    }).state('app.site.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'SiteDetailsController',
      controllerAs: 'vm',
      role: ['siteEdit']
    }).state('tv.view', {
      url: '/{id}',
      template: require('./tv/view.html'),
      controller: 'SiteTVController',
      controllerAs: 'vm'
    }).state('app.site.new', {
      url: '/add?entityId',
      template: require('./details/view.html'),
      controller: 'SiteDetailsController',
      controllerAs: 'vm',
      role: ['siteAdd']
    }).state('app.site.map', {
      url: '/map',
      template: require('./map/view.html'),
      controller: 'SiteMapController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
