String.prototype.includes = function (str) {
  var returnValue = false;

  if (this.indexOf(str) !== -1) {
    returnValue = true;
  }

  return returnValue;
};

String.prototype.isEmpty = function () {
  return this.length === 0 || !this.trim();
};

Array.prototype.isEmpty = function () {
  return this.length === 0;
};

Number.prototype.round = function (n) {
  return Math.round((this + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);
}

String.prototype.contains = function (array) {
  if (!Array.isArray(array)) {
    throw new Error("Comparison object is not an array!");
  }
  return array.includes(this);
};

export function ApplicationInterceptor(
  $rootScope,
  $state,
  $http,
  $timeout,
  AuthenticationService,
  AuthorizationService,
  UIService,
  Backlog
) {
  $rootScope.$on("$entityUpdate", () => {
    $state.refresh();
  });

  $rootScope.$on("$stateChangeStart", (event, to) => {
    // Get Server Status - this is async, so no worries :)
    $http.get("/api/status").then((res) => {
      $rootScope.status = res.data;
      $rootScope.$broadcast("$receivedServerStatus", res.data);
    });

    // User is authenticated? In case of an exception, set authenticated as off
    let loggedIn = AuthenticationService.isAuthenticated() || false;
    // 2FA control, view isAllowed() function for more info
    let allowed = AuthenticationService.isAllowed() || false;

    if (!loggedIn) {
      // If user not authenticated and trying to access routes that are not related to authentication, deny access
      if (!loggedIn && !to.name.includes("auth")) {
        AuthenticationService.clearUser();
        $state.go("auth.login");
        event.preventDefault(); // Denying access
      }
    } else {
      // If user is authenticated and tries to access routes that are authentication
      if (loggedIn && to.name.includes("auth") && allowed) {
        $state.go("app.dashboard");
        event.preventDefault(); // Denying access
      } else {
        if (!allowed && !to.name.includes("auth.two-factor")) {
          $state.go("auth.two-factor");
        } else {
          let user = AuthenticationService.getUser();
          if (user.blocked) {
            UIService.addToast("A sua conta foi bloqueada");
            AuthenticationService.logout();
          }

          // Check if user has access to role of state (if exists)
          if (to.hasOwnProperty("role")) {
            if (!AuthorizationService.canPerform(to.role)) {
              $state.go("app.dashboard");
              event.preventDefault(); // Denying access
            }
          }
        }
      }
    }
  });

  $rootScope.$on(
    "$stateChangeSuccess",
    (event, toState, toParams, fromState, fromParams) => {
      $rootScope.previousState = {
        name: fromState,
        params: fromParams,
      };
      $rootScope.currentState = {
        name: toState,
        params: toParams,
      };

      let loggedIn = AuthenticationService.isAuthenticated() || false;
      if (loggedIn) {
        $timeout(() => {
          let env = $rootScope.status.environment;
          // Write to backlog
          let user = AuthenticationService.getUser();
          if (user != null && env !== "local") {
            Backlog.create({
              timestamp: moment.utc(),
              userId: user.id,
              route: toState.url,
              params: toParams.hasOwnProperty('#') && toParams['#'] == null ? null : JSON.stringify(toParams)
            });
          }
        });
      }
    }
  );
}

ApplicationInterceptor.$inject = [
  "$rootScope",
  "$state",
  "$http",
  "$timeout",
  "AuthenticationService",
  "AuthorizationService",
  "UIService",
  "Backlog"
];
