export function routes($stateProvider) {
  $stateProvider
    .state('app.register', {
      url: '/register',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.register.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
      template: require('./list/view.html'),
      controller: 'RegisterListController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
