export default class MagicLinkController {
  constructor($state, $rootScope, $cookies, LoopBackAuth, $timeout, AuthenticationService) {
    let token = $state.params["access-token"];
    let userId = $state.params["user-id"];
    if (!token || !userId) {
      // Prevent people by hitting the url directly
      $state.go("auth.login");
    }
    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.providers = data.providers.filter((r) => !r.link);
      this.data = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
    });
    $timeout(() => {
      if (angular.isDefined(token) && angular.isDefined(userId)) {
        $cookies.put("access_token", LoopBackAuth.accessTokenId);
        $cookies.put("userId", LoopBackAuth.currentUserId);
        LoopBackAuth.currentUserId = userId;
        LoopBackAuth.accessTokenId = token;
        LoopBackAuth.save();
        AuthenticationService.getUserFromId(userId).then(r => {
          window.location = "/";
        });
      } else {
        this.UI.addToast("Não foi possível validar os dados introduzidos");
        $timeout(() => {
          $state.go("auth.login");
        }, 500);
      }
    }, 2500);
  }
}

MagicLinkController.$inject = ["$state", "$rootScope", "$cookies", "LoopBackAuth", "$timeout", "AuthenticationService"];
