export function routes($stateProvider) {
  $stateProvider
    .state('app.constant', {
      url: '/constant',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.constant.list', {
      url: '',
      template: require('./list/view.html'),
      controller: 'ConstantListController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })/*.state('app.constant.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'ConstantDetailsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })*/.state('app.constant.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'ConstantDetailsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    });
}
//TODO replace controlPanel with specific permission?
routes.$inject = ['$stateProvider'];
