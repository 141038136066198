export default class MeasureDetailsController {
  constructor($state, UIService, MeasureService, AuthorizationService, FileUploader) {
    this.$state = $state;
    this.UI = UIService;
    this.Measure = MeasureService;
    this.Authorization = AuthorizationService;

    this.id = $state.params.id;

    if (this.id) {
      this.label = "Editar";
      this.loadData();
    } else {
      this.label = "Adicionar";
      this.loaded = true;
    }
  }

  loadData = () => {
    this.Measure.get(this.id).then(r => {
      this.data = r;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar medida");
      this.$state.go('app.measure.list');
    });
  };

  save = () => {
    if (this.validate()) {
      this.Measure.save(this.data).then(r => {
        if (this.id) {
          this.UI.addToast("Medida alterada com sucesso");
          this.$state.go('app.measure.list');
        } else {
          this.$state.go('app.measure.list');
          this.UI.addToast("Medida adicionada com sucesso");
        }
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar medida");
      });
    }
  };

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome da medida");
      return false;
    }
    if (!this.data.unit) {
      this.UI.addToast("Indique a unidade para capacidade máxima");
      return false;
    }
    if (!this.data.icon) {
      this.UI.addToast("Indique o ícone para a medida");
      return false;
    }

    return true;
  };

  editable = () => {
    return this.Authorization.canPerform(['measureEdit']);
  };
}

MeasureDetailsController.$inject = ['$state', 'UIService', 'MeasureService', 'AuthorizationService', 'FileUploader'];
