export default class TariffWaterService {
    constructor($q, AuthenticationService, Tariffwater) {
        this.$q = $q;
        this.Auth = AuthenticationService;
        this.TariffWater = Tariffwater;
    }

    list = (filter) => {
        let defer = this.$q.defer();
        let user = this.Auth.getUser();
        filter = filter || {};
        filter.where = filter.where || {};

        filter.where["entityId"] = user.selected.entityId

        filter.include = ["smartmeters"];
        this.TariffWater.count({
            where: filter.where
        }).$promise.then(c => {
            this.TariffWater.find({
                filter: filter
            }).$promise.then(r => {
                return defer.resolve({
                    total: c.count,
                    data: r
                });
            }).catch(e => defer.reject(e));
        }).catch(e => defer.reject(e));
        return defer.promise;
    }

    get = id => {
        let defer = this.$q.defer();
        let user = this.Auth.getUser();
        this.TariffWater.findById({
            id: id,
        }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
        return defer.promise;
    }

    save = data => {
        let defer = this.$q.defer();
        let user = this.Auth.getUser();
        if (!data.entityId)
            data.entityId = user.selected.entityId;
        this.TariffWater.upsert(data)
            .$promise.then(r => defer.resolve(r))
            .catch(e => defer.reject(e));
        return defer.promise;
    }
}

TariffWaterService.$inject = ['$q', 'AuthenticationService', 'Tariffwater'];
