export default class DashboardDoorController {
    constructor(Dashboard, $interval, $scope, $state) {
        this.Dashboard = Dashboard;
        this.$state = $state;
        this.$interval = $interval;
        this.counters = {};

        this.getData();

        if (!$scope.$$destroyed) {
            this.interval = $interval(() => {
                this.getData();
            }, 2 * 1000 * 60);
        }

        $scope.$on("$destroy", () => {
            if (this.interval) $interval.cancel(this.interval);
        });
    }

    doorStateOutput = (door) => {
      switch(door.state) {
        case 0: return "PORTA FECHADA";
        case 1: return "PORTA ABERTA";
        default: return "PORTA EM ESTADO DESCONHECIDO";
      }
    };

    getData = () => {
        this.environmentLoaded = false;
        this.Dashboard.dashboardDoors().then((r) => {
            this.doors = r;
            this.environmentLoaded = true;
        });
    }
}

DashboardDoorController.$inject = ["DashboardService", "$interval", "$scope", "$state"];
