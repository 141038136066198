import uiRouter from "@uirouter/angularjs";

import { routes, routing } from "./authentication.routes";
import LoginController from "./login/controller";
import ForgotPasswordController from "./forgot-password/forgot-password.controller";
import ResetPasswordController from "./reset-password/controller";
import AuthenticationService from "./authentication.service";
import TwoFactorController from "./two-factor/two-factor.controller";
import RedirectController from "./redirect/redirect.controller";
import MagicLinkController from "./magic-link/controller";

export default angular
  .module("app.authentication", [uiRouter])
  .config(routes)
  .config(routing)
  .controller("LoginController", LoginController)
  .controller("MagicLinkController", MagicLinkController)
  .controller("ForgotPasswordController", ForgotPasswordController)
  .controller("ResetPasswordController", ResetPasswordController)
  .controller("TwoFactorController", TwoFactorController)
  .controller("RedirectController", RedirectController)
  .service("AuthenticationService", AuthenticationService)
  .directive("valueMatches", [
    "$parse",
    function ($parse) {
      return {
        require: "ngModel",
        link: function (scope, elm, attrs, ngModel) {
          var originalModel = $parse(attrs.valueMatches),
            secondModel = $parse(attrs.ngModel);
          // Watch for changes to this input
          scope.$watch(attrs.ngModel, function (newValue) {
            ngModel.$setValidity(attrs.name, newValue === originalModel(scope));
          });
          // Watch for changes to the value-matches model's value
          scope.$watch(attrs.valueMatches, function (newValue) {
            ngModel.$setValidity(attrs.name, newValue === secondModel(scope));
          });
        },
      };
    },
  ])
  .name;
