import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import MeterForm from "./Meter";
import { TabPanel } from "../../utils/CustomComponents";
import { theme } from "../../utils";
const MetersForm = ({ forceShowErrors, instalacao, licenciamento, obj: meters, }) => {
    const [tab, setTab] = useState(0);
    const handleTabChange = (_event, newValue) => setTab(newValue);
    const getTabStyle = (meter) => forceShowErrors && !meter.isValid()
        ? { color: theme.palette.error.main }
        : undefined;
    return (<div>
      <Typography sx={{ mt: 1, mb: 1 }}>Passo 4 - Criar Meters</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          {licenciamento.Energia ? (<Tab label="Consumo operador" style={getTabStyle(meters.ConsumoOperador)}/>) : null}
          {licenciamento.Solar ? (<Tab label="Produção Solar" style={getTabStyle(meters.ProdSolar)}/>) : null}
          {licenciamento.Solar ? (<Tab label="Excedente Solar" style={getTabStyle(meters.ExcedenteSolar)}/>) : null}
        </Tabs>
      </Box>

      {licenciamento.Energia ? (<TabPanel value={tab} index={0}>
          <MeterForm state={meters.ConsumoOperador} forceShowErrors={forceShowErrors} instalacao={instalacao}></MeterForm>
        </TabPanel>) : null}
      {licenciamento.Solar ? (<TabPanel value={tab} index={licenciamento.Energia ? 1 : 0}>
          <MeterForm state={meters.ProdSolar} forceShowErrors={forceShowErrors} instalacao={instalacao}></MeterForm>
        </TabPanel>) : null}
      {licenciamento.Solar ? (<TabPanel value={tab} index={licenciamento.Energia ? 2 : 1}>
          <MeterForm state={meters.ExcedenteSolar} forceShowErrors={forceShowErrors} instalacao={instalacao}></MeterForm>
        </TabPanel>) : null}
      <Box>
        <Tabs value={tab} onChange={handleTabChange}></Tabs>
      </Box>
    </div>);
};
export default MetersForm;
