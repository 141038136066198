/*
 * Taken and adapted from:
 * https://www.npmjs.com/package/propellerkit-datetimepicker
 */

let $ = require("jquery");
moment.updateLocale("pt", {
  week: { dow: 0 },
  weekdaysMin: ["D", "S", "T", "Q", "Q", "S", "S"],
});

function dateTimePicker(element, options) {
  let picker = {},
    date = moment().startOf("d"),
    viewDate = date.clone(),
    unset = true,
    input,
    component = false,
    widget = false,
    use24Hours,
    minViewModeNumber = 0,
    actualFormat,
    parseFormats,
    currentViewMode,
    datePickerModes = [
      {
        clsName: "days",
        navFnc: "M",
        navStep: 1,
      },
      {
        clsName: "months",
        navFnc: "y",
        navStep: 1,
      },
      {
        clsName: "years",
        navFnc: "y",
        navStep: 10,
      },
    ],
    viewModes = ["days", "months", "years"],
    verticalModes = ["top", "bottom", "auto"],
    horizontalModes = ["left", "right", "auto"],
    toolbarPlacements = ["default", "top", "bottom"],
    keyMap = {
      up: 38,
      38: "up",
      down: 40,
      40: "down",
      left: 37,
      37: "left",
      right: 39,
      39: "right",
      tab: 9,
      9: "tab",
      escape: 27,
      27: "escape",
      enter: 13,
      13: "enter",
      pageUp: 33,
      33: "pageUp",
      pageDown: 34,
      34: "pageDown",
      shift: 16,
      16: "shift",
      control: 17,
      17: "control",
      space: 32,
      32: "space",
      t: 84,
      84: "t",
      delete: 46,
      46: "delete",
    },
    keyState = {},
    /********************************************************************************
     *
     * Private functions
     *
     ********************************************************************************/
    isEnabled = function (granularity) {
      if (typeof granularity !== "string" || granularity.length > 1) {
        throw new TypeError(
          "isEnabled expects a single character string parameter"
        );
      }
      switch (granularity) {
        case "y":
          return actualFormat.indexOf("Y") !== -1;
        case "M":
          return actualFormat.indexOf("M") !== -1;
        case "d":
          return actualFormat.toLowerCase().indexOf("d") !== -1;
        case "h":
        case "H":
          return actualFormat.toLowerCase().indexOf("h") !== -1;
        case "m":
          return actualFormat.indexOf("m") !== -1;
        case "s":
          return actualFormat.indexOf("s") !== -1;
        default:
          return false;
      }
    },
    hasTime = function () {
      return isEnabled("h") || isEnabled("m") || isEnabled("s");
    },
    hasDate = function () {
      return isEnabled("y") || isEnabled("M") || isEnabled("d");
    },
    getDatePickerTemplate = function () {
      let monthonly1 = moment.utc(date).format("MMM"),
        yearonly1 = moment.utc(date).format("YYYY"),
        dateonly1 = moment.utc(date).format("DD"),
        dayname1 = moment.utc(date).format("ddd"),
        headTemplate = $("<thead>").append(
          $("<tr>")
            .append(
              $("<th>")
                .addClass("prev")
                .attr("data-action", "previous")
                .append($("<span>").addClass(options.icons.previous))
            )
            .append(
              $("<th>")
                .addClass("picker-switch")
                .attr("data-action", "pickerSwitch")
                .attr("colspan", options.calendarWeeks ? "6" : "5")
            )
            .append(
              $("<th>")
                .addClass("next")
                .attr("data-action", "next")
                .append($("<span>").addClass(options.icons.next))
            )
        ),
        contTemplate = $("<tbody>").append(
          $("<tr>").append(
            $("<td>").attr("colspan", options.calendarWeeks ? "8" : "7")
          )
        );

      return [
        $("<div>")
          .addClass("datepicker-days")
          .append(
            $("<div>")
              .addClass("topdateinfo")
              .append($("<p>").addClass("yearonly").append(yearonly1))
              .append(
                $("<div>")
                  .addClass("datetopcol")
                  .append(
                    $("<p>")
                      .addClass("dayname")
                      .append(dayname1 + ",")
                  )
                  .append($("<p>").addClass("dateonly").append(dateonly1))
                  .append($("<p>").addClass("monthonly").append(monthonly1))
              )
          )
          .append(
            $("<table>")
              .addClass("table-condensed")
              .append(headTemplate)
              .append($("<tbody>"))
          ),
        $("<div>")
          .addClass("datepicker-months")
          .append(
            $("<div>")
              .addClass("topdateinfo")
              .append($("<p>").addClass("yearonly").append(yearonly1))
              .append(
                $("<div>")
                  .addClass("datetopcol")
                  .append(
                    $("<p>")
                      .addClass("dayname")
                      .append(dayname1 + ",")
                  )
                  .append($("<p>").addClass("dateonly").append(dateonly1))
                  .append($("<p>").addClass("monthonly").append(monthonly1))
              )
          )
          .append(
            $("<table>")
              .addClass("table-condensed")
              .append(headTemplate.clone())
              .append(contTemplate.clone())
          ),
        $("<div>")
          .addClass("datepicker-years")
          .append(
            $("<div>")
              .addClass("topdateinfo")
              .append($("<p>").addClass("yearonly").append(yearonly1))
              .append(
                $("<div>")
                  .addClass("datetopcol")
                  .append(
                    $("<p>")
                      .addClass("dayname")
                      .append(dayname1 + ",")
                  )
                  .append($("<p>").addClass("dateonly").append(dateonly1))
                  .append($("<p>").addClass("monthonly").append(monthonly1))
              )
          )
          .append(
            $("<table>")
              .addClass("table-condensed")
              .append(headTemplate.clone())
              .append(contTemplate.clone())
          ),
      ];
    },
    getTimePickerMainTemplate = function () {
      let timevalRow = $("div"),
        topRow = $("<div>").addClass("uparrow"),
        middleRow = $("<div>").addClass("timeview"),
        separator1 = $("<div>").addClass("time-separator"),
        separator2 = $("<div>").addClass("time-separator"),
        bottomRow = $("<div>").addClass("downarrow"),
        amapview = $("<div>").addClass("ampmview");

      if (isEnabled("h")) {
        topRow
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "incrementHours")
              .append($("<span>").addClass(options.icons.up))
          )
          .append(
            $("<span>")
              .addClass("timepicker-hour")
              .addClass("active")
              .attr("data-time-component", "hours")
              .attr("data-action", "showHours")
          )
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "decrementHours")
              .append($("<span>").addClass(options.icons.down))
          );
      }
      if (isEnabled("m")) {
        if (isEnabled("h")) {
          //      topRow.append($('<span>').addClass('separator'));
          separator1.append($("<span>").addClass("separator").html(":"));
          //     bottomRow.append($('<span>').addClass('separator'));
        }
        middleRow
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "incrementMinutes")
              .append($("<span>").addClass(options.icons.up))
          )
          .append(
            $("<span>")
              .addClass("timepicker-minute")
              .attr("data-time-component", "minutes")
              .attr("data-action", "showMinutes")
          )
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "decrementMinutes")
              .append($("<span>").addClass(options.icons.down))
          );
      }
      if (isEnabled("s")) {
        if (isEnabled("m")) {
          //      topRow.append($('<span>').addClass('separator'));
          separator2.append($("<span>").addClass("separator").html(":"));
          //      middleRow.append($('<span>').addClass('separator'));
        }

        bottomRow
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "incrementSeconds")
              .append($("<span>").addClass(options.icons.up))
          )
          .append(
            $("<span>")
              .addClass("timepicker-second")
              .attr("data-time-component", "seconds")
              .attr("data-action", "showSeconds")
          )
          .append(
            $("<a>")
              .attr({ href: "#", tabindex: "-1" })
              .addClass("btn")
              .attr("data-action", "decrementSeconds")
              .append($("<span>").addClass(options.icons.down))
          );
      }

      if (!use24Hours) {
        topRow.append($("<td>").addClass("separator"));
        //                    middleRow

        bottomRow.append(
          $("<span>")
            .attr("data-action", "togglePeriod")
            .addClass("ampmvalue")
            .append(
              $("<button>")
                .addClass("btn btn-primary")
                .attr("data-action", "togglePeriodam")
            )
            .append(
              $("<button>")
                .addClass("btn btn-primary")
                .attr("data-action", "togglePeriodpm")
            )
        );
        /*amapview.append($('<div>').addClass('btn-group')
                         .append($('<button>').addClass('btn btn-primary pmd-ripple-effect pull-left amview active').attr('data-action', 'togglePeriodam'))
                      .append($('<button>').addClass('btn btn-primary pmd-ripple-effect pull-right pmview').attr('data-action', 'togglePeriodpm'))
                  );*/
      }

      return $("<div>")
        .addClass("timepicker-picker")
        .append([topRow])
        .append([separator1])
        .append([middleRow])
        .append([separator2])
        .append([bottomRow])
        .append([amapview]);
      //.append([bottomRow])
    },
    getTimePickerTemplate = function () {
      let hoursView = $("<div>")
          .addClass("timepicker-hours")
          .append($("<div>").addClass("table-condensed clockpickerr")),
        minutesView = $("<div>")
          .addClass("timepicker-minutes")
          .append($("<div>").addClass("table-condensed clockpickerminute")),
        secondsView = $("<div>")
          .addClass("timepicker-seconds")
          .append($("<div>").addClass("table-condensed clockpickerseconds")),
        ret = [getTimePickerMainTemplate()];

      if (isEnabled("h")) {
        ret.push(hoursView);
      }
      if (isEnabled("m")) {
        ret.push(minutesView);
      }
      if (isEnabled("s")) {
        ret.push(secondsView);
      }
      return ret;
    },
    getToolbar = function () {
      let row = [];
      if (options.showTodayButton) {
        row.push(
          $("<a>")
            .attr("data-action", "today")
            .append($("<span>").addClass(options.icons.today))
        );
      }
      if (!options.sideBySide && hasDate() && hasTime()) {
        row.push(
          $("<a>")
            .attr("data-action", "togglePicker")
            .append($("<span>").addClass(options.icons.time))
        );
      }
      if (options.showClear) {
        row.push(
          $("<td>").append(
            $("<a>")
              .attr("data-action", "clear")
              .append($("<span>").addClass(options.icons.clear))
          )
        );
      }
      if (options.showClose) {
        row.push(
          $("<td>").append(
            $("<a>")
              .attr("data-action", "close")
              .append($("<span>").addClass(options.icons.close))
          )
        );
      }
      return row;
    },
    getTemplate = function () {
      let template = $("<div>").addClass(
          "bootstrap-datetimepicker-widget dropdown-menu"
        ),
        dateView = $("<div>")
          .addClass("datepicker")
          .append(getDatePickerTemplate()),
        timeView = $("<div>")
          .addClass("timepicker")
          .append(getTimePickerTemplate()),
        content = $("<ul>").addClass("list-unstyled"),
        toolbar = $("<li>")
          .addClass(
            "picker-switch" + (options.collapse ? " accordion-toggle" : "")
          )
          .append(getToolbar());

      if (options.inline) {
        template.removeClass("dropdown-menu");
      }

      if (use24Hours) {
        template.addClass("usetwentyfour");
      }
      if (options.sideBySide && hasDate() && hasTime()) {
        template.addClass("timepicker-sbs");
        template.append(
          $("<div>")
            .addClass("row")
            .append(dateView.addClass("col-sm-12"))
            .append(timeView.addClass("col-sm-12"))
        );
        template.append(toolbar);
        return template;
      }

      if (options.toolbarPlacement === "top") {
        content.append(toolbar);
      }
      if (hasDate()) {
        content.append(
          $("<li>")
            .addClass(options.collapse && hasTime() ? "collapse in" : "")
            .append(dateView)
        );
      }
      if (options.toolbarPlacement === "default") {
        content.append(toolbar);
      }
      if (hasTime()) {
        content.append(
          $("<li>")
            .addClass(options.collapse && hasDate() ? "collapse" : "")
            .append(timeView)
        );
      }
      if (options.toolbarPlacement === "bottom") {
        content.append(toolbar);
      }
      return template.append(content);
    },
    dataToOptions = function () {
      let eData,
        dataOptions = {};

      if (element.is("input") || options.inline) {
        eData = element.data();
      } else {
        eData = element.find("input").data();
      }

      if (eData.dateOptions && eData.dateOptions instanceof Object) {
        dataOptions = $.extend(true, dataOptions, eData.dateOptions);
      }

      $.each(options, function (key) {
        let attributeName = "date" + key.charAt(0).toUpperCase() + key.slice(1);
        if (eData[attributeName] !== undefined) {
          dataOptions[key] = eData[attributeName];
        }
      });
      return dataOptions;
    },
    place = function () {
      let position = (component || element).position(),
        offset = (component || element).offset(),
        vertical = options.widgetPositioning.vertical,
        horizontal = options.widgetPositioning.horizontal,
        parent;

      if (options.widgetParent) {
        parent = options.widgetParent.append(widget);
      } else if (element.is("input")) {
        parent = element.parent().append(widget);
      } else if (options.inline) {
        parent = element.append(widget);
        return;
      } else {
        parent = element;
        element.children().first().after(widget);
      }

      // Top and bottom logic
      if (vertical === "auto") {
        // removed  " 1.5 "  " offset.top + widget.height() * 1.5"
        if (
          offset.top + widget.height() >=
            $(window).height() + $(window).scrollTop() &&
          widget.height() + element.outerHeight() < offset.top
        ) {
          vertical = "top";
        } else {
          vertical = "bottom";
        }
      }

      // Left and right logic
      if (horizontal === "auto") {
        if (
          parent.width() < offset.left + widget.outerWidth() / 2 &&
          offset.left + widget.outerWidth() > $(window).width()
        ) {
          horizontal = "right";
        } else {
          horizontal = "left";
        }
      }

      if (vertical === "top") {
        widget.addClass("top").removeClass("bottom");
      } else {
        widget.addClass("bottom").removeClass("top");
      }

      if (horizontal === "right") {
        widget.addClass("pull-right");
      } else {
        widget.removeClass("pull-right");
      }

      // find the first parent element that has a relative css positioning
      if (parent.css("position") !== "relative") {
        parent = parent
          .parents()
          .filter(function () {
            return $(this).css("position") === "relative";
          })
          .first();
      }

      if (parent.length === 0) {
        throw new Error(
          "datetimepicker component should be placed within a relative positioned container"
        );
      }

      widget.css({
        top: vertical === "top" ? "auto" : position.top + element.outerHeight(),
        bottom:
          vertical === "top" ? position.top + element.outerHeight() : "auto",
        left: horizontal === "left" ? parent.css("padding-left") : "auto",
        right:
          horizontal === "left"
            ? "auto"
            : parent.width() - element.outerWidth(),
      });
    },
    notifyEvent = function (e) {
      if (
        e.type === "dp.change" &&
        ((e.date && e.date.isSame(e.oldDate)) || (!e.date && !e.oldDate))
      ) {
        return;
      }
      element.trigger(e);
    },
    showMode = function (dir) {
      if (!widget) {
        return;
      }
      if (dir) {
        currentViewMode = Math.max(
          minViewModeNumber,
          Math.min(2, currentViewMode + dir)
        );
      }

      widget
        .find(".datepicker > div")
        .hide()
        .filter(".datepicker-" + datePickerModes[currentViewMode].clsName)
        .css("display", "table");
    },
    fillDow = function () {
      let row = $("<tr>"),
        currentDate = viewDate.clone().startOf("w");

      if (options.calendarWeeks === true) {
        row.append($("<th>").addClass("cw").text("#"));
      }

      while (currentDate.isBefore(viewDate.clone().endOf("w"))) {
        row.append($("<th>").addClass("dow").text(currentDate.format("dd")));
        currentDate.add(1, "d");
      }
      widget.find(".datepicker-days thead").append(row);
    },
    isInDisabledDates = function (testDate) {
      return options.disabledDates[testDate.format("YYYY-MM-DD")] === true;
    },
    isInEnabledDates = function (testDate) {
      return options.enabledDates[testDate.format("YYYY-MM-DD")] === true;
    },
    isValid = function (targetMoment, granularity) {
      if (!targetMoment.isValid()) {
        return false;
      }
      if (
        options.disabledDates &&
        isInDisabledDates(targetMoment) &&
        granularity !== "M"
      ) {
        return false;
      }
      if (
        options.enabledDates &&
        !isInEnabledDates(targetMoment) &&
        granularity !== "M"
      ) {
        return false;
      }
      if (
        options.minDate &&
        targetMoment.isBefore(options.minDate, granularity)
      ) {
        return false;
      }
      if (
        options.maxDate &&
        targetMoment.isAfter(options.maxDate, granularity)
      ) {
        return false;
      }
      if (
        granularity === "d" &&
        options.daysOfWeekDisabled.indexOf(targetMoment.day()) !== -1
      ) {
        //widget && widget.find('.datepicker-days').length > 0
        return false;
      }
      return true;
    },
    fillMonths = function () {
      let spans = [],
        monthsShort = viewDate.clone().startOf("y").hour(12); // hour is changed to avoid DST issues in some browsers
      while (monthsShort.isSame(viewDate, "y")) {
        spans.push(
          $("<span>")
            .attr("data-action", "selectMonth")
            .addClass("month")
            .text(monthsShort.format("MMM"))
        );
        monthsShort.add(1, "M");
      }
      widget.find(".datepicker-months td").empty().append(spans);
    },
    updateMonths = function () {
      let monthsView = widget.find(".datepicker-months"),
        monthsViewHeader = monthsView.find("th"),
        months = monthsView.find("tbody").find("span");

      monthsView.find(".disabled").removeClass("disabled");

      if (!isValid(viewDate.clone().subtract(1, "y"), "y")) {
        monthsViewHeader.eq(0).addClass("disabled");
      }

      monthsViewHeader.eq(1).text(viewDate.year());

      if (!isValid(viewDate.clone().add(1, "y"), "y")) {
        monthsViewHeader.eq(2).addClass("disabled");
      }

      months.removeClass("active");
      if (date.isSame(viewDate, "y")) {
        months.eq(date.month()).addClass("active");
      }

      months.each(function (index) {
        if (!isValid(viewDate.clone().month(index), "M")) {
          $(this).addClass("disabled");
        }
      });
    },
    updateYears = function () {
      let yearsView = widget.find(".datepicker-years"),
        yearsViewHeader = yearsView.find("th"),
        startYear = viewDate.clone().subtract(5, "y"),
        endYear = viewDate.clone().add(6, "y"),
        html = "";

      yearsView.find(".disabled").removeClass("disabled");

      if (options.minDate && options.minDate.isAfter(startYear, "y")) {
        yearsViewHeader.eq(0).addClass("disabled");
      }

      yearsViewHeader.eq(1).text(startYear.year() + "-" + endYear.year());

      if (options.maxDate && options.maxDate.isBefore(endYear, "y")) {
        yearsViewHeader.eq(2).addClass("disabled");
      }

      while (!startYear.isAfter(endYear, "y")) {
        html +=
          '<span data-action="selectYear" class="year' +
          (startYear.isSame(date, "y") ? " active" : "") +
          (!isValid(startYear, "y") ? " disabled" : "") +
          '">' +
          startYear.year() +
          "</span>";
        startYear.add(1, "y");
      }

      yearsView.find("td").html(html);
    },
    fillDate = function () {
      let daysView = widget.find(".datepicker-days"),
        daysViewHeader = daysView.find("th"),
        currentDate,
        html = [],
        row,
        clsName;

      if (!hasDate()) {
        return;
      }

      daysView.find(".disabled").removeClass("disabled");
      daysViewHeader.eq(1).text(viewDate.format(options.dayViewHeaderFormat));

      if (!isValid(viewDate.clone().subtract(1, "M"), "M")) {
        daysViewHeader.eq(0).addClass("disabled");
      }
      if (!isValid(viewDate.clone().add(1, "M"), "M")) {
        daysViewHeader.eq(2).addClass("disabled");
      }

      currentDate = viewDate.clone().startOf("M").startOf("week");

      while (
        !viewDate.clone().endOf("M").endOf("w").isBefore(currentDate, "d")
      ) {
        if (currentDate.weekday() === 0) {
          row = $("<tr>");
          if (options.calendarWeeks) {
            row.append('<td class="cw">' + currentDate.week() + "</td>");
          }
          html.push(row);
        }
        clsName = "";
        if (currentDate.isBefore(viewDate, "M")) {
          clsName += " old";
        }
        if (currentDate.isAfter(viewDate, "M")) {
          clsName += " new";
        }
        if (currentDate.isSame(date, "d") && !unset) {
          clsName += " active";
        }
        if (!isValid(currentDate, "d")) {
          clsName += " disabled";
        }
        if (currentDate.isSame(moment(), "d")) {
          clsName += " today";
        }
        if (currentDate.day() === 0 || currentDate.day() === 6) {
          clsName += " weekend";
        }
        row.append(
          '<td data-action="selectDay" class="day' +
            clsName +
            '"><span></span>' +
            currentDate.date() +
            "</td>"
        );
        currentDate.add(1, "d");
      }

      daysView.find("tbody").empty().append(html);

      updateMonths();

      updateYears();
    },
    fillHours = function () {
      let table = widget.find(".timepicker-hours > div"),
        currentHour = viewDate.clone().startOf("d"),
        html = [],
        row = $("<div>");

      if (viewDate.hour() > 11 && !use24Hours) {
        currentHour.hour(12);
      }
      if (!use24Hours) {
        widget.find(".timepicker").addClass("tHours");
      }
      if (use24Hours) {
        widget.find(".timepicker").addClass("24Hours");
      }
      let position = 0;
      while (
        currentHour.isSame(viewDate, "d") &&
        (use24Hours ||
          (viewDate.hour() < 12 && currentHour.hour() < 12) ||
          viewDate.hour() > 11)
      ) {
        if (currentHour.hour() % 4 === 0) {
          //  row = $('<tr>');
          html.push(row);
        }
        row.append(
          '<div data-action="selectHour" class="hour position-' +
            position++ +
            (!isValid(currentHour, "h") ? " disabled" : "") +
            '">' +
            currentHour.format(use24Hours ? "HH" : "hh") +
            "</div>"
        );
        currentHour.add(1, "h");
      }
      table.empty().append(html);

      row.parent().prepend('<div class="hourHnad"></div>');
      let currenthourtime = widget.find(".timepicker-hour").text();
      widget.find(".hourHnad").addClass("hourHnad-" + currenthourtime);
      $(".hour").removeClass("active");
      widget.find(".hour").each(function () {
        if ($(this).text() === currenthourtime) {
          $(this).addClass("active");
        }
      });
    },
    fillMinutes = function () {
      let table = widget.find(".timepicker-minutes > div"),
        currentMinute = viewDate.clone().startOf("h"),
        html = [],
        row = $("<div>"),
        step = options.stepping === 1 ? 5 : options.stepping;

      let position = 0;
      while (viewDate.isSame(currentMinute, "h")) {
        if (currentMinute.minute() % (step * 4) === 0) {
          //  row = $('<tr>');
          html.push(row);
        }
        row.append(
          '<div data-action="selectMinute" class="minute position-' +
            position++ +
            (!isValid(currentMinute, "m") ? " disabled" : "") +
            '">' +
            currentMinute.format("mm") +
            "</div>"
        );
        currentMinute.add(step, "m");
      }
      table.empty().append(html);
      row.parent().prepend('<div class="minuteHnad"></div>');
      let currentminutetime = widget.find(".timepicker-minute").text();
      widget.find(".minuteHnad").addClass("minuteHnad-" + currentminutetime);
      $(".minute").removeClass("active");
      widget.find(".minute").each(function () {
        if ($(this).text() === currentminutetime) {
          $(this).addClass("active");
        }
      });
    },
    fillSeconds = function () {
      let table = widget.find(".timepicker-seconds > div"),
        currentSecond = viewDate.clone().startOf("m"),
        html = [],
        row = $("<div>");
      let position = 0;
      while (viewDate.isSame(currentSecond, "m")) {
        if (currentSecond.second() % 20 === 0) {
          //    row = $('<tr>');
          html.push(row);
        }
        row.append(
          '<div data-action="selectSecond" class="second position-' +
            position++ +
            (!isValid(currentSecond, "s") ? " disabled" : "") +
            '">' +
            currentSecond.format("ss") +
            "</div>"
        );
        currentSecond.add(5, "s");
      }

      table.empty().append(html);
      row.parent().prepend('<div class="secondHnad"></div>');
      let currentsecondtime = widget.find(".timepicker-second").text();
      widget.find(".secondHnad").addClass("secondHnad-" + currentsecondtime);
      $(".second").removeClass("active");
      widget.find(".second").each(function () {
        if ($(this).text() === currentsecondtime) {
          $(this).addClass("active");
        }
      });
    },
    fillTime = function () {
      let timeComponents = widget.find(".timepicker span[data-time-component]");
      if (!use24Hours) {
        widget
          .find(".timepicker [data-action=togglePeriod]")
          .text(date.format("A"));
        widget.find(".timepicker [data-action=togglePeriodam]").text("AM");
        widget.find(".timepicker [data-action=togglePeriodpm]").text("PM");
      }
      timeComponents
        .filter("[data-time-component=hours]")
        .text(date.format(use24Hours ? "HH" : "hh"));
      timeComponents
        .filter("[data-time-component=minutes]")
        .text(date.format("mm"));
      timeComponents
        .filter("[data-time-component=seconds]")
        .text(date.format("ss"));
      fillHours();
      fillMinutes();
      fillSeconds();
      if (date.isSame(viewDate, "y")) {
      }
    },
    update = function () {
      if (!widget) {
        return;
      }
      fillDate();
      fillTime();
    },
    setValue = function (targetMoment) {
      let oldDate = unset ? null : date;

      // case of calling setValue(null or false)
      if (!targetMoment) {
        unset = true;
        input.val("");
        element.data("date", "");
        notifyEvent({
          type: "dp.change",
          date: null,
          oldDate: oldDate,
        });
        update();
        return;
      }

      targetMoment = targetMoment.clone().locale(options.locale);

      if (options.stepping !== 1) {
        targetMoment
          .minutes(
            (Math.round(targetMoment.minutes() / options.stepping) *
              options.stepping) %
              60
          )
          .seconds(0);
      }

      if (isValid(targetMoment)) {
        date = targetMoment;
        viewDate = date.clone();
        input.val(date.format(actualFormat));
        element.data("date", date.format(actualFormat));
        update();
        unset = false;
        notifyEvent({
          type: "dp.change",
          date: date.clone(),
          oldDate: oldDate,
        });
      } else {
        if (!options.keepInvalid) {
          input.val(unset ? "" : date.format(actualFormat));
        }
        notifyEvent({
          type: "dp.error",
          date: targetMoment,
        });
      }
    },
    hide = function () {
      let transitioning = false;
      if (!widget) {
        return picker;
      }
      // Ignore event if in the middle of a picker transition
      widget.find(".collapse").each(function () {
        let collapseData = $(this).data("collapse");
        if (collapseData && collapseData.transitioning) {
          transitioning = true;
          return false;
        }
        return true;
      });
      if (transitioning) {
        return picker;
      }
      if (component && component.hasClass("btn")) {
        component.toggleClass("active");
      }
      widget.hide();

      $(window).off("resize", place);
      widget.off("click", "[data-action]");
      widget.off("mousedown", false);

      widget.remove();
      widget = false;

      notifyEvent({
        type: "dp.hide",
        date: date.clone(),
      });
      return picker;
    },
    clear = function () {
      setValue(null);
    },
    /********************************************************************************
     *
     * Widget UI interaction functions
     *
     ********************************************************************************/
    actions = {
      next: function () {
        viewDate.add(
          datePickerModes[currentViewMode].navStep,
          datePickerModes[currentViewMode].navFnc
        );
        fillDate();
      },

      previous: function () {
        viewDate.subtract(
          datePickerModes[currentViewMode].navStep,
          datePickerModes[currentViewMode].navFnc
        );
        fillDate();
      },

      pickerSwitch: function () {
        showMode(1);
      },

      selectMonth: function (e) {
        let month = $(e.target)
          .closest("tbody")
          .find("span")
          .index($(e.target));
        viewDate.month(month);
        if (currentViewMode === minViewModeNumber) {
          setValue(date.clone().year(viewDate.year()).month(viewDate.month()));
          if (!options.inline) {
            hide();
          }
        } else {
          showMode(-1);
          fillDate();
        }
      },

      selectYear: function (e) {
        let year = parseInt($(e.target).text(), 10) || 0;
        viewDate.year(year);
        if (currentViewMode === minViewModeNumber) {
          setValue(date.clone().year(viewDate.year()));
          if (!options.inline) {
            hide();
          }
        } else {
          showMode(-1);
          fillDate();
        }
      },

      selectDay: function (e) {
        let day = viewDate.clone();
        if ($(e.target).is(".old")) {
          day.subtract(1, "M");
        }
        if ($(e.target).is(".new")) {
          day.add(1, "M");
        }

        setValue(day.date(parseInt($(e.target).text(), 10)));

        let monthonly = moment
            .utc(day.date(parseInt($(e.target).text(), 10)))
            .format("MMM"),
          yearonly = moment
            .utc(day.date(parseInt($(e.target).text(), 10)))
            .format("YYYY"),
          dateonly = moment
            .utc(day.date(parseInt($(e.target).text(), 10)))
            .format("DD"),
          dayname = moment
            .utc(day.date(parseInt($(e.target).text(), 10)))
            .format("ddd");

        widget.find("p.dayname").text(dayname + ",");
        widget.find("p.monthonly").text(monthonly);
        widget.find("p.dateonly").text(dateonly);
        widget.find("p.yearonly").text(yearonly);

        if (!hasTime() && !options.keepOpen && !options.inline) {
          hide();
        }
      },

      incrementHours: function () {
        setValue(date.clone().add(1, "h"));
      },

      incrementMinutes: function () {
        setValue(date.clone().add(options.stepping, "m"));
      },

      incrementSeconds: function () {
        setValue(date.clone().add(1, "s"));
      },

      decrementHours: function () {
        setValue(date.clone().subtract(1, "h"));
      },

      decrementMinutes: function () {
        setValue(date.clone().subtract(options.stepping, "m"));
      },

      decrementSeconds: function () {
        setValue(date.clone().subtract(1, "s"));
      },

      togglePeriod: function () {
        setValue(date.clone().add(date.hours() >= 12 ? -12 : 12, "h"));
      },

      togglePeriodam: function () {
        setValue(date.clone().add(date.hours() >= 12 ? -12 : 0, "h"));
      },

      togglePeriodpm: function () {
        setValue(date.clone().add(date.hours() >= 12 ? 0 : 12, "h"));
      },

      togglePicker: function (e) {
        let $this = $(e.target),
          $parent = $this.closest("ul"),
          expanded = $parent.find(".in"),
          closed = $parent.find(".collapse:not(.in)"),
          collapseData;

        if (expanded && expanded.length) {
          collapseData = expanded.data("collapse");
          if (collapseData && collapseData.transitioning) {
            return;
          }
          if (expanded.collapse) {
            // if collapse plugin is available through bootstrap.js then use it
            expanded.collapse("hide");
            closed.collapse("show");
          } else {
            // otherwise just toggle in class on the two views
            expanded.removeClass("in");
            closed.addClass("in");
          }
          if ($this.is("span")) {
            $this.toggleClass(options.icons.time + " " + options.icons.date);
          } else {
            $this
              .find("span")
              .toggleClass(options.icons.time + " " + options.icons.date);
          }

          // NOTE: uncomment if toggled state will be restored in show()
          //if (component) {
          //    component.find('span').toggleClass(options.icons.time + ' ' + options.icons.date);
          //}
        }
      },

      showPicker: function () {
        widget.find(".timepicker > div:not(.timepicker-picker)").hide();
        widget.find(".timepicker .timepicker-picker").show();
      },

      showHours: function () {
        //    widget.find('.timepicker .timepicker-picker').hide();
        widget.find(".timepicker .timepicker-minutes").hide();
        widget.find(".timepicker .timepicker-seconds").hide();
        widget.find(".timepicker .timepicker-hours").show();
        $(".timepicker-minute, .timepicker-second").removeClass("active");
        $(".timepicker-hour").addClass("active");
      },

      showMinutes: function () {
        //   widget.find('.timepicker .timepicker-picker').hide();
        widget.find(".timepicker .timepicker-hours").hide();
        widget.find(".timepicker .timepicker-seconds").hide();
        widget.find(".timepicker .timepicker-minutes").show();

        $(".timepicker-hour, .timepicker-second").removeClass("active");
        $(".timepicker-minute").addClass("active");
      },

      showSeconds: function () {
        // widget.find('.timepicker .timepicker-picker').hide();
        widget.find(".timepicker .timepicker-hours").hide();
        widget.find(".timepicker .timepicker-minutes").hide();
        widget.find(".timepicker .timepicker-seconds").show();
        $(".timepicker-minute, .timepicker-hour").removeClass("active");
        $(".timepicker-second").addClass("active");
      },

      selectHour: function (e) {
        let hour = parseInt($(e.target).text(), 10);
        if (!use24Hours) {
          if (date.hours() >= 12) {
            if (hour !== 12) {
              hour += 12;
            }
          } else {
            if (hour === 12) {
              hour = 0;
            }
          }
        }
        setValue(date.clone().hours(hour));

        // actions.showPicker.call(picker);
      },

      selectMinute: function (e) {
        setValue(date.clone().minutes(parseInt($(e.target).text(), 10)));
        // actions.showPicker.call(picker);
      },

      selectSecond: function (e) {
        setValue(date.clone().seconds(parseInt($(e.target).text(), 10)));
        // actions.showPicker.call(picker);
      },

      clear: clear,

      today: function () {
        setValue(moment());
      },

      close: hide,
    },
    doAction = function (e) {
      //$('.amview').toggleClass('active');
      //$('.pmview').toggleClass('active');

      //alert("");

      if ($(e.currentTarget).is(".disabled")) {
        return false;
      }
      actions[$(e.currentTarget).data("action")].apply(picker, arguments);

      /* $(".hour ,.minute ,.second").each(function(){
                  let dynamicClass = $(this).index();
                  $(this).addClass("position-"+dynamicClass);
              }); */
      return false;
    },
    show = function () {
      let currentMoment,
        useCurrentGranularity = {
          year: function (m) {
            return m.month(0).date(1).hours(0).seconds(0).minutes(0);
          },
          month: function (m) {
            return m.date(1).hours(0).seconds(0).minutes(0);
          },
          day: function (m) {
            return m.hours(0).seconds(0).minutes(0);
          },
          hour: function (m) {
            return m.seconds(0).minutes(0);
          },
          minute: function (m) {
            return m.seconds(0);
          },
        };

      if (
        input.prop("disabled") ||
        (!options.ignoreReadonly && input.prop("readonly")) ||
        widget
      ) {
        return picker;
      }
      if (
        options.useCurrent &&
        unset &&
        ((input.is("input") && input.val().trim().length === 0) ||
          options.inline)
      ) {
        currentMoment = moment();
        if (typeof options.useCurrent === "string") {
          currentMoment =
            useCurrentGranularity[options.useCurrent](currentMoment);
        }
        setValue(currentMoment);
      }

      widget = getTemplate();

      fillDow();
      fillMonths();

      // widget.find('.timepicker-hours').hide();

      widget.find(".timepicker-minutes").hide();
      widget.find(".timepicker-seconds").hide();

      update();
      showMode();

      $(window).on("resize", place);
      widget.on("click", "[data-action]", doAction); // this handles clicks on the widget
      widget.on("mousedown", false);

      if (component && component.hasClass("btn")) {
        component.toggleClass("active");
      }
      widget.show();
      place();

      if (!input.is(":focus")) {
        input.focus();
      }

      notifyEvent({
        type: "dp.show",
      });
      return picker;
    },
    toggle = function () {
      return widget ? hide() : show();
    },
    parseInputDate = function (inputDate) {
      if (moment.isMoment(inputDate) || inputDate instanceof Date) {
        inputDate = moment.utc(inputDate);
      } else {
        inputDate = moment.utc(inputDate, parseFormats, options.useStrict);
      }
      inputDate.locale(options.locale);
      return inputDate;
    },
    keydown = function (e) {
      //if (e.keyCode === 27 && widget) { // allow escape to hide picker
      //    hide();
      //    return false;
      //}
      //if (e.keyCode === 40 && !widget) { // allow down to show picker
      //    show();
      //    e.preventDefault();
      //}
      //return true;

      let handler = null,
        index,
        index2,
        pressedKeys = [],
        pressedModifiers = {},
        currentKey = e.which,
        keyBindKeys,
        allModifiersPressed,
        pressed = "p";

      keyState[currentKey] = pressed;

      for (index in keyState) {
        if (keyState.hasOwnProperty(index) && keyState[index] === pressed) {
          pressedKeys.push(index);
          if (parseInt(index, 10) !== currentKey) {
            pressedModifiers[index] = true;
          }
        }
      }

      for (index in options.keyBinds) {
        if (
          options.keyBinds.hasOwnProperty(index) &&
          typeof options.keyBinds[index] === "function"
        ) {
          keyBindKeys = index.split(" ");

          if (
            keyBindKeys.length === pressedKeys.length &&
            keyMap[currentKey] === keyBindKeys[keyBindKeys.length - 1]
          ) {
            allModifiersPressed = true;
            for (index2 = keyBindKeys.length - 2; index2 >= 0; index2--) {
              if (!(keyMap[keyBindKeys[index2]] in pressedModifiers)) {
                allModifiersPressed = false;
                break;
              }
            }
            if (allModifiersPressed) {
              handler = options.keyBinds[index];
              break;
            }
          }
        }
      }

      if (handler) {
        handler.call(picker, widget);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    keyup = function (e) {
      keyState[e.which] = "r";
      e.stopPropagation();
      e.preventDefault();
    },
    change = function (e) {
      let val = $(e.target).val().trim(),
        parsedDate = val ? parseInputDate(val) : null;
      setValue(parsedDate);
      e.stopImmediatePropagation();
      return false;
    },
    attachDatePickerElementEvents = function () {
      input.on({
        change: change,
        blur: options.debug ? "" : hide,
        keydown: keydown,
        keyup: keyup,
      });

      if (element.is("input")) {
        input.on({
          focus: show,
        });
      } else if (component) {
        component.on("click", toggle);
        component.on("mousedown", false);
      }
    },
    detachDatePickerElementEvents = function () {
      input.off({
        change: change,
        blur: hide,
        keydown: keydown,
        keyup: keyup,
      });

      if (element.is("input")) {
        input.off({
          focus: show,
        });
      } else if (component) {
        component.off("click", toggle);
        component.off("mousedown", false);
      }
    },
    indexGivenDates = function (givenDatesArray) {
      // Store given enabledDates and disabledDates as keys.
      // This way we can check their existence in O(1) time instead of looping through whole array.
      // (for example: options.enabledDates['2014-02-27'] === true)
      let givenDatesIndexed = {};
      $.each(givenDatesArray, function () {
        let dDate = parseInputDate(this);
        if (dDate.isValid()) {
          givenDatesIndexed[dDate.format("YYYY-MM-DD")] = true;
        }
      });
      return Object.keys(givenDatesIndexed).length ? givenDatesIndexed : false;
    },
    initFormatting = function () {
      let format = options.format || "L LT";

      actualFormat = format.replace(
        /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g,
        function (formatInput) {
          let newinput =
            date.localeData().longDateFormat(formatInput) || formatInput;
          return newinput.replace(
            /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g,
            function (formatInput2) {
              //temp fix for #740
              return (
                date.localeData().longDateFormat(formatInput2) || formatInput2
              );
            }
          );
        }
      );

      parseFormats = options.extraFormats ? options.extraFormats.slice() : [];
      if (
        parseFormats.indexOf(format) < 0 &&
        parseFormats.indexOf(actualFormat) < 0
      ) {
        parseFormats.push(actualFormat);
      }

      use24Hours =
        actualFormat.toLowerCase().indexOf("a") < 1 &&
        actualFormat.indexOf("h") < 1;

      if (isEnabled("y")) {
        minViewModeNumber = 2;
      }
      if (isEnabled("M")) {
        minViewModeNumber = 1;
      }
      if (isEnabled("d")) {
        minViewModeNumber = 0;
      }

      currentViewMode = Math.max(minViewModeNumber, currentViewMode);

      if (!unset) {
        setValue(date);
      }
    };

  /********************************************************************************
   *
   * Public API functions
   * =====================
   *
   * Important: Do not expose direct references to private objects or the options
   * object to the outer world. Always return a clone when returning values or make
   * a clone when setting a private letiable.
   *
   ********************************************************************************/
  picker.destroy = function () {
    hide();
    detachDatePickerElementEvents();
    element.removeData("DateTimePicker");
    element.removeData("date");
  };

  picker.toggle = toggle;

  picker.show = show;

  picker.hide = hide;

  picker.disable = function () {
    hide();
    if (component && component.hasClass("btn")) {
      component.addClass("disabled");
    }
    input.prop("disabled", true);
    return picker;
  };

  picker.enable = function () {
    if (component && component.hasClass("btn")) {
      component.removeClass("disabled");
    }
    input.prop("disabled", false);
    return picker;
  };

  picker.ignoreReadonly = function (ignoreReadonly) {
    if (arguments.length === 0) {
      return options.ignoreReadonly;
    }
    if (typeof ignoreReadonly !== "boolean") {
      throw new TypeError("ignoreReadonly () expects a boolean parameter");
    }
    options.ignoreReadonly = ignoreReadonly;
    return picker;
  };

  picker.options = function (newOptions) {
    if (arguments.length === 0) {
      return $.extend(true, {}, options);
    }

    if (!(newOptions instanceof Object)) {
      throw new TypeError("options() options parameter should be an object");
    }
    $.extend(true, options, newOptions);
    $.each(options, function (key, value) {
      if (picker[key] !== undefined) {
        picker[key](value);
      } else {
        throw new TypeError("option " + key + " is not recognized!");
      }
    });
    return picker;
  };

  picker.date = function (newDate) {
    if (arguments.length === 0) {
      if (unset) {
        return null;
      }
      return date.clone();
    }

    if (
      newDate !== null &&
      typeof newDate !== "string" &&
      !moment.isMoment(newDate) &&
      !(newDate instanceof Date)
    ) {
      throw new TypeError(
        "date() parameter must be one of [null, string, moment.utc or Date]"
      );
    }

    setValue(newDate === null ? null : parseInputDate(newDate));
    return picker;
  };

  picker.format = function (newFormat) {
    if (arguments.length === 0) {
      return options.format;
    }

    if (
      typeof newFormat !== "string" &&
      (typeof newFormat !== "boolean" || newFormat !== false)
    ) {
      throw new TypeError(
        "format() expects a sting or boolean:false parameter " + newFormat
      );
    }

    options.format = newFormat;
    if (actualFormat) {
      initFormatting(); // reinit formatting
    }
    return picker;
  };

  picker.dayViewHeaderFormat = function (newFormat) {
    if (arguments.length === 0) {
      return options.dayViewHeaderFormat;
    }

    if (typeof newFormat !== "string") {
      throw new TypeError("dayViewHeaderFormat() expects a string parameter");
    }

    options.dayViewHeaderFormat = newFormat;
    return picker;
  };

  picker.extraFormats = function (formats) {
    if (arguments.length === 0) {
      return options.extraFormats;
    }

    if (formats !== false && !(formats instanceof Array)) {
      throw new TypeError("extraFormats() expects an array or false parameter");
    }

    options.extraFormats = formats;
    if (parseFormats) {
      initFormatting(); // reinit formatting
    }
    return picker;
  };

  picker.disabledDates = function (dates) {
    if (arguments.length === 0) {
      return options.disabledDates
        ? $.extend({}, options.disabledDates)
        : options.disabledDates;
    }

    if (!dates) {
      options.disabledDates = false;
      update();
      return picker;
    }
    if (!(dates instanceof Array)) {
      throw new TypeError("disabledDates() expects an array parameter");
    }
    options.disabledDates = indexGivenDates(dates);
    options.enabledDates = false;
    update();
    return picker;
  };

  picker.enabledDates = function (dates) {
    if (arguments.length === 0) {
      return options.enabledDates
        ? $.extend({}, options.enabledDates)
        : options.enabledDates;
    }

    if (!dates) {
      options.enabledDates = false;
      update();
      return picker;
    }
    if (!(dates instanceof Array)) {
      throw new TypeError("enabledDates() expects an array parameter");
    }
    options.enabledDates = indexGivenDates(dates);
    options.disabledDates = false;
    update();
    return picker;
  };

  picker.daysOfWeekDisabled = function (daysOfWeekDisabled) {
    if (arguments.length === 0) {
      return options.daysOfWeekDisabled.splice(0);
    }

    if (!(daysOfWeekDisabled instanceof Array)) {
      throw new TypeError("daysOfWeekDisabled() expects an array parameter");
    }
    options.daysOfWeekDisabled = daysOfWeekDisabled
      .reduce(function (previousValue, currentValue) {
        currentValue = parseInt(currentValue, 10);
        if (currentValue > 6 || currentValue < 0 || isNaN(currentValue)) {
          return previousValue;
        }
        if (previousValue.indexOf(currentValue) === -1) {
          previousValue.push(currentValue);
        }
        return previousValue;
      }, [])
      .sort();
    update();
    return picker;
  };

  picker.maxDate = function (maxDate) {
    if (arguments.length === 0) {
      return options.maxDate ? options.maxDate.clone() : options.maxDate;
    }

    if (typeof maxDate === "boolean" && maxDate === false) {
      options.maxDate = false;
      update();
      return picker;
    }

    if (typeof maxDate === "string") {
      if (maxDate === "now" || maxDate === "moment.utc") {
        maxDate = moment();
      }
    }

    let parsedDate = parseInputDate(maxDate);

    if (!parsedDate.isValid()) {
      throw new TypeError(
        "maxDate() Could not parse date parameter: " + maxDate
      );
    }
    if (options.minDate && parsedDate.isBefore(options.minDate)) {
      throw new TypeError(
        "maxDate() date parameter is before options.minDate: " +
          parsedDate.format(actualFormat)
      );
    }
    options.maxDate = parsedDate;
    if (options.maxDate.isBefore(maxDate)) {
      setValue(options.maxDate);
    }
    if (viewDate.isAfter(parsedDate)) {
      viewDate = parsedDate.clone();
    }
    update();
    return picker;
  };

  picker.minDate = function (minDate) {
    if (arguments.length === 0) {
      return options.minDate ? options.minDate.clone() : options.minDate;
    }

    if (typeof minDate === "boolean" && minDate === false) {
      options.minDate = false;
      update();
      return picker;
    }

    if (typeof minDate === "string") {
      if (minDate === "now" || minDate === "moment.utc") {
        minDate = moment();
      }
    }

    let parsedDate = parseInputDate(minDate);

    if (!parsedDate.isValid()) {
      throw new TypeError(
        "minDate() Could not parse date parameter: " + minDate
      );
    }
    if (options.maxDate && parsedDate.isAfter(options.maxDate)) {
      throw new TypeError(
        "minDate() date parameter is after options.maxDate: " +
          parsedDate.format(actualFormat)
      );
    }
    options.minDate = parsedDate;
    if (options.minDate.isAfter(minDate)) {
      setValue(options.minDate);
    }
    if (viewDate.isBefore(parsedDate)) {
      viewDate = parsedDate.clone();
    }
    update();
    return picker;
  };

  picker.defaultDate = function (defaultDate) {
    if (arguments.length === 0) {
      return options.defaultDate
        ? options.defaultDate.clone()
        : options.defaultDate;
    }
    if (!defaultDate) {
      options.defaultDate = false;
      return picker;
    }

    if (typeof defaultDate === "string") {
      if (defaultDate === "now" || defaultDate === "moment.utc") {
        defaultDate = moment();
      }
    }

    let parsedDate = parseInputDate(defaultDate);
    if (!parsedDate.isValid()) {
      throw new TypeError(
        "defaultDate() Could not parse date parameter: " + defaultDate
      );
    }
    if (!isValid(parsedDate)) {
      throw new TypeError(
        "defaultDate() date passed is invalid according to component setup validations"
      );
    }

    options.defaultDate = parsedDate;

    if (
      options.defaultDate &&
      input.val().trim() === "" &&
      input.attr("placeholder") === undefined
    ) {
      setValue(options.defaultDate);
    }
    return picker;
  };

  picker.locale = function (locale) {
    if (arguments.length === 0) {
      return options.locale;
    }

    if (!moment.localeData(locale)) {
      throw new TypeError(
        "locale() locale " + locale + " is not loaded from moment.utc locales!"
      );
    }

    options.locale = locale;
    date.locale(options.locale);
    viewDate.locale(options.locale);

    if (actualFormat) {
      initFormatting(); // reinit formatting
    }
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.stepping = function (stepping) {
    if (arguments.length === 0) {
      return options.stepping;
    }

    stepping = parseInt(stepping, 10);
    if (isNaN(stepping) || stepping < 1) {
      stepping = 1;
    }
    options.stepping = stepping;
    return picker;
  };

  picker.useCurrent = function (useCurrent) {
    let useCurrentOptions = ["year", "month", "day", "hour", "minute"];
    if (arguments.length === 0) {
      return options.useCurrent;
    }

    if (typeof useCurrent !== "boolean" && typeof useCurrent !== "string") {
      throw new TypeError("useCurrent() expects a boolean or string parameter");
    }
    if (
      typeof useCurrent === "string" &&
      useCurrentOptions.indexOf(useCurrent.toLowerCase()) === -1
    ) {
      throw new TypeError(
        "useCurrent() expects a string parameter of " +
          useCurrentOptions.join(", ")
      );
    }
    options.useCurrent = useCurrent;
    return picker;
  };
  picker.collapse = function (collapse) {
    if (arguments.length === 0) {
      return options.collapse;
    }

    if (typeof collapse !== "boolean") {
      throw new TypeError("collapse() expects a boolean parameter");
    }
    if (options.collapse === collapse) {
      return picker;
    }
    options.collapse = collapse;
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.icons = function (icons) {
    if (arguments.length === 0) {
      return $.extend({}, options.icons);
    }

    if (!(icons instanceof Object)) {
      throw new TypeError("icons() expects parameter to be an Object");
    }
    $.extend(options.icons, icons);
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.useStrict = function (useStrict) {
    if (arguments.length === 0) {
      return options.useStrict;
    }

    if (typeof useStrict !== "boolean") {
      throw new TypeError("useStrict() expects a boolean parameter");
    }
    options.useStrict = useStrict;
    return picker;
  };

  picker.sideBySide = function (sideBySide) {
    if (arguments.length === 0) {
      return options.sideBySide;
    }

    if (typeof sideBySide !== "boolean") {
      throw new TypeError("sideBySide() expects a boolean parameter");
    }
    options.sideBySide = sideBySide;
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.viewMode = function (viewMode) {
    if (arguments.length === 0) {
      return options.viewMode;
    }

    if (typeof viewMode !== "string") {
      throw new TypeError("viewMode() expects a string parameter");
    }

    if (viewModes.indexOf(viewMode) === -1) {
      throw new TypeError(
        "viewMode() parameter must be one of (" +
          viewModes.join(", ") +
          ") value"
      );
    }

    options.viewMode = viewMode;
    currentViewMode = Math.max(viewModes.indexOf(viewMode), minViewModeNumber);

    showMode();
    return picker;
  };

  picker.toolbarPlacement = function (toolbarPlacement) {
    if (arguments.length === 0) {
      return options.toolbarPlacement;
    }

    if (typeof toolbarPlacement !== "string") {
      throw new TypeError("toolbarPlacement() expects a string parameter");
    }
    if (toolbarPlacements.indexOf(toolbarPlacement) === -1) {
      throw new TypeError(
        "toolbarPlacement() parameter must be one of (" +
          toolbarPlacements.join(", ") +
          ") value"
      );
    }
    options.toolbarPlacement = toolbarPlacement;

    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.widgetPositioning = function (widgetPositioning) {
    if (arguments.length === 0) {
      return $.extend({}, options.widgetPositioning);
    }

    if ({}.toString.call(widgetPositioning) !== "[object Object]") {
      throw new TypeError("widgetPositioning() expects an object letiable");
    }
    if (widgetPositioning.horizontal) {
      if (typeof widgetPositioning.horizontal !== "string") {
        throw new TypeError(
          "widgetPositioning() horizontal letiable must be a string"
        );
      }
      widgetPositioning.horizontal = widgetPositioning.horizontal.toLowerCase();
      if (horizontalModes.indexOf(widgetPositioning.horizontal) === -1) {
        throw new TypeError(
          "widgetPositioning() expects horizontal parameter to be one of (" +
            horizontalModes.join(", ") +
            ")"
        );
      }
      options.widgetPositioning.horizontal = widgetPositioning.horizontal;
    }
    if (widgetPositioning.vertical) {
      if (typeof widgetPositioning.vertical !== "string") {
        throw new TypeError(
          "widgetPositioning() vertical letiable must be a string"
        );
      }
      widgetPositioning.vertical = widgetPositioning.vertical.toLowerCase();
      if (verticalModes.indexOf(widgetPositioning.vertical) === -1) {
        throw new TypeError(
          "widgetPositioning() expects vertical parameter to be one of (" +
            verticalModes.join(", ") +
            ")"
        );
      }
      options.widgetPositioning.vertical = widgetPositioning.vertical;
    }
    update();
    return picker;
  };

  picker.calendarWeeks = function (calendarWeeks) {
    if (arguments.length === 0) {
      return options.calendarWeeks;
    }

    if (typeof calendarWeeks !== "boolean") {
      throw new TypeError(
        "calendarWeeks() expects parameter to be a boolean value"
      );
    }

    options.calendarWeeks = calendarWeeks;
    update();
    return picker;
  };

  picker.showTodayButton = function (showTodayButton) {
    if (arguments.length === 0) {
      return options.showTodayButton;
    }

    if (typeof showTodayButton !== "boolean") {
      throw new TypeError("showTodayButton() expects a boolean parameter");
    }

    options.showTodayButton = showTodayButton;
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.showClear = function (showClear) {
    if (arguments.length === 0) {
      return options.showClear;
    }

    if (typeof showClear !== "boolean") {
      throw new TypeError("showClear() expects a boolean parameter");
    }

    options.showClear = showClear;
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.widgetParent = function (widgetParent) {
    if (arguments.length === 0) {
      return options.widgetParent;
    }
    if (typeof widgetParent === "string") {
      widgetParent = $(widgetParent);
    }
    if (
      widgetParent !== null &&
      typeof widgetParent !== "string" &&
      !(widgetParent instanceof $)
    ) {
      throw new TypeError(
        "widgetParent() expects a string or a jQuery object parameter"
      );
    }
    options.widgetParent = widgetParent;
    if (widget) {
      hide();
      show();
    }
    return picker;
  };

  picker.keepOpen = function (keepOpen) {
    if (arguments.length === 0) {
      return options.keepOpen;
    }

    if (typeof keepOpen !== "boolean") {
      throw new TypeError("keepOpen() expects a boolean parameter");
    }

    options.keepOpen = keepOpen;
    return picker;
  };

  picker.inline = function (inline) {
    if (arguments.length === 0) {
      return options.inline;
    }

    if (typeof inline !== "boolean") {
      throw new TypeError("inline() expects a boolean parameter");
    }

    options.inline = inline;
    return picker;
  };

  picker.clear = function () {
    clear();
    return picker;
  };

  picker.keyBinds = function (keyBinds) {
    options.keyBinds = keyBinds;
    return picker;
  };

  picker.debug = function (debug) {
    if (typeof debug !== "boolean") {
      throw new TypeError("debug() expects a boolean parameter");
    }

    options.debug = debug;
    return picker;
  };

  picker.showClose = function (showClose) {
    if (arguments.length === 0) {
      return options.showClose;
    }

    if (typeof showClose !== "boolean") {
      throw new TypeError("showClose() expects a boolean parameter");
    }

    options.showClose = showClose;
    return picker;
  };

  picker.keepInvalid = function (keepInvalid) {
    if (arguments.length === 0) {
      return options.keepInvalid;
    }

    if (typeof keepInvalid !== "boolean") {
      throw new TypeError("keepInvalid() expects a boolean parameter");
    }
    options.keepInvalid = keepInvalid;
    return picker;
  };

  picker.datepickerInput = function (datepickerInput) {
    if (arguments.length === 0) {
      return options.datepickerInput;
    }

    if (typeof datepickerInput !== "string") {
      throw new TypeError("datepickerInput() expects a string parameter");
    }

    options.datepickerInput = datepickerInput;
    return picker;
  };

  // initializing element and component attributes
  if (element.is("input")) {
    input = element;
  } else {
    input = element.find(options.datepickerInput);
    if (input.size() === 0) {
      input = element.find("input");
    } else if (!input.is("input")) {
      throw new Error(
        'CSS class "' +
          options.datepickerInput +
          '" cannot be applied to non input element'
      );
    }
  }

  if (element.hasClass("input-group")) {
    // in case there is more then one 'input-group-addon' Issue #48
    if (element.find(".datepickerbutton").size() === 0) {
      component = element.find('[class^="input-group-"]');
    } else {
      component = element.find(".datepickerbutton");
    }
  }

  if (!options.inline && !input.is("input")) {
    throw new Error(
      "Could not initialize DateTimePicker without an input element"
    );
  }

  $.extend(true, options, dataToOptions());

  picker.options(options);

  initFormatting();

  attachDatePickerElementEvents();

  if (input.prop("disabled")) {
    picker.disable();
  }
  if (input.is("input") && input.val().trim().length !== 0) {
    setValue(parseInputDate(input.val().trim()));
  } else if (options.defaultDate && input.attr("placeholder") === undefined) {
    setValue(options.defaultDate);
  }
  if (options.inline) {
    show();
  }
  return picker;
}

/********************************************************************************
 *
 * jQuery plugin constructor and defaults object
 *
 ********************************************************************************/

$.fn.datetimepicker = function (options) {
  return this.each(function () {
    let $this = $(this);
    if (!$this.data("DateTimePicker")) {
      // create a private copy of the defaults object
      options = $.extend(true, {}, $.fn.datetimepicker.defaults, options);
      $this.data("DateTimePicker", dateTimePicker($this, options));
    }
  });
};

$.fn.datetimepicker.defaults = {
  format: false,
  dayViewHeaderFormat: "MMMM [de] YYYY",
  extraFormats: false,
  stepping: 1,
  minDate: false,
  maxDate: false,
  useCurrent: true,
  collapse: true,
  locale: moment.locale("pt"),
  defaultDate: false,
  disabledDates: false,
  enabledDates: false,
  icons: {
    time: "material-icons md-light pmd-sm pmd-time",
    date: "material-icons md-light pmd-sm pmd-today",
    up: "material-icons md-light pmd-sm pmd-keyboard-arrow-up",
    down: "material-icons md-light pmd-sm pmd-keyboard-arrow-down",
    previous: "material-icons pmd-sm pmd-keyboard-arrow-left",
    next: "material-icons pmd-sm pmd-keyboard-arrow-right",
    today: "mdi mdi-calendar-today",
    clear: "glyphicon glyphicon-trash",
    close: "glyphicon glyphicon-remove",
  },
  useStrict: false,
  sideBySide: false,
  daysOfWeekDisabled: [],
  calendarWeeks: false,
  viewMode: "days",
  toolbarPlacement: "default",
  showTodayButton: false,
  showClear: false,
  showClose: false,
  widgetPositioning: {
    horizontal: "auto",
    vertical: "auto",
  },
  widgetParent: null,
  ignoreReadonly: false,
  keepOpen: false,
  inline: false,
  keepInvalid: false,
  datepickerInput: ".datepickerinput",
  keyBinds: {
    up: function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().subtract(7, "d"));
      } else {
        this.date(d.clone().add(1, "m"));
      }
    },
    down: function (widget) {
      if (!widget) {
        this.show();
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().add(7, "d"));
      } else {
        this.date(d.clone().subtract(1, "m"));
      }
    },
    "control up": function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().subtract(1, "y"));
      } else {
        this.date(d.clone().add(1, "h"));
      }
    },
    "control down": function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().add(1, "y"));
      } else {
        this.date(d.clone().subtract(1, "h"));
      }
    },
    left: function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().subtract(1, "d"));
      }
    },
    right: function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().add(1, "d"));
      }
    },
    pageUp: function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().subtract(1, "M"));
      }
    },
    pageDown: function (widget) {
      if (!widget) {
        return;
      }
      let d = this.date() || moment();
      if (widget.find(".datepicker").is(":visible")) {
        this.date(d.clone().add(1, "M"));
      }
    },
    enter: function () {
      this.hide();
    },
    escape: function () {
      this.hide();
    },
    //tab: function (widget) { //this break the flow of the form. disabling for now
    //    let toggle = widget.find('.picker-switch a[data-action="togglePicker"]');
    //    if(toggle.length > 0) toggle.click();
    //},
    "control space": function (widget) {
      if (widget.find(".timepicker").is(":visible")) {
        //        widget.find('.btn[data-action="togglePeriod"]').click();
      }
    },
    t: function () {
      this.date(moment());
    },
    delete: function () {
      this.clear();
    },
  },
  debug: false,
};
